import React, { useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "components/Header/LangDropdown";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  onClickClose,
}) => {
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {/* {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))} */}
      </ul>
    );
  };

  // const _renderItem = (item: NavItemType, index: number) => {



  //   return (
  //     <Disclosure
  //       key={item.id}
  //       as="li"
  //       className="text-neutral-900 dark:text-white"
  //     >
  //       <NavLink
  //         end
  //         className={({ isActive }) =>
  //           `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${isActive ? "text-secondary" : ""
  //           }`
  //         }
  //         to={{
  //           pathname: item.href || undefined,
  //         }}
  //       >
  //         <span
  //           className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
  //         >
  //           {item.name}
  //         </span>
  //         {item.children && (
  //           <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
  //             <Disclosure.Button
  //               as="span"
  //               className="py-2.5 flex items-center justify-end flex-1 "
  //             >
  //               <ChevronRightIcon
  //                 className="ml-2 h-4 w-4 text-neutral-500"
  //                 aria-hidden="true"
  //                 onClick={onClickArrow}
  //               />
  //             </Disclosure.Button>
  //           </span>
  //         )}
  //       </NavLink>
  //       {item.children && (
  //         <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
  //       )}
  //     </Disclosure>
  //   );
  // };


  const renderItem = () => {
    return (
      <div className="font1 ">
        <ul className="">
          <a href="/">
          <li className="w-full px-4 font-medium uppercase tracking-wide text-sm">
            Home
          </li>
          </a>
          <li className="w-full px-4 py-5 m uppercase tracking-wide text-sm">
            <span className="flex-1 flex justify-between" onClick={onClickArrow}>
              <h3 className="">
                Destinations
              </h3>
              <ChevronRightIcon
                className="ml-2 h-4 w-4 text-neutral-500"
                aria-hidden="true"

              />
            </span>
          </li>
          <a href="/group-tour-packages">
            <li className="w-full px-4 font-medium uppercase tracking-wide text-sm">
              Group Tour
            </li>
          </a>
          <a href="/about-us">
          <li className="w-full px-4 py-5 font-medium uppercase tracking-wide text-sm">
            About Us
          </li>
          </a>
          <a href="/contact">
          <li className="w-full px-4 font-medium uppercase tracking-wide text-sm">
            Contact Us
          </li>
          </a>
        </ul>
      </div>

    );
  };




  const [showFirstStep, setShowFirstStep] = useState(true);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showMultilevelDropdown, setShowMultilevelDropdown] = useState(false);
  const [showMultilevelDropdown1, setShowMultilevelDropdown1] = useState(false);
  const [showMultilevelDropdown2, setShowMultilevelDropdown2] = useState(false);
  const [showMultilevelDropdown3, setShowMultilevelDropdown3] = useState(false);
  const [showMultilevelDropdown4, setShowMultilevelDropdown4] = useState(false);
  const [showMultilevelDropdown5, setShowMultilevelDropdown5] = useState(false);
  const [showMultilevelDropdown6, setShowMultilevelDropdown6] = useState(false);
  const [showMultilevelDropdown7, setShowMultilevelDropdown7] = useState(false);
  const [showMultilevelDropdown8, setShowMultilevelDropdown8] = useState(false);
  const [showMultilevelDropdown9, setShowMultilevelDropdown9] = useState(false);
  const [showMultilevelDropdown10, setShowMultilevelDropdown10] = useState(false);


  const onClickArrow = () => {
    setShowFirstStep(!showFirstStep);
    setShowSecondStep(!showSecondStep);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };

  const onClickMultilevelDropdownArrow = () => {
    setShowMultilevelDropdown(!showMultilevelDropdown);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };

  const onClickMultilevelDropdownArrow1 = () => {
    setShowMultilevelDropdown1(!showMultilevelDropdown1);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow2 = () => {
    setShowMultilevelDropdown2(!showMultilevelDropdown2);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow3 = () => {
    setShowMultilevelDropdown3(!showMultilevelDropdown3);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow4 = () => {
    setShowMultilevelDropdown4(!showMultilevelDropdown4);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };



  const onClickMultilevelDropdownArrow5 = () => {
    setShowMultilevelDropdown5(!showMultilevelDropdown5);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };




  const onClickMultilevelDropdownArrow6 = () => {
    setShowMultilevelDropdown6(!showMultilevelDropdown6);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };




  const onClickMultilevelDropdownArrow7 = () => {
    setShowMultilevelDropdown7(!showMultilevelDropdown7);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow8 = () => {
    setShowMultilevelDropdown8(!showMultilevelDropdown8);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow9 = () => {
    setShowMultilevelDropdown9(!showMultilevelDropdown9);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown7(false);
    setShowMultilevelDropdown10(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  const onClickMultilevelDropdownArrow10 = () => {
    setShowMultilevelDropdown10(!showMultilevelDropdown10);
    setShowMultilevelDropdown(false);
    setShowMultilevelDropdown2(false);
    setShowMultilevelDropdown3(false);
    setShowMultilevelDropdown1(false);
    setShowMultilevelDropdown5(false);
    setShowMultilevelDropdown6(false);
    setShowMultilevelDropdown4(false);
    setShowMultilevelDropdown8(false);
    setShowMultilevelDropdown9(false);
    setShowMultilevelDropdown7(false);
    // setShowMultilevelDropdown11(false);
    // setShowMultilevelDropdown12(false);
    // setShowMultilevelDropdown13(false);
  };


  return (
    <div className="overflow-y-auto w-full font1 h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      {showFirstStep && (
        <><div className="py-6 px-5">
          <Logo />
          <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
            <span>
              To establish and maintain TRAWEL MART as the most highlighted travel agency.
            </span>
          </div>
          <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose} />
          </span>
        </div><ul className="flex flex-col py-6 px-2 space-y-1">
            {renderItem()}
          </ul>
          <div className="flex justify-between items-center mt-4 mx-4">
            <SocialsList itemClass="w-9 h-9 mt-6 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              {/* <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" /> */}
            </span>
          </div>

        </>
      )}




      {showSecondStep && (
        <>
          <div className="overflow-y-auto w-full  bg-white dark:bg-neutral-900 -mt-3">
            <div className="py-4 px-5 flex items-center bg-gray-100 ">
              <ChevronLeftIcon
                className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer  top-2"
                aria-hidden="true"
                onClick={onClickArrow}
              />
              <h1 className="font1 w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                Destinations
              </h1>
              <span className="absolute right-2 top-2 p-1 font-2xl">
                <ButtonClose onClick={onClickClose} />
              </span>
            </div>
          </div>
          <div className="py-6 px-5 mb-16">
            {/* Your multilevel dropdown content */}

            <h3 className="w-full px-4 font-medium uppercase text-base font1 text-sky-600">
              International
            </h3>
            <ul>
              <li onClick={onClickMultilevelDropdownArrow1} className=" mt-6 border-t ">
                <div className={`flex items-center ${showMultilevelDropdown1 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Asia
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>

                {showMultilevelDropdown1 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <a href="/bali-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Bali</li>
                    </a>
                    <a href="/cambodia-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Cambodia</li>
                    </a>
                    <a href="/vietnam-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Vietnam</li>
                    </a>
                    <a href="/thailand-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Thailand</li>
                    </a>
                    <a href="/singapore-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Singapore</li>
                    </a>
                    <a href="/srilanka-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Srilanka</li>
                    </a>
                    <a href="/philippines-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Philippines</li>
                    </a>
                    <a href="/nepal-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Nepal</li>
                    </a>
                    <a href="/malaysia-tour-packages">
                      <li className="pb-2  pb-2 mt-4">Malaysia</li>
                    </a>
                  </ul>
                )}
              </li>


              <li onClick={onClickMultilevelDropdownArrow} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Europe
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <a href="/golden-europe-16-nights-17-days-tour-package-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Golden Europe</li>
                    </a>
                    <a href="/best-of-europe-10-nights-11-days-tour-package-1-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Best Of Europe</li>
                    </a>
                    <a href="/delightful-europe-13-nights-14days-tour-package-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Delightful Europe</li>
                    </a>
                    <a href="/classique-europe-7-nights-8-days-tour-package-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Classique Europe</li>
                    </a>
                    <a href="/splendor-europe-10-nights-11-days-tour-package-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Splendor Europe</li>
                    </a>
                    <a href="/greece-tour-packages">
                      <li className="pb-2 pb-2 mt-4">Greece</li>
                    </a>
                  </ul>
                )}
              </li>

              <li onClick={onClickMultilevelDropdownArrow2} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown2 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Australia & NewZealand
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown2 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <a href="/australia-tour-packages"></a>
                    <li className="pb-2 border-b pb-2 mt-4">Australia</li>
                    <li className="pb-2  pb-2 mt-4">New Zealand</li>
                  </ul>
                )}
              </li>


              <li onClick={onClickMultilevelDropdownArrow3} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown3 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Middle East
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown3 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <a href="/dubai-tour-packages">
                      <li className="pb-2  pb-2 mt-4">United Arab Emirates - Dubai</li>
                    </a>
                  </ul>
                )}
              </li>


              <li onClick={onClickMultilevelDropdownArrow4} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown4 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Africa
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown4 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">South Africa</li>
                    <a href="/kenya-tour-packages">
                      <li className="pb-2 border-b pb-2 mt-4">Kenya</li>
                    </a>
                    <a href="/morocco-tour-packages">
                      <li className="pb-2  pb-2 mt-4">Morocco</li>
                    </a>
                  </ul>
                )}
              </li>



              <li onClick={onClickMultilevelDropdownArrow5} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown5 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    America
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown5 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">USA</li>
                    <li className="pb-2 border-b pb-2 mt-4">Brazil</li>
                    <li className="pb-2 border-b pb-2 mt-4">Argentina</li>
                    <li className="pb-2  pb-2 mt-4">Canada</li>
                  </ul>
                )}
              </li>


              <li onClick={onClickMultilevelDropdownArrow6} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown6 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    Antarctica
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>
                {showMultilevelDropdown6 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2  pb-2 mt-4">Antarctica</li>
                  </ul>
                )}
              </li>
            </ul>





            <h3 className="w-full px-4 font-medium uppercase text-base font1 text-sky-600 pt-8">
              India
            </h3>

            <ul >
              <li onClick={onClickMultilevelDropdownArrow7} className=" mt-6 border-t ">
                <div className={`flex items-center ${showMultilevelDropdown7 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    East & North East India
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>

                {showMultilevelDropdown7 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">Arunachal Pradesh</li>
                    <li className="pb-2 border-b pb-2 mt-4">Meghalaya</li>
                    <li className="pb-2 border-b pb-2 mt-4">Mizoram</li>
                    <li className="pb-2 border-b pb-2 mt-4">Nagaland</li>
                    <li className="pb-2  pb-2 mt-4">West Bengal</li>
                  </ul>
                )}
              </li>
            </ul>



            <ul>
              <li onClick={onClickMultilevelDropdownArrow8} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown8 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    North India
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>

                {showMultilevelDropdown8 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">Himachal Pradesh</li>
                    <li className="pb-2 border-b pb-2 mt-4">Kashmir</li>
                    <li className="pb-2 border-b pb-2 mt-4">Leh Ladakh</li>
                    <li className="pb-2 border-b pb-2 mt-4">Uttarakhand</li>
                    <li className="pb-2 border-b pb-2 mt-4">Uttar Pradesh</li>
                  </ul>
                )}
              </li>
            </ul>



            <ul>
              <li onClick={onClickMultilevelDropdownArrow9} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown9 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    West India
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>

                {showMultilevelDropdown9 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">Goa</li>
                    <li className="pb-2 border-b pb-2 mt-4">Gujarat</li>
                    <li className="pb-2 border-b pb-2 mt-4">Madhya Pradesh</li>
                    <li className="pb-2 border-b pb-2 mt-4">Maharashtra</li>
                    <li className="pb-2 border-b pb-2 mt-4">Rajastan</li>
                  </ul>
                )}
              </li>
            </ul>


            <ul>
              <li onClick={onClickMultilevelDropdownArrow10} className=" border-t ">
                <div className={`flex items-center ${showMultilevelDropdown10 ? 'bg-gray-100' : ''}`}>
                  <p className="w-full px-4 font-medium font1 text-sm py-3"  >
                    South India
                  </p>
                  <ChevronDownIcon
                    className="ml-2 h-6  w-6 text-neutral-500 cursor-pointer "
                    aria-hidden="true"
                  />
                </div>

                {showMultilevelDropdown10 && (
                  <ul className="w-full px-4 font1 text-sm py-3">
                    <h6 className="font-bold font1 text-sm text-gray-400 uppercase ">
                      Countries
                    </h6>
                    <li className="pb-2 border-b pb-2 mt-4">Andhra Pradesh</li>
                    <li className="pb-2 border-b pb-2 mt-4">Kerala</li>
                    <li className="pb-2 border-b pb-2 mt-4">Karnataka</li>
                    <li className="pb-2 border-b pb-2 mt-4">TamilNadu</li>
                    <li className="pb-2 border-b pb-2 mt-4">Telangana</li>
                  </ul>
                )}
              </li>
            </ul>


          </div>

        </>

      )}






      {/* <div className="flex items-center justify-between py-6 px-5">
        <a
          className="inline-block"
          href="https://themeforest.net/item/chisfis-online-booking-react-template/33515927"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonPrimary>Get Template</ButtonPrimary>
        </a>

        <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0" />
  </div>*/}
    </div>
  );
};

export default NavMobile;
