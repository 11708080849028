import React, { FC, Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import axios from "axios";
import DatePicker from "react-datepicker";
import Packages from "./Packages";
import LandingHome from "landing/LandingHome/LandingHome";
import Page404 from "containers/Page404/Page404";
import { Dialog, Tab, Transition } from "@headlessui/react";
import StartRating from "components/StartRating/StartRating";
import luggage from "../../../images/ICONS/luggage.png";
import offersss from "../../../images/offersss.gif";
import presents from "../../../images/ICONS/presents.png";
import Input from "shared/Input/Input";
import Accordion from "./Accordion";
import Badge from "shared/Badge/Badge";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import DubaiTrourPackage from "images/sample/Dubai-Trour-Package.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LoadingSpinner from "routers/LoadingSpinner";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";
import NcImage from "shared/NcImage/NcImage";
import { debounce } from "lodash";
import Select from "react-select";
import { GetOptionLabel } from "react-select";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import StartRating1 from "components/StartRating/StartRating1";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { toast } from "react-toastify";
import airports from "../../../airports.json";
import coin from "images/ICONS/coin.png";
import india from "images/india.png";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import authentication from "../../../firebaseConfig";
import line from "images/uk/line.webp";
import closee from "images/uk/closee.png";

import { Amenities_demos, PHOTOS } from "./constant";

const Dynamic_without_checkout: React.FC = () => {

  const [isShowMore, setIsShowMore] = useState(false);
  const [mobileNumberEntry, setMobileNumberEntry] = useState(true);
  const [OTPEntry, setOTPEntry] = useState(false);
  const [signUpEntry, setSignUpEntry] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [OTP, setOTP] = useState("");
  const [showPopup, setShowPopup] = useState(false);


  const generateRecaptcha = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      authentication,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log(response);
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          console.log("Expired");
        },
      }
    );
  };

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredNumber = e.target.value;

    // Check if the entered number exceeds the maximum limit (10 digits)
    if (enteredNumber.length <= 10) {
      setMobileNumber(enteredNumber);
    } else {
      // If more than 10 digits, show the pop-up message
      setShowPopup(true);
    }
  };

  // const toggleReadMoreLess = () => {
  //     setIsShowMore(!isShowMore);
  // };

  const { slug } = useParams();
  const [pageType, setPageType] = useState("");
  const [id, setId] = useState("");
  let [categories3] = useState(["Flight Upgrade", "Hotel Upgrade"]);
  let [categories1] = useState(["Included", "Excluded"]);
  const [adultValue, setAdultValue] = useState(1);
  const [adultAmount, setAdultAmount] = useState(1);
  const [childrenValue, setChildrenValue] = useState(0);
  const [childrenAmount, setChildrenAmount] = useState(0);
  const [infantValue, setInfantValue] = useState(0);
  const [infantAmount, setInfantAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [maximumPeople, setMaximumPeople] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [departureError, setDepartureError] = useState(false);
  const [travelError, setTravelError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [passangerError, setPassangerError] = useState(false);
  const [dynamicAccordion, setDynamicAccordion] = useState([]);
  const [placeName, setPlaceName] = useState("");
  const [packageId, setPackageId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageDays, setPackageDays] = useState("");
  const [packageType, setPackageType] = useState("");
  const [guestsTraveled, setGuestsTraveled] = useState("");
  const [packageImages, setPackageImages] = useState([]);
  const [includes, setIncludes] = useState([]);
  const [excludes, setExcludes] = useState([]);
  const [daysDetails, setDaysDetails] = useState([]);
  const [tourInfoDetails, setTourInfoDetails] = useState([]);
  const location = useLocation();
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [mobileNumber, setMobileNumber] = useState("");
  let [departureCity, setDepartureCity] = useState("");
  let [travelDate, setTravelDate] = useState("");
  let [destination, setDestination] = useState("");
  let [noofPassanger, setNoofPassanger] = useState("");
  const [inputDateType, setInputDateType] = useState("text");
  let [description, setDescription] = useState("");
  const [startingFrom, setStartingFrom] = useState(null);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [adultsValue, setAdultsValue] = useState<number[]>([]);
  const [childrensValue, setChildrensValue] = useState<number[]>([]);
  const [infantsValue, setInfantsValue] = useState<number[]>([]);
  const [childrenDates, setChildrenDates] = useState<string[]>([]);
  const [infantDates, setInfantDates] = useState<string[]>([]);
  const [roomsErrors, setRoomsErrors] = useState<string[]>([]);
  const [childDateErrors, setChildDateErrors] = useState<string[]>([]);
  const [infantDateErrors, setInfantDateErrors] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState(1);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [currentStep, setCurrentStep] = useState(3);
  const totalSteps = 3;
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);
  const [openAccordions, setOpenAccordions] = useState<boolean[]>([]);
  const [openDays, setOpenDays] = useState<boolean[]>([]);
  let [inOpen, setInOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const [faqList, setFaqList] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [bannerTitle1, setBannerTitle1] = useState("");
  const [bannerTitle2, setBannerTitle2] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");
  const [cardBannerImages, setCardBannerImages] = useState([]);
  const [activitiesImages, setActivitiesImages] = useState([]);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [topPlaces, setTopPlaces] = useState([]);
  const [middleBannerImage, setMiddleBannerImage] = useState("");
  const [packages, setPackages] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  const [showMoreStates, setShowMoreStates] = useState<{
    [key: number]: boolean;
  }>({});
  const [isIslandPage, setIsIslandPage] = useState(false);
  const [resorts, setResorts] = useState([]);
  const [isFlight, setIsFlight] = useState(false);
  const [isCruise, setIsCruise] = useState(false);
  const [isResort, setIsResort] = useState(false);
  const [resortTimings, setResortTimings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [bannerAlts, setBannerAlts] = useState([]);
  const [activitiesAlts, setActivitiesAlts] = useState([]);
  const [bannerTitle, setBannerTitle] = useState('')

  const [errorsInCheckout, setErrorsInCheckout] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleReadMoreLess = (index: number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getMonthName = (monthNumber: any) => {
    const date = new Date(2024, monthNumber - 1, 1);
    return date.toLocaleString('default', { month: 'short' });
  };

  useEffect(() => {
    const isLoggedInString = localStorage.getItem("isLoggedIn");
    const isLoggedIn = isLoggedInString ? JSON.parse(isLoggedInString) : false;
    setIsLoggedIn(isLoggedIn);
  }, []);

  function incloseModal() {
    setInOpen(false);
  }

  function inopenModal() {
    setInOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeRegisterModal() {
    setIsModalOpen(false);
  }

  function openRegisterModal() {
    setIsModalOpen(true);
  }

  const handleAirportChange = (selectedOption: any) => {
    setStartingFrom(selectedOption);
  };

  const handleEnquiryButtonClick = () => {
    setShowEnquiryPopup(true);
  };

  const handleNextButtonClick = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const updateRoomPrice = (value: any, roomText: any) => {
    setTotalAmount(
      adultValue * adultAmount +
      childrenValue * childrenAmount +
      infantValue * infantAmount +
      value
    );
    setSelectedRoom(roomText);
  };

  const handleAdultChange = (value: any) => {
    setAdultValue(value);
    setMaximumPeople(value + childrenValue + infantValue);
    setTotalAmount(
      value * adultAmount +
      childrenValue * childrenAmount +
      infantValue * infantAmount
    );
  };

  const handleChildrenChange = (value: any) => {
    setChildrenValue(value);
    setMaximumPeople(adultValue + value + infantValue);
    setTotalAmount(
      adultValue * adultAmount +
      value * childrenAmount +
      infantValue * infantAmount
    );
  };

  const handleInfantChange = (value: any) => {
    setInfantValue(value);
    setMaximumPeople(adultValue + childrenValue + value);
    setTotalAmount(
      adultValue * adultAmount +
      childrenValue * childrenAmount +
      value * infantAmount
    );
  };

  const handleEditIconClick = () => {
    setCurrentStep(1);
    setShowEnquiryPopup(false); // Show the popup again after going back to the first step
  };

  const formatDate = (inputDate: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate: string = new Date(inputDate).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  const handleSelectChange = (event: { target: { value: string } }) => {
    setSelectedOption(parseInt(event.target.value, 10));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function changeTextToDate() {
    setInputDateType("date");
  }

  const handleDateChange = (date: any) => {
    setTravelDate(date.toISOString().slice(0, 10));
  };

  const options = airports.airports.map((item: any, index: number) => ({
    value: item.airport_name,
    label: (
      <>
        <div className="flex items-center ">
          <div className=" pr-5">
            <i className="las la-plane-departure"></i>
          </div>
          <div className="font-medium text-sm w-11/12">
            {item.city_name}, India
            <p className="text-neutral-400 font-normal text-xs">
              {item.airport_name}
            </p>
          </div>
          <div className="font-semibold text-base">{item.IATA_code}</div>
        </div>
      </>
    ),
    searchLabel: `${item.airport_name}, ${item.city_name}`
  }));
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: false,
    className: "center",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  const settings1 = {
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    className: "center",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  const settingss = {
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: false,
    className: "center",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  function registerUser() {
    const requestData = {
      mobile: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      user_location: userLocation,
    };
    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/registerUser",
        requestData
      )
      .then((response: any) => {
        console.log(response.data);
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          closeRegisterModal();
        } else {
          console.log(response.data.message);
        }
      });
  }

  const sendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) {
      setIsMobileError(true);
      return;
    }
    if (!/^\d+$/.test(mobileNumber)) {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;
    const sendOtpButton = document.getElementById(
      "sendOTPBtn"
    ) as HTMLButtonElement | null;

    try {
      if (sendOtpButton) {
        sendOtpButton.disabled = true;
        sendOtpButton.innerHTML = "Checking Recaptcha...";
        generateRecaptcha();
        sendOtpButton.innerHTML = "Sending OTP...";
      }

      const appVerifier: any = (window as any).recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        authentication,
        otpNumber,
        appVerifier
      );

      (window as any).confirmationResult = confirmationResult;
      setMobileNumberEntry(false);
      setOTPEntry(true);
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
      if (sendOtpButton) {
        sendOtpButton.disabled = false;
        sendOtpButton.innerHTML = "Request OTP";
      }
    }
  };

  const resendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) {
      setIsMobileError(true);
      return;
    }
    if (!/^\d+$/.test(mobileNumber)) {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;

    try {
      const appVerifier: any = (window as any).recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        authentication,
        otpNumber,
        appVerifier
      );

      (window as any).confirmationResult = confirmationResult;
      setMobileNumberEntry(false);
      setOTPEntry(true);
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
    }
  };

  function validateOTP() {
    if (OTP.length != 6) return;

    let confirmationResult = (window as any).confirmationResult;
    confirmationResult.confirm(OTP).then((result: any) => {
      console.log(result);
      getUserDetails();
    });
  }

  function getUserDetails() {
    const requestData = { mobile: mobileNumber };

    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/userLogin",
        requestData
      )
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          (window as any).location = "/";
        } else {
          setOTPEntry(false);
          setSignUpEntry(true);
          console.log(response.data.message);
        }
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (name.length == 0) {
      setNameError(true);
    } else if (/\d/.test(name)) {
      setNameError(true);
    } else if (mobileNumber.length != 10) {
      setNameError(false);
      setMobileError(true);
    } else if (email.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(true);
    } else if (departureCity.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(true);
    } else if (travelDate.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(true);
    } else if (noofPassanger.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(false);
      setDestinationError(false);
      setPassangerError(true);
    } else {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(false);
      setDestinationError(false);
      setPassangerError(false);
      const submitButton = document.getElementById("submitButton");
      if (submitButton) {
        (submitButton as HTMLButtonElement).disabled = true;
        submitButton.innerHTML = "Submitting...";
      }
      const travelers =
        (adultValue as any) +
        " Adults, " +
        (childrenValue as any) +
        " Child, " +
        (infantValue as any) +
        " Infant";
      const formData = {
        name: name,
        mobile: mobileNumber,
        email_address: email,
        description: description,
        guests: travelers,
        room: selectedRoom,
        url: location.pathname,
        city: departureCity,
        travel_date: travelDate,
        passenger_count: noofPassanger,
      };
      axios
        .post(
          "https://admin.trawelmart.com/admin/public/index.php/api/addContact",
          formData
        )
        .then((response: any) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.status) {
              setName("");
              setMobileNumber("");
              setEmail("");
              setDescription("");
              setSelectedRoom("");
              setDepartureCity("");
              setTravelDate("");
              setNoofPassanger("");
              (window as any).location = "/thanks";
            } else {
              toastr.error(response.data.message);
            }
          } else {
            console.log(response.responseText);
          }
        });
    }
  };

  function handleCheckout() {
    if (isLoggedIn) {
      if (!errorsInCheckout) {
        localStorage.setItem("packageId", packageId);
        localStorage.setItem("checkinDate", checkInDate);
        localStorage.setItem("checkoutDate", checkOutDate);
        localStorage.setItem("departure", (startingFrom as any).value);
        localStorage.setItem("adultCount", adultValue + "");
        localStorage.setItem("childrenCount", childrenValue + "");
        localStorage.setItem("infantCount", infantValue + "");
        localStorage.setItem("totalAmount", totalAmount + "");
        localStorage.setItem("childrenDates", childrenDates.join(","));
        localStorage.setItem("infantDates", infantDates.join(","));
        localStorage.setItem("numberOfRooms", selectedOption + "");
        localStorage.setItem("adultRooms", adultsValue.join(", "));
        localStorage.setItem("childrenRooms", childrensValue.join(", "));
        localStorage.setItem("infantRooms", infantsValue.join(", "));
        (window as any).location = "/checkout";
      }
    }
    else {
      openRegisterModal();
    }
  }

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const handlePrevButtonClick = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  function checkRoomCapacity() {
    const newRoomsErrors = Array(selectedOption).fill("");
    let hasError = false;

    for (let i = 0; i < selectedOption; i++) {
      if (adultsValue[i] == 0) {
        newRoomsErrors[i] = "Room should have one adult";
        hasError = true;
      } else {
        const sum = adultsValue[i] + childrensValue[i] + infantsValue[i];
        if (sum > 6) {
          newRoomsErrors[i] = "Room can only have a maximum of 6 people";
          hasError = true;
        }
      }
    }

    setRoomsErrors(newRoomsErrors);
    setErrorsInCheckout(hasError);
    // (document as any).getElementById("bookOnlineButton").disabled = isButtonDisabled;
  }

  function checkChildDate() {
    const newChildDateErrors = [];
    let hasError = false;

    for (var i = 0; i < childrenDates.length; i++) {
      const birthDate = new Date(childrenDates[i]);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthDate.getFullYear();

      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age = age - 1;
      }

      if (age < 2 || age > 13) {
        newChildDateErrors[i] = "Age should be between 2-13 years";
        hasError = true;
        // (document as any).getElementById("bookOnlineButton").disabled = true;

      } else {
        newChildDateErrors[i] = "";
        // (document as any).getElementById("bookOnlineButton").disabled = false;
      }
      setChildDateErrors(newChildDateErrors);
      setErrorsInCheckout(hasError);
    }
  }

  function checkInfantDate() {
    const newInfantDateErrors = [];
    const currentDate = new Date();
    let hasError = false;

    for (let i = 0; i < infantDates.length; i++) {
      const birthDate = new Date(infantDates[i]);
      const twoYearsAgo = new Date(currentDate);
      twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);

      if (birthDate >= twoYearsAgo && birthDate <= currentDate) {
        newInfantDateErrors[i] = "";
      } else {
        newInfantDateErrors[i] = "Age should be between 0-2 years";
        hasError = true;
      }
    }
    setErrorsInCheckout(hasError);
    setInfantDateErrors(newInfantDateErrors);
  }

  const accordionData = [
    {
      title: "Tour Preparation",
      content: [
        <ul key="list">
          <p className="text-base text-justify font-regular pt-4">
            Preparing for an international tour involves several important
            steps. As everyone wishes to invest in themselves and explore the
            world, we have a few helpful tips to ensure a stress-free holiday.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Passport and Visa:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Ensure your passport is valid for at least six months beyond your
            return date.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Obtain the necessary visas for your destination(s).
          </p>
          <p className="text-base font-medium text-base pt-2">
            Travel Itinerary:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            carry a copy of your itinerary, with all the bookings and
            reservations made in advance.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Share your itinerary with a trusted person and check in regularly.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Health Preparations:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Consult your doctor for required vaccinations or medications.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Carry a copy of your medical records and any necessary
            prescriptions.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Travel Insurance:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Purchase comprehensive travel insurance to cover unexpected events.
          </p>

          <p className="text-base font-medium text-base pt-2">
            Money and Finances:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Notify your bank of your travel plans to avoid issues with your
            cards.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Carry some local currency and a credit/debit card for emergencies.
          </p>
          <p className="text-base font-medium text-base pt-2">Packing:</p>
          <p className="text-base text-justify font-regular pt-1">
            Always make sure to carry less and light luggage, use a four-wheel
            small or medium suitcase and please make sure that you are carrying
            only one suitcase per person. This will ensure that you will travel
            smartly and without any inconveniences.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Create a packing list to ensure you don&#39;t forget essentials.
            Pack weather-appropriate clothing and accessories. If you are
            travelling during the monsoon or to destinations where it is rainy,
            please carry a small umbrella or poncho
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Don&#39;t forget chargers, adapters, and a travel-sized first-aid
            kit. A smart cap/hat and sunglasses are definitely something that
            you should have in your kit.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Important Documents:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Make photocopies of your passport, visa, and travel insurance.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Store digital copies of these documents securely.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Safety and Security:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Research the safety situation at your destination.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Language and Culture:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Learn basic phrases in the local language.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Familiarize yourself with local customs and etiquette.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Ensure that your clothing and footwear is suitable for the
            destination you are travelling to.
          </p>
          <p className="text-base font-medium text-base pt-2">Electronics:</p>
          <p className="text-base text-justify font-regular pt-1">
            Bring necessary gadgets (phone, camera, etc.) and chargers.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Check voltage requirements and bring appropriate adapters.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            For international tours, please carry a universal adapter for
            charging your cameras and mobile phones.
          </p>
          <p className="text-base font-medium text-base pt-2">Entertainment:</p>
          <p className="text-base text-justify font-regular pt-1">
            Pack books, music, or other forms of entertainment for long flights
            or downtime.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Health and Wellness:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Carry any essential medications and a small first-aid kit.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Stay hydrated and maintain a balanced diet while traveling.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Travel Accessories:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Consider items like a neck pillow, eye mask, and earplugs for
            comfort during long flights.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Transportation:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Arrange transportation from the airport to your accommodation in
            advance.
          </p>
          <p className="text-base font-medium text-base pt-2">
            Local Information:
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Research local transportation options and maps.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Know emergency numbers and embassy contact information.
          </p>
          <p className="text-base font-medium text-base pt-2">Packing Light:</p>
          <p className="text-base text-justify font-regular pt-1">
            It is also recommended that you have one cross shoulder bag or
            haversack for your handy needs which will ensure that you are
            comfortable at the time of sightseeing and also easy for photography
            during your tour.
          </p>
          <p className="text-base text-justify font-regular pt-1">
            Try to minimize the amount of luggage you bring to make travel
            easier.
          </p>
          <p className="text-base font-medium text-base pt-2">Travel Apps:</p>
          <p className="text-base text-justify font-regular pt-1">
            Download useful travel apps, such as maps, translation, and currency
            conversion.
          </p>
          <p className="text-base font-medium text-base pt-2">Safety Gear:</p>
          <p className="text-base text-justify font-regular pt-1">
            Depending on your destination, consider items like a money belt or
            RFID- blocking wallet.
          </p>
          <p className="text-base font-medium text-base pt-2">Communication:</p>
          <p className="text-base text-justify font-regular pt-1">
            Buy a local SIM card or consider international roaming options for
            your phone.
          </p>
          <p className="text-base font-medium text-base pt-2">Enjoy:</p>
          <p className="text-base text-justify font-regular pt-1">
            Finally, relax and enjoy your trip, but stay vigilant and adapt to
            the local culture.
          </p>
          <p className="text-base text-justify font-regular pt-2">
            Remember that this checklist can vary depending on your destination
            and personal preferences, so adjust it accordingly. Safe travels!
          </p>
        </ul>,
      ],
    },

    {
      title: "Cancellation Policy",
      content: [
        <table key="table" className="table ">
          <table className="mt-2" style={{ width: "710px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Number of days before departure, TRAWEL MART WORLD TOURS
                  receives your cancellations
                </th>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Amount of cancellations charge shown as a % of a total holiday
                  price you must pay
                </th>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  If we cancel your holiday, amount you will receive from us
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  More than 45 days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Deposit Only
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Advance Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  44 - 30 Days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  40% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  29 - 15 days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  60% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  15 days or less
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  90% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received plus 10% of the amount
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </table>,
      ],
    },
  ];

  const accordionData2 = [
    {
      title: "Flight Upgrade",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">
            Need to upgrade to business or first class? Please get in touch with
            our team on +91 8046427999 for more details.
          </li>
        </ul>,
      ],
    },

    {
      title: "Hotel Upgrade",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">
            You can select the Hotel Upgrade in your coach at an additional
            cost. Please get in touch with our team on +91 8046427999 for more
            details.
          </li>
        </ul>,
      ],
    },
  ];

  const accordionData3 = [
    {
      title: "Tour Preparation",
      content: [
        <ul key="list">
          <div className="-mt-6 p-4">
            <p className="text-base text-justify font-regular pt-4">
              Preparing for an international tour involves several important
              steps. As everyone wishes to invest in themselves and explore the
              world, we have a few helpful tips to ensure a stress-free holiday.
            </p>
            <p className="font-medium text-base pt-2">Passport and Visa:</p>
            <p className="text-base text-justify font-regular pt-1">
              Ensure your passport is valid for at least six months beyond your
              return date.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Obtain the necessary visas for your destination(s).
            </p>
            <p className="font-medium text-base pt-2">Travel Itinerary:</p>
            <p className="text-base text-justify font-regular pt-1">
              carry a copy of your itinerary, with all the bookings and
              reservations made in advance.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Share your itinerary with a trusted person and check in regularly.
            </p>
            <p className="font-medium text-base pt-2">Health Preparations:</p>
            <p className="text-base text-justify font-regular pt-1">
              Consult your doctor for required vaccinations or medications.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Carry a copy of your medical records and any necessary
              prescriptions.
            </p>
            <p className="font-medium text-base pt-2">Travel Insurance:</p>
            <p className="text-base text-justify font-regular pt-1">
              Purchase comprehensive travel insurance to cover unexpected
              events.
            </p>

            <p className="font-medium text-base pt-2">Money and Finances:</p>
            <p className="text-base text-justify font-regular pt-1">
              Notify your bank of your travel plans to avoid issues with your
              cards.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Carry some local currency and a credit/debit card for emergencies.
            </p>
            <p className="font-medium text-base pt-2">Packing:</p>
            <p className="text-base text-justify font-regular pt-1">
              Always make sure to carry less and light luggage, use a four-wheel
              small or medium suitcase and please make sure that you are
              carrying only one suitcase per person. This will ensure that you
              will travel smartly and without any inconveniences.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Create a packing list to ensure you don&#39;t forget essentials.
              Pack weather-appropriate clothing and accessories. If you are
              travelling during the monsoon or to destinations where it is
              rainy, please carry a small umbrella or poncho
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Don&#39;t forget chargers, adapters, and a travel-sized first-aid
              kit. A smart cap/hat and sunglasses are definitely something that
              you should have in your kit.
            </p>
            <p className="font-medium text-base pt-2">Important Documents:</p>
            <p className="text-base text-justify font-regular pt-1">
              Make photocopies of your passport, visa, and travel insurance.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Store digital copies of these documents securely.
            </p>
            <p className="font-medium text-base pt-2">Safety and Security:</p>
            <p className="text-base text-justify font-regular pt-1">
              Research the safety situation at your destination.
            </p>
            <p className="font-medium text-base pt-2">Language and Culture:</p>
            <p className="text-base text-justify font-regular pt-1">
              Learn basic phrases in the local language.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Familiarize yourself with local customs and etiquette.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Ensure that your clothing and footwear is suitable for the
              destination you are travelling to.
            </p>
            <p className="font-medium text-base pt-2">Electronics:</p>
            <p className="text-base text-justify font-regular pt-1">
              Bring necessary gadgets (phone, camera, etc.) and chargers.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Check voltage requirements and bring appropriate adapters.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              For international tours, please carry a universal adapter for
              charging your cameras and mobile phones.
            </p>
            <p className="font-medium text-base pt-2">Entertainment:</p>
            <p className="text-base text-justify font-regular pt-1">
              Pack books, music, or other forms of entertainment for long
              flights or downtime.
            </p>
            <p className="font-medium text-base pt-2">Health and Wellness:</p>
            <p className="text-base text-justify font-regular pt-1">
              Carry any essential medications and a small first-aid kit.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Stay hydrated and maintain a balanced diet while traveling.
            </p>
            <p className="font-medium text-base pt-2">Travel Accessories:</p>
            <p className="text-base text-justify font-regular pt-1">
              Consider items like a neck pillow, eye mask, and earplugs for
              comfort during long flights.
            </p>
            <p className="font-medium text-base pt-2">Transportation:</p>
            <p className="text-base text-justify font-regular pt-1">
              Arrange transportation from the airport to your accommodation in
              advance.
            </p>
            <p className="font-medium text-base pt-2">Local Information:</p>
            <p className="text-base text-justify font-regular pt-1">
              Research local transportation options and maps.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Know emergency numbers and embassy contact information.
            </p>
            <p className="font-medium text-base pt-2">Packing Light:</p>
            <p className="text-base text-justify font-regular pt-1">
              It is also recommended that you have one cross shoulder bag or
              haversack for your handy needs which will ensure that you are
              comfortable at the time of sightseeing and also easy for
              photography during your tour.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Try to minimize the amount of luggage you bring to make travel
              easier.
            </p>
            <p className="font-medium text-base pt-2">Travel Apps:</p>
            <p className="text-base text-justify font-regular pt-1">
              Download useful travel apps, such as maps, translation, and
              currency conversion.
            </p>
            <p className="font-medium text-base pt-2">Safety Gear:</p>
            <p className="text-base text-justify font-regular pt-1">
              Depending on your destination, consider items like a money belt or
              RFID- blocking wallet.
            </p>
            <p className="font-medium text-base pt-2">Communication:</p>
            <p className="text-base text-justify font-regular pt-1">
              Buy a local SIM card or consider international roaming options for
              your phone.
            </p>
            <p className="font-medium text-base pt-2">Enjoy:</p>
            <p className="text-base text-justify font-regular pt-1">
              Finally, relax and enjoy your trip, but stay vigilant and adapt to
              the local culture.
            </p>
            <p className="text-base text-justify font-regular pt-2">
              Remember that this checklist can vary depending on your
              destination and personal preferences, so adjust it accordingly.
              Safe travels!
            </p>
          </div>
        </ul>,
      ],
    },

    {
      title: "Cancellation Policy",
      content: [
        <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
          <ul className="text-base font-medium pt-6">
            <table className="w-full">
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Number of days before departure, TRAWEL MART WORLD TOURS
                    receives your cancellations
                  </th>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Amount of cancellations charge shown as a % of a total
                    holiday price you must pay
                  </th>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    If we cancel your holiday, amount you will receive from us
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    More than 45 days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Deposit Only
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Advance Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    44 - 30 Days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    40% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    29 - 15 days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    60% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    15 days or less
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    90% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received plus 10% of the amount
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </ul>
        </div>,
      ],
    },

    {
      title: "Transport Details",
      content: [
        <ul key="list" className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className=" p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Making the finest for you!.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We are trying to reserve the airplane tickets for this trip. We
                will update it here, as we are done.
              </li>
              <li className="lg:text-base md:text-base text-sm  font-normal">
                Post Booking, you will be notified through your contact details
                about all tour related updates.
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },

    {
      title: "Accomodation Details",
      content: [
        <ul key="list" className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className=" p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Processing the best for you!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We are in the process of booking the accommodation for this
                tour. We will update it here, as we are done.
              </li>
              <li className="lg:text-base md:text-base text-sm  font-normal">
                Post Booking, you will be notified through your contact details
                about all tour related updates.
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },
  ];

  const formatPrice = (price: string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ''));

    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return 'Invalid input';
    }

    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formattedAmount;
  };

  const toggleDay = (index: number) => {
    // Create a copy of the openDays array
    const newOpenDays = [...openDays];

    // Toggle the state for the clicked day
    newOpenDays[index] = !newOpenDays[index];

    // Update the state to trigger a re-render
    setOpenDays(newOpenDays);
  };

  const toggleAccordion = (index: number) => {
    // Create a copy of the openAccordions array
    const newOpenAccordions = [...openAccordions];

    // Toggle the state for the clicked item
    newOpenAccordions[index] = !newOpenAccordions[index];

    // Update the state to trigger a re-render
    setOpenAccordions(newOpenAccordions);
  };

  useEffect(() => {
    setPackageType("");
    setIsLoading(true);
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getPagesBySlug/" +
        slug
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setPageType(response.data.page_type);
            if (response.data.page_type == "package") {
              setId(response.data.package.id);
              axios
                .get(
                  "https://admin.trawelmart.com/admin/public/index.php/api/getTourPackageForWebsite/" +
                  response.data.package.id
                )
                .then((response) => {
                  console.log(response.data);
                  if (response.data.status) {
                    setPackageId(response.data.tour.id);
                    setPackageName(response.data.tour.tour_title);
                    setDescription(
                      "Enquiring for " + response.data.tour.tour_title
                    );
                    setPackageDays(response.data.tour.tour_days);
                    setPlaceName(response.data.tour.tour_city);
                    setPackageType(response.data.tour.tour_type);
                    setPackageImages(
                      response.data.tour.photos_details
                        .split(", ")
                        .map(
                          (img: string) =>
                            `https://admin.trawelmart.com/admin/public/${img}`
                        )
                    );
                    setDaysDetails(response.data.days);
                    setIncludes(response.data.includes);
                    setExcludes(response.data.excludes);
                    setTourInfoDetails(response.data.tour_info);
                    response.data.tour.visited_people_count
                      ? setGuestsTraveled(
                        response.data.tour.visited_people_count
                      )
                      : setGuestsTraveled("9234");
                    response.data.tour.is_flight
                      ? setIsFlight(response.data.tour.is_flight === "Yes")
                      : setIsFlight(false);
                    response.data.tour.is_cruise
                      ? setIsCruise(response.data.tour.is_cruise === "Yes")
                      : setIsCruise(false);
                    response.data.tour.is_resort
                      ? setIsResort(response.data.tour.is_resort === "Yes")
                      : setIsResort(false);
                    response.data.timing
                      ? setResortTimings(response.data.timing)
                      : setResortTimings([]);
                  }
                });
              axios
                .get(
                  "https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/" +
                  response.data.package.id
                )
                .then((response) => {
                  console.log("Price response is" + response.data);
                  if (response.status == 200) {
                    setAdultAmount(response.data.adult);
                    setChildrenAmount(response.data.child);
                    setInfantAmount(response.data.infant);
                    setTotalAmount(response.data.adult);
                  }
                });
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            } else if (response.data.page_type == "landing") {
              axios
                .get(
                  "https://admin.trawelmart.com/admin/public/index.php/api/getLandingPageForWebsite/" +
                  response.data.landing.id
                )
                .then((response) => {
                  if (response.status === 200 && response.data.status) {
                    setBannerImage(response.data.landing.banner_image);
                    setBannerTitle1(response.data.landing.banner_title_1);
                    setBannerTitle2(response.data.landing.banner_title_2);
                    setDescription(
                      "Enquiring for " +
                      response.data.landing.city +
                      " tour packages"
                    );
                    setBannerDescription(
                      response.data.landing.banner_description
                    );
                    setCardBannerImages(
                      response.data.landing.card_banner_images.split(", ")
                    );
                    response.data.landing.activities_images
                      ? setActivitiesImages(
                        response.data.landing.activities_images.split(", ")
                      )
                      : setActivitiesImages([]);
                    setCity(response.data.landing.city);
                    response.data.top_places
                      ? setTopPlaces(response.data.top_places)
                      : setTopPlaces([]);
                    setMiddleBannerImage(
                      response.data.landing.middle_banner_image
                    );
                    response.data.faqs
                      ? setFaqList(response.data.faqs)
                      : setFaqList([]);
                    response.data.packages
                      ? setPackages(response.data.packages)
                      : setPackages([]);
                    response.data.landing.is_island
                      ? setIsIslandPage(
                        response.data.landing.is_island === "Yes"
                      )
                      : setIsIslandPage(false);
                    response.data.resorts
                      ? setResorts(response.data.resorts)
                      : setResorts([]);
                    response.data.landing.page_title
                      ? setPageTitle(response.data.landing.page_title)
                      : setPageTitle(
                        "Elegant Travel India's leading and award winning travel agency"
                      );
                    response.data.landing.meta_keywords
                      ? setMetaKeywords(response.data.landing.meta_keywords)
                      : setMetaKeywords(
                        "Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
                      );
                    response.data.landing.meta_description
                      ? setMetaDescription(
                        response.data.landing.meta_description
                      )
                      : setMetaDescription(
                        "We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
                      );
                    response.data.landing.banner_alts
                      ? setBannerAlts(
                        response.data.landing.banner_alts.split(",")
                      )
                      : setBannerAlts([]);
                    response.data.landing.activities_alts
                      ? setActivitiesAlts(
                        response.data.landing.activities_alts.split(",")
                      )
                      : setActivitiesAlts([]);
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 1000);
                  }
                });
            } else if (response.data.page_type == "island") {
              axios
                .get(
                  "https://admin.trawelmart.com/admin/public/index.php/api/getIslandForWebsite/" +
                  response.data.island.id
                )
                .then((response) => {
                  if (response.status === 200 && response.data.status) {
                    setPlaceName(response.data.island.city);
                    setPackageName(response.data.island.title);
                    setPackageDays(response.data.island.days);
                    setPackageType(response.data.island.island_type);
                    response.data.island.is_flight ? setIsFlight(response.data.island.is_flight === "Yes") : setIsFlight(false)
                    response.data.island.is_cruise ? setIsCruise(response.data.island.is_cruise === "Yes") : setIsCruise(false)
                    setGuestsTraveled(response.data.island.number_of_guests);
                    setPackageImages(response.data.island.photos_details.split(", ").map((img: string) => `https://admin.trawelmart.com/admin/public/${img}`))
                    response.data.resorts ? setResorts(response.data.resorts) : setResorts([])
                    response.data.infos ? setTourInfoDetails(response.data.infos) : setTourInfoDetails([])
                    response.data.island.banner_title ? setBannerTitle(response.data.island.banner_title) : setBannerTitle('')
                    response.data.island.banner_description ? setBannerDescription(response.data.island.banner_description) : setBannerDescription('')
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 1000);
                  }
                });
            }
          }
        }
      });
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight / 3 &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveMenuItem(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const userDataJSON = localStorage.getItem("loggedInUser");
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setName(userData.name);
      setMobileNumber(userData.mobile);
      setEmail(userData.email);
    }
  }, []);

  useEffect(() => {
    if (checkInDate !== "") {
      var result = new Date(checkInDate);
      result.setDate(result.getDate() + parseInt(packageDays));
      var year = result.getFullYear();
      var month = (result.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month and pad with leading zero if needed
      var day = result.getDate().toString().padStart(2, "0");
      var endDate = year + "-" + month + "-" + day;
      setCheckOutDate(endDate);
    }
  }, [checkInDate, packageDays]);

  useEffect(() => {
    const sum = adultsValue.reduce((acc, item) => acc + item, 0);
    setAdults(sum);
    setAdultValue(sum);
    setTotalAmount(
      sum * adultAmount +
      childrenValue * childrenAmount +
      infantValue * infantAmount
    );
    checkRoomCapacity();
  }, [adultsValue]);

  useEffect(() => {
    const sum = childrensValue.reduce((acc, item) => acc + item, 0);
    setChildren(sum);
    setChildrenValue(sum);
    setTotalAmount(
      adultValue * adultAmount +
      sum * childrenAmount +
      infantValue * infantAmount
    );
    checkRoomCapacity();
  }, [childrensValue]);

  useEffect(() => {
    const sum = infantsValue.reduce((acc, item) => acc + item, 0);
    setInfants(sum);
    setInfantValue(sum);
    setTotalAmount(
      adultValue * adultAmount +
      childrenValue * childrenAmount +
      sum * infantAmount
    );
    checkRoomCapacity();
  }, [infantsValue]);

  useEffect(() => {
    checkChildDate();
  }, [childrenDates]);

  useEffect(() => {
    checkInfantDate();
  }, [infantDates]);


  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };


  return (
    <div>
      <>
        {pageType === "landing" && (
          <>
            <Helmet>
              <title>{pageTitle}</title>
              <meta name="description" content={metaDescription} />
              <meta name="keywords" content={metaKeywords} />
            </Helmet>
            <div className=" nc-PageHome relative overflow-hidden">
              {/* GLASSMOPHIN */}
              <div
                className="font xl:block lg:block hidden md:hidden sm:hidden"
              // style={{
              //   backgroundImage: `url("${isLoading
              //       ? ""
              //       : "https://admin.trawelmart.com/admin/public/" +
              //       bannerImage
              //     }")`,
              //   width: "100%",
              //   height: "475px",
              //   backgroundSize: "cover",
              // }}
              >
                <img src={isLoading ? "" : "https://admin.trawelmart.com/admin/public/" + bannerImage} alt="" style={{ width: "100%" }} />
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-2 ">
                  <div
                    className={` nc-SectionHero grid xl:grid-cols-1  xl:gap-6 lg:grid-cols-2 lg:gap-6 md:grid-cols-2 relative items-center`}
                    data-nc-id="SectionHero"
                  >
                    <div className="lg:items-center text-left  ">
                      <div className="flex-shrink-0 text-center lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:-mt-80 lg:-mt-72 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                        {isLoading ? (
                          <>
                            <Skeleton height={10} />
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            <p className="text-3xl fontfamily pt-2 text-white">
                              {bannerTitle1}
                            </p>
                            <h2 className=" uppercase font-semibold text-4xl md:text-5xl xl:text-5xl !leading-[114%] lg:pb-3 mt-2 text-white" style={{ fontFamily: 'Times New Roman' }}>
                              {bannerTitle2}
                            </h2>
                          </>
                        )}
                        {isLoading ? (
                          <>
                            <Skeleton count={2} />
                          </>
                        ) : (
                          <>
                            <span
                              className="fontfamily text-base md:text-lg lg:text-xl font-normal text-white dark:text-neutral-400 lg:hidden block xl:px-72"
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                            <span
                              className="fontfamily text-base md:text-lg lg:text-xl font-normal text-white dark:text-neutral-400 lg:block hidden xl:px-72"
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                          </>
                        )}
                        {/*<div className="flex pt-4">
                                <button
                                    type="button"
                                    onClick={openModal}
                                    className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                    Enquire Now
                                </button>
                                <Transition appear show={isOpen} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                                        </Transition.Child>

                                        <div className="font fixed inset-0 overflow-y-auto">
                                            <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0 scale-95"
                                                    enterTo="opacity-100 scale-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100 scale-100"
                                                    leaveTo="opacity-0 scale-95"
                                                >
                                                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                        <div className="text-right mt-2 mr-2">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                onClick={closeModal}
                                                            >
                                                                X
                                                            </button>
                                                        </div>

                                                        <Dialog.Title
                                                            as="h3"
                                                            className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                        >
                                                            Quick Enquiry
                                                            <p className="pt-2 font-normal text-xs text-sky-500">
                                                                Plan an unforgettable trip with Trawel Mart
                                                            </p>
                                                        </Dialog.Title>

                                                        <div className="mt-2">
                                                            <form>
                                                                <div className="mx-auto">
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                                        {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                                    </label>
                                                                    {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                    Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                      </p>
                                                                </div>
                                                                <div className="xl:px-16 px-4 mt-4">
                                                                    <button type="button"
                                                                        className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                    font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                    focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                                        Submit Enquiry
                                                                    </button>
                                                                </div>
                                                                <p className="mt-4 text-xs text-center">
                                                                    Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                                </p>
                                                                <div className="bg-[#fbf7fb] mt-6">
                                                                    <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-9/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    Super Offers
                                                                                </span>
                                                                                <p className="text-sky-700 italic font-normal text-xs ">
                                                                                    Explore Great Deals
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-10/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    My Trips
                                                                                </span>
                                                                                <p className="text-sky-700 font-normal italic text-xs ">
                                                                                    Manage Your Bookings
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Dialog.Panel>
                                                </Transition.Child>
                                            </div>
                                        </div>
                                    </Dialog>
                                </Transition>
                                <a href="/contact">
                                    <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                        Contact Us
                                    </button>
                                </a>
                            </div>*/}
                      </div>
                    </div>

                    {/* <div className="text-white pl-12 my-6 xl:mt-4 xl:-mt-0 lg:mt-20 lg:mt-0 md:mt-12 xl:px-12 md:px-0">
                      <div className="bg-white pb-3 rounded-lg">
                        <div className="mt-2 ">
                          <form onSubmit={handleSubmit}>
                            <div className="mx-auto">
                              {isLoading ? (
                                <>
                                  <Skeleton count={2} height={12} />
                                </>
                              ) : (
                                <>
                                  <div className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] xl:-mt-8 lg:-mt-24  md:-mt-8 -mt-8 lg:pt-4 text-xl font-semibold leading-6 text-black">
                                    Quick Enquiry
                                    <p className="pt-2 font-normal text-xs text-sky-500">
                                      Plan an unforgettable trip with Trawel
                                      Mart
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="px-3">
                                <div className="grid grid-cols-2">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton height={40} />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton height={40} />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="text"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          placeholder="Full Name"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6"
                                        />
                                        {nameError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid name
                                          </p>
                                        )}
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type=""
                                          value={mobileNumber}
                                          onChange={(e) =>
                                            setMobileNumber(e.target.value)
                                          }
                                          placeholder="Phone Number"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6"
                                        />
                                        {mobileError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid mobile number
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="grid grid-cols-2">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="email"
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          placeholder="Email ID"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {emailError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid email
                                          </p>
                                        )}
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="text"
                                          value={departureCity}
                                          onChange={(e) =>
                                            setDepartureCity(e.target.value)
                                          }
                                          placeholder="Departure City"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {departureError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid departure city
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>

                                <div className="grid grid-cols-2 ">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <DatePicker
                                          selected={
                                            travelDate
                                              ? new Date(travelDate)
                                              : null
                                          }
                                          onChange={handleDateChange}
                                          id="travelDateInput"
                                          placeholderText="Travel Date"
                                          minDate={new Date()}
                                          dateFormat={"d-M-Y"}
                                          popperPlacement="top"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {travelError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid travel date
                                          </p>
                                        )}
                                      </label>
                                      {/* <label className="block xl:px-16 px-4">
                                                          <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                          {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                      </label> 
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="number"
                                          value={noofPassanger}
                                          onChange={(e) =>
                                            setNoofPassanger(e.target.value)
                                          }
                                          placeholder="No of Passenger"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {passangerError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid number of
                                            passenger
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>
                                {isLoading ? (
                                  <>
                                    <label className="block xl:px-2 px-2">
                                      <Skeleton height={50} className="mt-4" />
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <label className="block xl:px-2 px-2">
                                      <textarea
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        placeholder="Drop us a small description"
                                        className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                                      />
                                    </label>
                                  </>
                                )}
                                {/* <div id="recaptcha-container"></div> */}
                    {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                            </label> 
                              </div>

                              <div className="xl:px-6 lg:px-4 md:px-4 px-4 mt-4 mb-2">
                                {isLoading ? (
                                  <>
                                    <Skeleton height={50} className="mt-2" />
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="submit"
                                      id="submitButton"
                                      className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                      font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                      focus-visible:ring-opacity-75"
                                    >
                                      Submit Enquiry
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* ======== BANNER LG SCREEN VIEW ============ */}

              <div
                className="font xl:hidden lg:hidden hidden md:hidden sm:hidden"
              // style={{
              //   backgroundImage: `url("${isLoading
              //       ? ""
              //       : "https://admin.trawelmart.com/admin/public/" +
              //       bannerImage
              //     }")`,
              //   width: "100%",
              //   height: "350px",
              //   backgroundSize: "cover",
              // }}
              >
                <img src={isLoading ? "" : "https://admin.trawelmart.com/admin/public/" + bannerImage} alt="" style={{ width: "100%" }} />
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                  <div
                    className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                    data-nc-id="SectionHero"
                  >
                    <div className="lg:items-center text-left lg:pt-16 ">
                      <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-6 lg:mt-6 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                        {isLoading ? (
                          <>
                            <Skeleton height={10} />
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            <p className="text-lg  text-white">
                              {bannerTitle1}
                            </p>
                            <h2 className="font font-bold text-4xl md:text-4xl xl:text-4xl !leading-[114%] lg:pb-2 mt-0 text-white">
                              {bannerTitle2}
                            </h2>
                          </>
                        )}
                        {isLoading ? (
                          <>
                            <Skeleton count={2} />
                          </>
                        ) : (
                          <>
                            <span
                              className="text-base md:text-lg lg:text-base font-normal text-white dark:text-neutral-400 lg:hidden block "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                            <span
                              className="text-base md:text-lg lg:text-base font-normal text-white dark:text-neutral-400 lg:block hidden "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                          </>
                        )}
                        {/* <div className="flex pt-4">
                          <button
                            type="button"
                            onClick={openModal}
                            className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm"
                          >
                            Enquire Now
                          </button>
                          <Transition appear show={isOpen} as={Fragment}>
                            <Dialog
                              as="div"
                              className="relative z-10"
                              onClose={closeModal}
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                              </Transition.Child>

                              <div className="font fixed inset-0 overflow-y-auto">
                                <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                  >
                                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                      <div className="text-right mt-2 mr-2">
                                        <button
                                          type="button"
                                          className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                          onClick={closeModal}
                                        >
                                          X
                                        </button>
                                      </div>
                                      {isLoading ? (
                                        <>
                                          <Skeleton count={2} height={12} />
                                        </>
                                      ) : (
                                        <>
                                          <Dialog.Title
                                            as="h3"
                                            className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                          >
                                            Quick Enquiry
                                            <p className="pt-2 font-normal text-xs text-sky-500">
                                              Plan an unforgettable trip with
                                              Trawel Mart
                                            </p>
                                          </Dialog.Title>
                                        </>
                                      )}

                                      <div className="mt-2">
                                        <form onSubmit={handleSubmit}>
                                          <div className="mx-auto">
                                            {isLoading ? (
                                              <>
                                                <label className="block xl:px-16 px-4">
                                                  <Skeleton height={40} />
                                                </label>
                                                <label className="block xl:px-16 px-4">
                                                  <Skeleton height={40} />
                                                </label>
                                              </>
                                            ) : (
                                              <>
                                                <label className="block xl:px-16 px-4">
                                                  <Input
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) =>
                                                      setName(e.target.value)
                                                    }
                                                    placeholder="Full Name"
                                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                                                  />
                                                  {nameError && (
                                                    <p
                                                      style={{
                                                        textAlign: "start",
                                                        color: "red",
                                                      }}
                                                    >
                                                      Please enter valid name
                                                    </p>
                                                  )}
                                                </label>
                                                <label className="block xl:px-16 px-4">
                                                  <Input
                                                    type="number"
                                                    value={mobileNumber}
                                                    onChange={(e) =>
                                                      setMobileNumber(
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder="Phone Number"
                                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                                  />
                                                  {mobileError && (
                                                    <p
                                                      style={{
                                                        textAlign: "start",
                                                        color: "red",
                                                      }}
                                                    >
                                                      Please enter valid mobile
                                                      number
                                                    </p>
                                                  )}
                                                </label>
                                              </>
                                            )}
                                            {isLoading ? (
                                              <>
                                                <label className="block xl:px-16 px-4">
                                                  <Skeleton
                                                    height={40}
                                                    className="mt-4"
                                                  />
                                                </label>
                                                <label className="block xl:px-16 px-4">
                                                  <Skeleton
                                                    height={40}
                                                    className="mt-4"
                                                  />
                                                </label>
                                              </>
                                            ) : (
                                              <>
                                                <label className="block xl:px-16 px-4">
                                                  <Input
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) =>
                                                      setEmail(e.target.value)
                                                    }
                                                    placeholder="Email ID"
                                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                                  />
                                                  {emailError && (
                                                    <p
                                                      style={{
                                                        textAlign: "start",
                                                        color: "red",
                                                      }}
                                                    >
                                                      Please enter valid email
                                                    </p>
                                                  )}
                                                </label>
                                                <label className="block xl:px-16 px-4">
                                                  <textarea
                                                    value={description}
                                                    onChange={(e) =>
                                                      setDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder="Drop us a small description"
                                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4"
                                                  />
                                                </label>
                                              </>
                                            )}
                                            {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                    Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                      </p>
                                          </div>
                                          <div className="xl:px-16 px-4 mt-4">
                                            {isLoading ? (
                                              <>
                                                <Skeleton
                                                  height={50}
                                                  className="mt-2"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  type="submit"
                                                  className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                      font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                      focus-visible:ring-opacity-75"
                                                >
                                                  Submit Enquiry
                                                </button>
                                              </>
                                            )}
                                          </div>
                                          {isLoading ? (
                                            <>
                                              <Skeleton count={2} height={14} />
                                            </>
                                          ) : (
                                            <>
                                              <p className="mt-4 text-xs text-center">
                                                Get the most recent travel
                                                offers, new tour <br />
                                                announcements, trip ideas and
                                                much more.
                                              </p>
                                            </>
                                          )}
                                          <div className="bg-[#fbf7fb] mt-6">
                                            <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                              <div className="lg:w-6/12 text-left flex items-center">
                                                <div className="lg:w-2/12">
                                                  <LazyLoadImage
                                                    effect="blur"
                                                    className="block dark:hidden w-8"
                                                    src={presents}
                                                    alt="Presents"
                                                  />
                                                </div>
                                                <div className="lg:w-9/12 pl-2">
                                                  <span className="text-sm font-medium ">
                                                    Super Offers
                                                  </span>
                                                  <p className="text-sky-700 font font-normal text-xs ">
                                                    Explore Great Deals
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="lg:w-6/12 text-left flex items-center">
                                                <div className="lg:w-2/12">
                                                  <LazyLoadImage
                                                    effect="blur"
                                                    className="block dark:hidden w-8"
                                                    src={luggage}
                                                    alt="Luggage"
                                                  />
                                                </div>
                                                <div className="lg:w-10/12 pl-2">
                                                  <span className="text-sm font-medium ">
                                                    My Trips
                                                  </span>
                                                  <p className="text-sky-700 font-normal font text-xs ">
                                                    Manage Your Bookings
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                            </Dialog>
                          </Transition>
                          <a href="/contact">
                            <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                              Contact Us
                            </button>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ========== BANNER MD SCREEN VIEW ======== */}

              <div
                className="font xl:hidden lg:hidden hidden md:block sm:hidden bg-sky-600"
                title={bannerTitle1}
                aria-label={bannerTitle1}
              // style={{
              //   // backgroundImage: `url("https://admin.trawelmart.com/admin/public/${bannerImage}")`,
              //   width: "100%",
              //   height: "475px",
              //   backgroundSize: "cover",
              // }}
              >
                <img src={isLoading ? "" : "https://admin.trawelmart.com/admin/public/" + bannerImage} alt="" style={{ width: "100%" }} />
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-2 ">
                  <div
                    className={` nc-SectionHero grid xl:grid-cols-2 xl:gap-6 lg:grid-cols-2 lg:gap-6 md:grid-cols-2 relative items-center`}
                    data-nc-id="SectionHero"
                  >
                    <div className="lg:items-center text-left xl:pt-16 ">
                      <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 md:-mt-56 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                        {isLoading ? (
                          <>
                            <Skeleton height={10} />
                            <Skeleton />
                          </>
                        ) : (
                          <></>
                        )}
                        <p className="text-lg   text-white">
                          {bannerTitle1}
                        </p>
                        <h2 className="font font-bold text-4xl md:text-3xl xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                          {bannerTitle2}
                        </h2>
                        {isLoading ? (
                          <>
                            <Skeleton count={2} />
                          </>
                        ) : (
                          <>
                            <span
                              className="text-base md:text-base lg:text-base font-normal text-white dark:text-neutral-400 md:block "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                            <span
                              className="text-base md:text-base lg:text-base font-normal text-white dark:text-neutral-400 lg:block hidden "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                          </>
                        )}
                        {/*<div className="flex pt-2">
                                <button
                                    type="button"
                                    onClick={openModal}
                                    className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                    Enquire Now
                                </button>
                                <Transition appear show={isOpen} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                                        </Transition.Child>

                                        <div className="font fixed inset-0 overflow-y-auto">
                                            <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0 scale-95"
                                                    enterTo="opacity-100 scale-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100 scale-100"
                                                    leaveTo="opacity-0 scale-95"
                                                >
                                                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                        <div className="text-right mt-2 mr-2">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                onClick={closeModal}
                                                            >
                                                                X
                                                            </button>
                                                        </div>

                                                        <Dialog.Title
                                                            as="h3"
                                                            className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                        >
                                                            Quick Enquiry
                                                            <p className="pt-2 font-normal text-xs text-sky-500">
                                                                Plan an unforgettable trip with Trawel Mart
                                                            </p>
                                                        </Dialog.Title>

                                                        <div className="mt-2">
                                                            <form>
                                                                <div className="mx-auto">
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                                        {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                                    </label>
                                                                    {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                    Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                      </p>
                                                                </div>
                                                                {/*<div className="xl:px-16">
                                                                    <button type="submit"
                                                                        className="w-full rounded-md border border-sky-600 bg-white mt-4 px-4 py-2 text-sm font-medium text-sky-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                    </div>
                                                                <p className="text-xs text-neutral-500 pt-2">
                                                                    OR
                                                                </p>
                                                                <div className="xl:px-16 px-4 mt-4">
                                                                    <button type="button"
                                                                        className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                    font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                    focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                                        Submit Enquiry
                                                                    </button>
                                                                </div>
                                                                <p className="mt-4 text-xs text-center">
                                                                    Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                                </p>
                                                                <div className="bg-[#fbf7fb] mt-6">
                                                                    <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-9/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    Super Offers
                                                                                </span>
                                                                                <p className="text-sky-700 italic font-normal text-xs ">
                                                                                    Explore Great Deals
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-10/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    My Trips
                                                                                </span>
                                                                                <p className="text-sky-700 font-normal italic text-xs ">
                                                                                    Manage Your Bookings
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Dialog.Panel>
                                                </Transition.Child>
                                            </div>
                                        </div>
                                    </Dialog>
                                </Transition>
                                <a href="/contact">
                                    <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                        Contact Us
                                    </button>
                                </a>
                            </div>*/}
                      </div>
                    </div>

                    {/* <div className="text-white pl-12 my-6 xl:mt-10 xl:-mt-0 lg:-mt-20 lg:mt-0 md:mt-12 xl:px-12 md:px-0">
                      <div className="bg-white pb-3 rounded-lg">
                        <div className="mt-2 ">
                          <form onSubmit={handleSubmit}>
                            <div className="mx-auto">
                              {isLoading ? (
                                <>
                                  <Skeleton count={2} height={12} />
                                </>
                              ) : (
                                <>
                                  <div className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] xl:-mt-8 lg:-mt-24  md:-mt-8 -mt-8 lg:pt-4 text-xl font-semibold leading-6 text-black">
                                    Quick Enquiry
                                    <p className="pt-2 font-normal text-xs text-sky-500">
                                      Plan an unforgettable trip with Trawel
                                      Mart
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="px-3">
                                <div className="grid grid-cols-2">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-6"
                                        />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-6"
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="text"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          placeholder="Full Name"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6"
                                        />
                                        {nameError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid name
                                          </p>
                                        )}
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type=""
                                          value={mobileNumber}
                                          onChange={(e) =>
                                            setMobileNumber(e.target.value)
                                          }
                                          placeholder="Phone Number"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6"
                                        />
                                        {mobileError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid mobile number
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="grid grid-cols-2">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="email"
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          placeholder="Email ID"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {emailError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid email
                                          </p>
                                        )}
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type=""
                                          value={departureCity}
                                          onChange={(e) =>
                                            setDepartureCity(e.target.value)
                                          }
                                          placeholder="Departure City"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {departureError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid departure city
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>

                                <div className="grid grid-cols-2 ">
                                  {isLoading ? (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                      <label className="block xl:px-2 px-2">
                                        <Skeleton
                                          height={40}
                                          className="mt-4"
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <label className="block xl:px-2 px-2">
                                        <DatePicker
                                          selected={
                                            travelDate
                                              ? new Date(travelDate)
                                              : null
                                          }
                                          onChange={handleDateChange}
                                          id="travelDateInput"
                                          placeholderText="Travel Date"
                                          minDate={new Date()}
                                          dateFormat={"d-M-Y"}
                                          popperPlacement="top"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {travelError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid travel date
                                          </p>
                                        )}
                                      </label>
                                      {/* <label className="block xl:px-16 px-4">
                                                          <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                          {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                      </label> 
                                      <label className="block xl:px-2 px-2">
                                        <Input
                                          type="number"
                                          value={noofPassanger}
                                          onChange={(e) =>
                                            setNoofPassanger(e.target.value)
                                          }
                                          placeholder="No of Passenger"
                                          className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                        />
                                        {passangerError && (
                                          <p
                                            style={{
                                              textAlign: "start",
                                              color: "red",
                                            }}
                                          >
                                            Please enter valid number of
                                            passenger
                                          </p>
                                        )}
                                      </label>
                                    </>
                                  )}
                                </div>
                                {isLoading ? (
                                  <>
                                    <label className="block xl:px-2 px-2">
                                      <Skeleton height={50} className="mt-4" />
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <label className="block xl:px-2 px-2">
                                      <textarea
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        placeholder="Drop us a small description"
                                        className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                                      />
                                    </label>
                                  </>
                                )}
                                {/* <div id="recaptcha-container"></div> */}
                    {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                            </label>
                              </div>

                              <div className="xl:px-6 lg:px-4 md:px-4 px-4 mt-4 mb-2">
                                {isLoading ? (
                                  <>
                                    <Skeleton height={50} className="mt-2" />
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="submit"
                                      id="submitButton"
                                      className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                      font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                      focus-visible:ring-opacity-75"
                                    >
                                      Submit Enquiry
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* ========== BANNER mbl SCREEN VIEW ======== */}

              <div
                className="font xl:hidden lg:hidden block md:hidden sm:block bg-sky-600"
              // style={{
              //   // backgroundImage: `url("https://admin.trawelmart.com/admin/public/${bannerImage}")`,
              //   width: "100%",
              //   height: "230px",
              //   backgroundSize: "cover",
              // }}
              >
                <img src={isLoading ? "" : "https://admin.trawelmart.com/admin/public/" + bannerImage} alt="" style={{ width: "100%", height: "200px" }} />
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                  <div
                    className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                    data-nc-id="SectionHero"
                  >
                    <div className="lg:items-center text-left xl:pt-16 ">
                      <div className="flex-shrink-0 lg:w-12/12 items-start space-y-1 xl:pb-14 lg:pb-14 -mt-56 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                        {isLoading ? (
                          <>
                            <Skeleton height={10} />
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            <p className="text-base   text-white">
                              {bannerTitle1}
                            </p>
                            <h2 className="font font-bold text-xl md:text-3xl xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                              {bannerTitle2}
                            </h2>
                          </>
                        )}
                        {isLoading ? (
                          <>
                            <Skeleton count={2} />
                          </>
                        ) : (
                          <>
                            <span
                              className="text-sm md:text-base lg:text-base font-normal text-white dark:text-neutral-400 md:block "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                            <span
                              className="text-base md:text-base lg:text-base font-normal text-white dark:text-neutral-400 lg:block hidden "
                              dangerouslySetInnerHTML={{
                                __html: bannerDescription,
                              }}
                            ></span>
                          </>
                        )}
                        {/*<div className="flex pt-2">
                                <button
                                    type="button"
                                    onClick={openModal}
                                    className="bg-orange-400  rounded px-4 py-2 text-white font-normal text-xs">
                                    Enquire Now
                                </button>
                                <Transition appear show={isOpen} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                                        </Transition.Child>

                                        <div className="font fixed inset-0 overflow-y-auto">
                                            <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0 scale-95"
                                                    enterTo="opacity-100 scale-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100 scale-100"
                                                    leaveTo="opacity-0 scale-95"
                                                >
                                                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                        <div className="text-right mt-2 mr-2">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                onClick={closeModal}
                                                            >
                                                                X
                                                            </button>
                                                        </div>

                                                        <Dialog.Title
                                                            as="h3"
                                                            className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                        >
                                                            Quick Enquiry
                                                            <p className="pt-2 font-normal text-xs text-sky-500">
                                                                Plan an unforgettable trip with Trawel Mart
                                                            </p>
                                                        </Dialog.Title>

                                                        <div className="mt-2">
                                                            <form>
                                                                <div className="mx-auto">
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                                        {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                        {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                    </label>
                                                                    <label className="block xl:px-16 px-4">
                                                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                                    </label>
                                                                    {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                    Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                      </p>
                                                                </div>
                                                                {/*<div className="xl:px-16">
                                                                    <button type="submit"
                                                                        className="w-full rounded-md border border-sky-600 bg-white mt-4 px-4 py-2 text-sm font-medium text-sky-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                    </div>
                                                                <p className="text-xs text-neutral-500 pt-2">
                                                                    OR
                                                                </p>
                                                                <div className="xl:px-16 px-4 mt-4">
                                                                    <button type="button"
                                                                        className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                    font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                    focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                                        Submit Enquiry
                                                                    </button>
                                                                </div>
                                                                <p className="mt-4 text-xs text-center">
                                                                    Get the most recent travel offers, new tour <br/>announcements, trip ideas and much more.
                                                                </p>
                                                                <div className="bg-[#fbf7fb] mt-6">
                                                                    <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-9/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    Super Offers
                                                                                </span>
                                                                                <p className="text-sky-700 italic font-normal text-xs ">
                                                                                    Explore Great Deals
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="lg:w-6/12 text-left flex items-center">
                                                                            <div className="lg:w-2/12">
                                                                                <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                            </div>
                                                                            <div className="lg:w-10/12 pl-2">
                                                                                <span className="text-sm font-medium ">
                                                                                    My Trips
                                                                                </span>
                                                                                <p className="text-sky-700 font-normal italic text-xs ">
                                                                                    Manage Your Bookings
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Dialog.Panel>
                                                </Transition.Child>
                                            </div>
                                        </div>
                                    </Dialog>
                                </Transition>
                                <a href="/contact">
                                    <button className="bg-sky-700  rounded px-4 py-2 text-white font-normal text-xs ml-4 ">
                                        Contact Us
                                    </button>
                                </a>
                            </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-white xl:hidden  lg:hidden md:hidden block sm:block">
                <div className=" pb-3 px-4 rounded-lg  -mt-12">
                  <div className=" ">
                    <form onSubmit={handleSubmit}>
                      <div className="mx-auto border rounded-lg">
                        {isLoading ? (
                          <>
                            <Skeleton count={2} height={12} />
                          </>
                        ) : (
                          <>
                            <div className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] lg:-mt-8 md:-mt-8  lg:pt-4 text-xl font-semibold leading-6 text-black">
                              Quick Enquiry
                              <p className="pt-2 font-normal text-xs text-sky-500">
                                Plan an unforgettable trip with Trawel Mart
                              </p>
                            </div>
                          </>
                        )}
                        <div className="xl:px-8 px-3 ">
                          <div className="grid grid-cols-1 xl:gap-2 gap-1">
                            {isLoading ? (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-6" />
                                </label>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-6" />
                                </label>
                              </>
                            ) : (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <Input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Full Name"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6 font"
                                  />
                                  {nameError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid name
                                    </p>
                                  )}
                                </label>
                                <label className="block xl:px-2 px-1">
                                  <Input
                                    type=""
                                    value={mobileNumber}
                                    onChange={(e) =>
                                      setMobileNumber(e.target.value)
                                    }
                                    placeholder="Phone Number"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font"
                                  />
                                  {mobileError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid mobile number
                                    </p>
                                  )}
                                </label>
                              </>
                            )}
                          </div>
                          <div className="grid grid-cols-1 xl:gap-2 gap-1">
                            {isLoading ? (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-4" />
                                </label>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-4" />
                                </label>
                              </>
                            ) : (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email ID"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font"
                                  />
                                  {emailError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid email
                                    </p>
                                  )}
                                </label>
                                <label className="block xl:px-2 px-1">
                                  <Input
                                    type=""
                                    value={departureCity}
                                    onChange={(e) =>
                                      setDepartureCity(e.target.value)
                                    }
                                    placeholder="Departure City"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font"
                                  />
                                  {departureError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid departure city
                                    </p>
                                  )}
                                </label>
                              </>
                            )}
                          </div>

                          <div className="grid grid-cols-1 xl:gap-2 gap-1">
                            {isLoading ? (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-4" />
                                </label>
                                <label className="block xl:px-2 px-1">
                                  <Skeleton height={40} className="mt-4" />
                                </label>
                              </>
                            ) : (
                              <>
                                <label className="block xl:px-2 px-1">
                                  <DatePicker
                                    selected={
                                      travelDate ? new Date(travelDate) : null
                                    }
                                    onChange={handleDateChange}
                                    id="travelDateInput"
                                    placeholderText="Travel Date"
                                    minDate={new Date()}
                                    dateFormat={"d-M-Y"}
                                    popperPlacement="top"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                  />
                                  {travelError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid travel date
                                    </p>
                                  )}
                                </label>
                                {/* <label className="block xl:px-16 px-4">
                                                          <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                          {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                      </label> 
                                <label className="block xl:px-2 px-1">
                                  <Input
                                    type="number"
                                    value={noofPassanger}
                                    onChange={(e) =>
                                      setNoofPassanger(e.target.value)
                                    }
                                    placeholder="No of Passenger"
                                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font"
                                  />
                                  {passangerError && (
                                    <p
                                      style={{
                                        textAlign: "start",
                                        color: "red",
                                      }}
                                    >
                                      Please enter valid number of passenger
                                    </p>
                                  )}
                                </label>
                              </>
                            )}
                          </div>
                          {isLoading ? (
                            <>
                              <Skeleton height={50} className="mt-3" />
                            </>
                          ) : (
                            <>
                              <label className="block xl:px-2 px-1">
                                <textarea
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  placeholder="Drop us a small description"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-3 font"
                                />
                              </label>
                            </>
                          )}
                          {/* <div id="recaptcha-container"></div> */}
              {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                            </label> 
                        </div>

                        <div className="xl:px-10 lg:px-4 md:px-4 px-4 mt-4 mb-4">
                          {isLoading ? (
                            <>
                              <Skeleton height={50} className="mt-2" />
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                id="submitButton"
                                className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                      font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                      focus-visible:ring-opacity-75"
                              >
                                Submit Enquiry
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}

              {isIslandPage && (
                <div className="container relative  mb-6 lg:space-y-2 xl:mb-6 xl:mt-10 lg:mb-16 md:mb-8 lg:mt-10 mt-6">
                  {/* <div>
                    <div className="font text-black font-bold text-2xl xl:pb-4 pb-4">Resort</div>
                </div> */}
                  {isLoading ? (
                    <>
                      <div className="xl:px-3 px-1">
                        <Skeleton height={300} />
                      </div>
                    </>
                  ) : (
                    <>
                      <Slider {...settingss} className="items-center">
                        {resorts.length > 4 &&
                          resorts.map((item, index) => (
                            <div key={index} className="xl:px-3 px-1">
                              <a
                                href={
                                  (item as any).slug
                                    ? "/" + (item as any).slug
                                    : `#link-to-resort-${index}`
                                }
                              >
                                <img
                                  className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl"
                                  src={
                                    "https://admin.trawelmart.com/admin/public/" +
                                    (item as any).hotel_image
                                  }
                                  alt={`Image of ${(item as any).name}`}
                                />
                              </a>
                              <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                                <div className="hidden xl:block md:hidden lg:block">
                                  <img
                                    src={
                                      "https://admin.trawelmart.com/admin/public/" +
                                      (item as any).hotel_icon
                                    }
                                    className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                    alt={`Icon of ${(item as any).name}`}
                                  />
                                </div>
                                <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0 truncate xl:ml-3 md:ml-2">
                                    {(item as any).name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}

                        {/* <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/V9CCq0B/hilton.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/YfpMtXS/hiltonlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Hilton Mauritius Resort</p>
                              </div>
                          </div>
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/M5TKPZ3/four.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/dcV03kz/fourlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Four Seasons Resort</p>
                              </div>
                          </div>                        
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/gVDmVbt/outrigger.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/qsXHpVJ/outrrigerlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Outrigger Beach Resort</p>
                              </div>
                          </div>                      
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/c1fzFXV/sand.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/KqN6QVW/sandlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 lg:w-8 lg:h-8 rounded-full xl:mt-1 xl:block md:hidden lg:block" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Sands Suits Resort</p>
                              </div>
                          </div>                      
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/HPHvK7L/lux.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/R3TxFNw/luxlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Lux Belle Mare</p>
                              </div>
                          </div>                        
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/FXc9WNW/ambre.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/80pw0kx/ambrelogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Ambre Mauritius</p>
                              </div>
                          </div>                    
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/0jJqmFT/coco.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/C2PP649/cocologo.gif" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Cocotiers Hotel</p>
                              </div>
                          </div>                     
                      </div> */}
                        {/* <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/YQVwWBC/centara.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/nsf9Q87/Centara-logo-260x340.jpg" className="xl:w-10 xl:h-10 md:w-8 md:h-8 rounded-full xl:mt-1 xl:block md:hidden lg:block" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3">Centara Ras Fushi</p>
                              </div>
                          </div>                       
                      </div>
                      <div className="xl:px-3 px-1">
                          <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/pb4Y117/kandima.jpg"  alt="" /></a>
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                              <div className="hidden xl:block md:hidden lg:block">
                                  <img src="https://i.ibb.co/r31mxks/Kandima-Maldives-Logo-420x420.png" className="xl:w-10 xl:h-10 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                              </div>
                              <div>
                                  <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 lg:ml-2">Kandima Maldives</p>
                              </div>
                          </div>                       
                      </div> */}
                      </Slider>
                    </>
                  )}

                  {/* </div> */}
                </div>
              )}

              {/* =========== IMAGE SLIDER ================ */}

              {/* <div className="container relative space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6">
                {isLoading ? (
                  <>
                    <Skeleton height={175} />
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="items-center">
                      {cardBannerImages.map((item: string, index: number) => (
                        <div className="xl:px-3 px-1" key={index}>
                          <img
                            src={
                              "https://admin.trawelmart.com/admin/public/" +
                              item
                            }
                            alt={
                              bannerAlts[index]
                                ? bannerAlts[index]
                                : "Banner Image " + (index + 1) + "of " + city
                            }
                            className="rounded-lg"
                          />
                        </div>
                      ))}
                    </Slider>
                  </>
                )}
              </div> */}




              {/* =========== Top Visit Place ================ */}
              <div className="container relative space-y-2 mb-8 lg:space-y-2 lg:mb-16 lg:mt-12">
                {isLoading ? (
                  <>
                    <Skeleton width={"25%"} />
                  </>
                ) : (
                  <>
                    <div className="fontfamily text-black font-semibold text-2xl pb-4">
                      Top Places to Visit in {city}
                    </div>
                  </>
                )}
                {isLoading ? (
                  <>
                    <Skeleton height={80} />
                  </>
                ) : (
                  <>
                    <div className="grid lg:grid-cols-4 xl:grid-cols-8 sm:grid-cols-2 xl:gap-2 lg:gap-4 sm:gap-2">
                      {topPlaces.map((item: any, index: any) => (
                        <div className="justify-center text-center center items-center border  rounded-lg  py-2 px-2 xl:my-0 my-1">
                          <div className="">
                            <img
                              src={
                                "https://admin.trawelmart.com/admin/public/" +
                                (item as any).image
                              }
                              alt={(item as any).name}
                              className="w-24 h-24 rounded-full"
                            />
                          </div>
                          <div className="pt-4">
                            <h4 className="text-base font-medium fontfamily h-12">
                              {(item as any).name}
                            </h4>


                            {(item as any).rating > 0 && (
                              <div className="justify-center flex items-center">
                                <div>
                                  {Array.from(
                                    { length: (item as any).rating },
                                    (_, index) => (
                                      <span
                                        key={index}
                                        className="las la-star checked text-xs"
                                      ></span>
                                    )
                                  )}
                                  {Array.from(
                                    { length: 5 - (item as any).rating },
                                    (_, index) => (
                                      <span
                                        key={index}
                                        className="las la-star text-xs"
                                      ></span>
                                    )
                                  )}
                                </div>
                                {/* <div className="pl-2 font-medium">
                                                      {(item as any).rating}
                                                  </div>*/}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-10 lg:px-24">
                {isLoading ? (
                  <>
                    <Skeleton height={80} />
                  </>
                ) : (
                  <>
                    <div className="lg:px-12">
                      <LazyLoadImage
                        effect="blur"
                        src={
                          "https://admin.trawelmart.com/admin/public/" +
                          middleBannerImage
                        }
                        alt={"Advertisement Banner for " + city + " tours"}
                        className="rounded-xl "
                      />
                    </div>
                  </>
                )}
              </div>

              {/* =========== Dubai Tour Packages ================ */}

              <div className="container relative space-y-2 mb-12 lg:space-y-2 lg:mb-8 lg:mt-4">
                {isLoading ? (
                  <>
                    <Skeleton width={"25%"} />
                  </>
                ) : (
                  <>
                    <div className="fontfamily text-black font-semibold text-2xl pb-4">
                      {city} Tour Packages
                    </div>
                  </>
                )}
                {isLoading ? (
                  <>
                    <Skeleton height={400} />
                  </>
                ) : (
                  <>
                    <div
                      className={`grid gap-6 md:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 `}
                    >
                      {packages.map((item: any, index: any) => (
                        <div
                          className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                          data-nc-id="StayCard"
                          key={index}
                        >
                          <div className="relative w-full">
                            <Link
                              to={"/" + (item as any).slug}
                              className={`block aspect-w-4 aspect-h-3`}
                            >
                              <NcImage
                                src={
                                  (item as any).thumbnail_image
                                    ? `https://admin.trawelmart.com/admin/public/${(item as any).thumbnail_image
                                    }`
                                    : `https://admin.trawelmart.com/admin/public/${(item as any).photos_details.split(
                                      ", "
                                    )[0]
                                    }`
                                }
                                alt={(item as any).tour_title}
                              />
                            </Link>
                          </div>
                          <div className="">
                            <div className="px-4 pt-5 space-y-2 ">
                              <div className="space-y-2 ">
                                <div className="flex items-center text-xs space-x-1 text-black" >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="#11bb67"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    style={{ color: '#fff' }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                  <span className="fontfamily xl:text-sm">
                                    {item.tour_city}{" "}
                                  </span>

                                  {(item as any).is_flight === "Yes" && (
                                    <span className="pl-4 fontfamily   text-sm space-x-2 py-1">
                                      <i className="las la-plane  text-base font-semibold" style={{ color: '#11bb67' }}></i>{" "}
                                      With Flight
                                    </span>
                                  )}
                                  {(item as any).is_cruise === "Yes" && (
                                    <span className="pl-4 fontfamily text-sm space-x-2 py-1 pl-3">
                                      <i className="las la-ship  text-base " style={{ color: '#11bb67' }}></i>{" "}
                                      With Cruise
                                    </span>
                                  )}


                                </div>
                                <div className="flex items-center space-x-2">
                                  <h2
                                    className={` lg:font-medium font-regular capitalize xl:text-lg}`}
                                  >
                                    <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">
                                      {item.tour_title}
                                    </span>
                                  </h2>
                                </div>


                              </div>
                              {(item as any).night_description && (
                                <div className="card">
                                  <p className="font text-sm fontfamily font-normal xl:h-12">
                                    {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                    <span>
                                      {showMoreStates[(item as any).id] && (
                                        <p className="font text-sm fontfamily font-normal">
                                          {(item as any).night_description.split(", ").slice(2).map((night: any, i: any) => (
                                            <span key={i}>{night}{(item as any).night_description.split(", ").length === (i + 3) ? ' ' : ', '}</span>
                                          ))}
                                        </p>
                                      )}
                                    </span>
                                    {(item as any).night_description.split(", ").length > 2 && (
                                      <span>
                                        <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-sm fontfamily font-medium">
                                          {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                        </button>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              )}

                              <div className="flex items-center">
                                <div className="xl:w-6/12 ">
                                  {/* <span className="las la-star checked text-xl"></span> <span className="xl:text-base text-orangee fontfamily">4.5</span><span className="pl-3 fontfamily xl:text-base">(1.5k reviews)</span> */}
                                  <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">
                                    {item.tour_type}{" "}
                                  </div>
                                </div>
                                <div className="xl:w-6/12">
                                  <span className="text-xl font-extrabold text-right">
                                    <div className="fontfamily text-sm text-black dark:text-neutral-400 font-normal">
                                      Starts From
                                    </div>
                                    <div className="fontfamily xl:text-3xl text-black font-bold">
                                      {(item as any).price
                                        ? `${formatPrice((item as any).price)}`
                                        : "₹"}
                                      <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                  </span>
                                </div>


                              </div>


                            </div>

                            <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                            <div className="">
                              <div className="p-4 space-y-4">
                                <div className="flex justify-between items-center">
                                  <nav
                                    className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                    data-nc-id="SocialsList"
                                  >
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="las la-hotel text-orangee xl:text-2xl"></i>
                                      </a>
                                    </div>
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="las la-concierge-bell text-orangee xl:text-2xl"></i>
                                      </a>
                                    </div>
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="las la-car-side text-orangee xl:text-2xl"></i>
                                      </a>
                                    </div>
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="las la-binoculars text-orangee xl:text-2xl"></i>
                                      </a>
                                    </div>
                                  </nav>
                                  <Link to={"/" + item.slug}>
                                    <div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">
                                      Explore More
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              {/* =========== Popular Destinations ================ */}

              <div className="container relative space-y-2 mb-12 lg:space-y-2 lg:mb-16 lg:mt-12">
                <div className="nc-SectionSliderNewCategories4">
                  <div className="flex justify-between items-center ">
                    {isLoading ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        <div className="fontfamily text-black font-semibold text-2xl pb-12">
                          Popular Activities
                        </div>
                      </>
                    )}
                  </div>
                  {isLoading ? (
                    <>
                      <Skeleton height={200} />
                    </>
                  ) : (
                    <>
                      <Slider {...settings1} className="items-center px-2">
                        {activitiesImages.map((item: string, index: number) => (
                          <div
                            className="justify-center items-center center pl-2 pr-3"
                            key={index}
                          >
                            <img
                              src={
                                "https://admin.trawelmart.com/admin/public/" +
                                item
                              }
                              alt={
                                activitiesAlts[index]
                                  ? activitiesAlts[index]
                                  : " Activity in " + city
                              }
                              className="object-cover w-full h-60 rounded-lg"
                            />
                          </div>
                        ))}
                      </Slider>
                    </>
                  )}
                </div>
              </div>

              {isIslandPage && (
                <div className="container relative mb-12 lg:mb-16 xl:mb-24 lg:mt-10">
                  <div className="lg:font-semibold font-medium font lg:text-2xl text-base xl:pt-6 lg:pt-0 pt-6 text-center">
                    {isLoading ? <Skeleton width={"20%"} /> : "Activities"}
                  </div>
                  <div className="font font-regular text-center lg:text-base text-xs text-neutral-600 pb-5 xl:pb-10">
                    {isLoading ? (
                      <Skeleton width={"50%"} />
                    ) : (
                      "The tropical pearl of the Indian Ocean, tickling your senses and teleporting you directly to paradise!"
                    )}
                  </div>
                  {isLoading ? (
                    <>
                      <Skeleton height={60} count={3} />
                    </>
                  ) : (
                    <>
                      <div className="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 grid-cols-3">
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-7 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/MgWdPrT/27.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Snorkelling"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Snorkelling
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-4 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/P6GKhHK/12.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Bicycle"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Bicycle
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b xl:border-r lg:border-r border-r-0 md:border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-7 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/S7qQvst/23.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Indoor Sports"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Indoor Sports
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-5 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/Sd8CkyK/22.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Water Sports"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Water Sports
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-0 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/zPYLWg6/26.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Yoga"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Yoga
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-1 md:mt-4 lg:mt-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/f0YNkHK/25.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Diving"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Diving
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-0 md:mt-4 lg:mt-4 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/pWXNthS/7.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Wedding"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Wedding
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-2 md:mt-4 lg:mt-4 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/jr624TB/20.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Parasailing"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Parasailing
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-b xl:border-r lg:border-r border-r-0 md:border-r">
                          <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-5 md:mt-4 lg:mt-4 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/31sQzyW/13.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Cinema Facility"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Cinema Facility
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block border-b border-r ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/71RSc6X/19.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Kids Club"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Kids Club
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block  border-b border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/9vP8ZQN/18.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Surfing"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Surfing
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block  border-b ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/WfbgDz3/6.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Butler Services"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Butler Services
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block   border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/xMVZSn4/3.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Private Dining"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Private Dining
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block  border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/qxHxch0/1.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Wi fi"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Wi fi
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block   border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/VCdBZMp/9.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Live Music"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Live Music
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block  border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/r70M8WQ/5.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Honeymooners"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Honeymooners
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block border-r  ">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/vQyv3mZ/8.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Swimming Pool"
                              />
                            </div>
                            <div>
                              <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Swimming Pool
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hidden xl:block lg:block md:block sm:block">
                          <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                              <LazyLoadImage
                                effect="blur"
                                src="https://i.ibb.co/nRh9Gvt/10.png"
                                className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1"
                                alt="Gym/Health"
                              />
                            </div>
                            <div>
                              <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">
                                Gym/Health
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* =========== Dubai Tour Packages ================ */}

              {faqList.length > 0 && (
                <div className="container relative space-y-2 mb-24 lg:space-y-2 lg:mb-24 lg:mt-12">
                  <div className="fontfamily text-black font-bold text-2xl pb-6 text-center">
                    {isLoading ? (
                      <Skeleton width={"40%"} />
                    ) : (
                      `${city} Frequently Asked Questions`
                    )}
                  </div>
                  {/* <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:block hidden">
                    {isLoading ? (
                      <>
                        <Skeleton width={"40%"} height={10} count={2} />
                      </>
                    ) : (
                      <>
                        We help you prepare for your trip and ensure an
                        effortless <br />
                        and enjoyable travel experience.
                      </>
                    )}
                  </p> */}
                  {/* <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:hidden block ">
                    {isLoading ? (
                      <>
                        <Skeleton width={"40%"} height={10} count={2} />
                      </>
                    ) : (
                      <>
                        We help you prepare for your trip and ensure an
                        effortless and enjoyable travel experience.
                      </>
                    )}
                  </p> */}
                  <div
                    className={`font nc-SectionGridFilterCard lg:px-24`}
                    data-nc-id="SectionGridFilterCard"
                  >
                    <div className="space-y-2 ">
                      {isLoading ? (
                        <>
                          <Skeleton height={60} count={faqList.length} />
                        </>
                      ) : (
                        <>
                          {faqList.map((item: any, index: any) => (
                            <div
                              className={`hover:bg-gray-100 font shadow border rounded-lg  relative lg:pt-6 xl:mt-0 mt-6 lg:pb-4 pt-4 pb-4`}
                              key={index}
                            >
                              <div className="pl-6 flex items-center">
                                <a href="##" className="absolute inset-0"></a>
                                <span
                                  className={`border rounded-full  absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-4 xl:mt-6 xl:mr-4 sm:mt-6 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${openIndex === index
                                    ? "  transform -rotate-180"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    setOpenIndex(
                                      openIndex === index ? -1 : index
                                    );
                                  }}
                                >
                                  <i className="text-xl las la-angle-down"></i>
                                </span>
                                <div className="w-10/12 flex flex-col sm:flex-row sm:items-center xl:items-center lg:items-center md:items-center space-y-6 sm:space-y-0">
                                  <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4 ">
                                    {(item as any).faq}
                                  </div>
                                </div>
                              </div>
                              {openIndex !== index ? null : (
                                <div className="border-t mt-3">
                                  <div className="my-1 md:my-2 space-y-5 p-3">
                                    <p
                                      className="text-sm text-neutral-500 pl-3"
                                      dangerouslySetInnerHTML={{
                                        __html: (item as any).faq_answer,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {pageType === "package" && (
          <DetailPagetLayout>
            <div className="nc-ListingStayDetailPage font">
              {/*  HEADER*/}{" "}
              {isLoading ? (
                <>
                  <Skeleton height={8} width={"20%"} />
                </>
              ) : (
                <>
                  <ul className="breadcrumb  lg:w-4/12">
                    <li className="lg:text-base text-xs">
                      <a href="/">Home</a>
                    </li>
                    <li className="lg:text-base text-xs">
                      <a href={placeName + "-tour-packages"}>{placeName}</a>
                    </li>
                    <li className="lg:text-base text-xs">Itinerary Details</li>
                  </ul>
                </>
              )}
              <header className="rounded-md sm:rounded-xl lg:pt-5">


                {/* =================Title section Mobile view ============= */}
                <div className=" items-center lg:pb-4 pt-2 lg:hidden block ">
                  <div className="">
                    {isLoading ? (
                      <>
                        <Skeleton width={"50%"} />
                        <Skeleton width={"30%"} />
                        <Skeleton width={"20%"} height={12} />
                      </>
                    ) : (
                      <>
                        <h2 className="font text-base sm:text-3xl lg:text-2xl lg:pb-2 lg:font-semibold font-medium">
                          {packageName}
                        </h2>
                        <div className="flex text-xs items-center space-x-2 lg:pt-0 pt-2">
                          <StartRating className="text-xs font-medium" />
                          <span>
                            <i className="las la-map-marker-alt"></i>
                            <span className="text-xs font font-medium">
                              {placeName}
                            </span>
                          </span>
                          <span>
                            <i className="las la-calendar"></i>
                            <span className="text-xs font-medium">
                              {" "}
                              {packageDays} Days
                            </span>
                          </span>
                          <span>
                            <div className="font lg:text-xs md:text-sm text-xs font-regular text-sky-600 border-sky-600 border rounded px-2">
                              {packageType}
                            </div>
                          </span>
                        </div>
                        <div className="text-xs lg:pt-2 pt-2 pb-4 text-neutral-500 dark:text-neutral-400 font-normal">
                          {guestsTraveled} Guests already travelled
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* ======== image Tour includes desktop view ======== */}
                <div className="w-full flex flex-wrap ">
                  <div className="w-full w-full lg:block hidden">
                    {isLoading ? (
                      <>
                        <Skeleton height={320} className="mr-4" />
                      </>
                    ) : (
                      <>
                        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                          <div
                            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                            onClick={handleOpenModalImageGallery}
                          >
                            <img
                              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                              src={PHOTOS[0]}
                              alt=""
                              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                            />
                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                          </div>
                          {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                            <div
                              key={index}
                              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 4 ? "hidden sm:block" : ""
                                }`}
                            >
                              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-7 sm:aspect-h-5">
                                <img
                                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                                  src={item || ""}
                                  alt=""
                                  sizes="400px"
                                />
                              </div>

                              {/* OVERLAY */}
                              <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={handleOpenModalImageGallery}
                              />
                            </div>
                          ))}

                          <button
                            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                            onClick={handleOpenModalImageGallery}
                          >
                            <Squares2X2Icon className="w-5 h-5" />
                            <span className="ml-2 text-neutral-800 text-sm font-medium">
                              Show all photos
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="xl:w-4/12 lg:w-5/12 lg:block hidden xl:pl-0 lg:pl-6">
                    <div className="!space-y-3 ">
                      {/* ============== Tour include benifits desktop view =============*/}
                      {isResort ? (
                        <>
                          <div className="rounded-lg border lg:block hidden">
                            {isLoading ? (
                              <>
                                <Skeleton height={80} />
                              </>
                            ) : (
                              <>
                                <div className="gradient px-4 xl:h-36 rounded-lg">
                                  <div className="flex">
                                    <ul className="xl:ml-12 border-r h-10 text-white lg:w-6/12">
                                      <li className="xl:-ml-4 text-sm font-normal font xl:pt-3 lg:text-sm">
                                        Meal Plan - All Inclusive
                                      </li>
                                    </ul>
                                    <ul className="text-white xl:ml-12 lg:w-6/12">
                                      <li className="xl:-ml-3 text-sm font-normal font xl:pt-3 lg:text-sm">
                                        Transfers - Speed Boat
                                      </li>
                                    </ul>
                                  </div>
                                  {/* <div className="flex pt-3 xl:-mt-8">
                                                  <ul className="xl:ml-8 text-white lg:w-6/12">
                                                    <li className="text-xs lg:text-sm xl:text-base  font">
                                                      All Inclusive
                                                    </li>
                                                  </ul>
                                                  <ul className="xl:ml-16 text-white lg:w-6/12">
                                                    <li className="text-xs lg:text-sm xl:text-base font">
                                                      Speed Boat
                                                    </li>
                                                  </ul>
                                                </div> */}
                                  <div className="p-4 border-t mt-2">
                                    <div className="flex">
                                      <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                        <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                          {resortTimings[0]
                                            ? (resortTimings[0] as any)
                                              .start_month
                                            : "SEP"}{" "}
                                          -{" "}
                                          {resortTimings[0]
                                            ? (resortTimings[0] as any)
                                              .end_month
                                            : "OCT"}
                                        </li>
                                      </ul>
                                      <ul className="text-white xl:ml-12 lg:w-6/12">
                                        <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                          {resortTimings[1]
                                            ? (resortTimings[1] as any)
                                              .start_month
                                            : "NOV"}{" "}
                                          -{" "}
                                          {resortTimings[1]
                                            ? (resortTimings[1] as any)
                                              .end_month
                                            : "DEC"}
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="flex pt-3 xl:-mt-12">
                                      <ul className="xl:ml-8 text-white lg:w-6/12">
                                        <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                          ₹{" "}
                                          {resortTimings[0]
                                            ? (resortTimings[0] as any).amount
                                            : "98,650"}
                                          /-
                                        </li>
                                      </ul>
                                      <ul className="xl:ml-16 text-white lg:w-6/12">
                                        <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                          ₹{" "}
                                          {resortTimings[1]
                                            ? (resortTimings[1] as any).amount
                                            : "11,8650"}
                                          /-
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {isLoading ? (
                            <>
                              <Skeleton height={100} />
                            </>
                          ) : (
                            <>
                              <div className="border px-4 py-4 rounded-lg shadow lg:block hidden">
                                <div className="flex flex-col lg:flex-row items-center">
                                  <div className="text-lg font-semibold lg:w-7/12">
                                    Activities
                                  </div>
                                  <div className="lg:w-3/12 text-end"></div>
                                  <div className="border-orange-500 rounded-md text-orange-600 text-center border text-xs font-semibold lg:w-2/12 py-1">
                                    {placeName}
                                  </div>
                                </div>
                                <nav
                                  className="pt-4 nc-SocialsList flex text-2xl text-neutral-6000 dark:text-neutral-300 "
                                  data-nc-id="SocialsList"
                                >
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/MgWdPrT/27.png"
                                        alt="Snoorkling"
                                      />
                                    </a>
                                  </div>

                                  <div className=" xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      {/* <i className="las la-swimmer"></i> */}
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/f0YNkHK/25.png"
                                        alt="Swimming"
                                      />
                                    </a>
                                    {/* <span className="text-xs font-normal text-black text-center">
                                                                  Diving  
                                                              </span> */}
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      {/* <i className="las la-ring"></i> */}
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/Sd8CkyK/22.png"
                                        alt="Sailing"
                                      />
                                    </a>
                                    {/* <span className="text-xs font-normal text-black text-center">
                                                                  Weddings
                                                              </span> */}
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      {/* <i className="las la-child"></i> */}
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/P6GKhHK/12.png"
                                        alt="Cycling"
                                      />
                                    </a>
                                    {/* <span className="text-xs font-normal text-black text-center">
                                                                  Kids Club
                                                              </span> */}
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      {/* <i className="las la-magic"></i> */}
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/S7qQvst/23.png"
                                        alt="billiards"
                                      />
                                    </a>
                                    {/* <span className="text-xs font-normal text-black text-center">
                                                                  Surfing
                                                              </span> */}
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      {/* <i className="las la-biking"></i> */}
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/zPYLWg6/26.png"
                                        alt="Yoga"
                                      />
                                    </a>
                                    {/* <span className="text-xs font-normal text-black text-center">
                                                                  Bicycle
                                                              </span> */}
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/pWXNthS/7.png"
                                        alt="Weddings"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/jr624TB/20.png"
                                        alt="Parachute"
                                      />
                                    </a>
                                  </div>
                                </nav>
                                <nav
                                  className="pt-4 nc-SocialsList flex text-2xl text-neutral-6000 dark:text-neutral-300 "
                                  data-nc-id="SocialsList"
                                >
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/31sQzyW/13.png"
                                        alt="Movies"
                                      />
                                    </a>
                                  </div>

                                  <div className=" xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/71RSc6X/19.png"
                                        alt="Kids Section"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/qxHxch0/1.png"
                                        alt="Wifi"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/WfbgDz3/6.png"
                                        alt="Dining"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/VCdBZMp/9.png"
                                        alt="Music"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/r70M8WQ/5.png"
                                        alt="Love"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/nRh9Gvt/10.png"
                                        alt="Gym"
                                      />
                                    </a>
                                  </div>
                                  <div className="xl:mr-3">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src="https://i.ibb.co/vQyv3mZ/8.png"
                                        alt="Swimming Pool"
                                      />
                                    </a>
                                  </div>
                                </nav>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* Resort Mobile */}
                          {isLoading ? (
                            <>
                              <Skeleton height={80} />
                            </>
                          ) : (
                            <>
                              <div className="md:flex justify-between">
                                <div className="border px-2 py-4 rounded-lg shadow xl:hidden lg:hidden md:block block mt-6 md:mt-0 md:w-6/12 md:mr-2">
                                  <div className="flex items-center justify-between">
                                    <div className="text-base font-medium lg:w-7/12">
                                      Activities
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <div className="lg:w-3/12 text-end"></div>
                                      <div className="border-orange-500 rounded text-orange-600 text-center border text-xs font-medium px-3">
                                        {placeName}
                                      </div>
                                    </div>
                                  </div>

                                  <nav
                                    className="pt-4 nc-SocialsList flex text-2xl gap-1 text-neutral-6000 dark:text-neutral-300 "
                                    data-nc-id="SocialsList"
                                  >
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/MgWdPrT/27.png"
                                          alt="Snoorkling"
                                        />
                                      </a>
                                    </div>

                                    <div className=" xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        {/* <i className="las la-swimmer"></i> */}
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/f0YNkHK/25.png"
                                          alt="Swimming"
                                        />
                                      </a>
                                      {/* <span className="text-xs font-normal text-black text-center">
                                                                  Diving  
                                                              </span> */}
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        {/* <i className="las la-ring"></i> */}
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/Sd8CkyK/22.png"
                                          alt="Sailing"
                                        />
                                      </a>
                                      {/* <span className="text-xs font-normal text-black text-center">
                                                                  Weddings
                                                              </span> */}
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        {/* <i className="las la-child"></i> */}
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/P6GKhHK/12.png"
                                          alt="Cycling"
                                        />
                                      </a>
                                      {/* <span className="text-xs font-normal text-black text-center">
                                                                  Kids Club
                                                              </span> */}
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        {/* <i className="las la-magic"></i> */}
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/S7qQvst/23.png"
                                          alt="billiards"
                                        />
                                      </a>
                                      {/* <span className="text-xs font-normal text-black text-center">
                                                                  Surfing
                                                              </span> */}
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        {/* <i className="las la-biking"></i> */}
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/zPYLWg6/26.png"
                                          alt="Yoga"
                                        />
                                      </a>
                                      {/* <span className="text-xs font-normal text-black text-center">
                                                                  Bicycle
                                                              </span> */}
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/pWXNthS/7.png"
                                          alt="Weddings"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/jr624TB/20.png"
                                          alt="Parachute"
                                        />
                                      </a>
                                    </div>
                                  </nav>
                                  <nav
                                    className="pt-4 nc-SocialsList flex text-2xl gap-1 text-neutral-6000 dark:text-neutral-300 "
                                    data-nc-id="SocialsList"
                                  >
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/31sQzyW/13.png"
                                          alt="Movies"
                                        />
                                      </a>
                                    </div>

                                    <div className=" xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/71RSc6X/19.png"
                                          alt="Kids Section"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/qxHxch0/1.png"
                                          alt="Wifi"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/WfbgDz3/6.png"
                                          alt="Dining"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/VCdBZMp/9.png"
                                          alt="Music"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/r70M8WQ/5.png"
                                          alt="Love"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/nRh9Gvt/10.png"
                                          alt="Gym"
                                        />
                                      </a>
                                    </div>
                                    <div className="xl:mr-3">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <LazyLoadImage
                                          effect="blur"
                                          src="https://i.ibb.co/vQyv3mZ/8.png"
                                          alt="Swimming Pool"
                                        />
                                      </a>
                                    </div>
                                  </nav>
                                </div>
                                <div className="rounded-lg border xl:hidden lg:hidden md:block block md:w-6/12 md:mt-0 mt-4 md:mb-0 mb-4">
                                  <div className="gradient px-4 rounded-t-lg">
                                    <div className="items-center pt-3">
                                      <div className="text-white text-sm lg:w-8/12">
                                        Meal Plan - All Inclusive
                                      </div>
                                    </div>
                                    <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                                      <div className="flex">
                                        <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                          <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                            {resortTimings[0]
                                              ? (resortTimings[0] as any)
                                                .start_month
                                              : "SEP"}{" "}
                                            -{" "}
                                            {resortTimings[0]
                                              ? (resortTimings[0] as any)
                                                .end_month
                                              : "OCT"}
                                          </li>
                                        </ul>
                                        <ul className="text-white xl:ml-12 lg:w-6/12">
                                          <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                            {resortTimings[1]
                                              ? (resortTimings[1] as any)
                                                .start_month
                                              : "NOV"}{" "}
                                            -{" "}
                                            {resortTimings[1]
                                              ? (resortTimings[1] as any)
                                                .end_month
                                              : "DEC"}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="items-center pt-3">
                                      <div className="text-white text-sm lg:w-8/12">
                                        Transfers - Speed Boat
                                      </div>
                                    </div>
                                    <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                                      <div className="flex pt-3 xl:-mt-12">
                                        <ul className="xl:ml-8 text-white lg:w-6/12">
                                          <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                            ₹{" "}
                                            {resortTimings[0]
                                              ? (resortTimings[0] as any).amount
                                              : "98,650"}
                                            /-
                                          </li>
                                        </ul>
                                        <ul className="xl:ml-16 text-white lg:w-6/12">
                                          <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                            ₹{" "}
                                            {resortTimings[1]
                                              ? (resortTimings[1] as any).amount
                                              : "11,8650"}
                                            /-
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="border px-4 py-4 rounded-lg shadow lg:block hidden">
                                <div className="flex flex-col lg:flex-row items-center">
                                  <div className="text-lg font-semibold lg:w-7/12">
                                    Tour Includes
                                  </div>
                                  <div className="lg:w-3/12 text-end"></div>
                                  <div className="border-orange-500 rounded-lg text-orange-600 text-center border text-xs font-semibold lg:w-2/12 py-1">
                                    {placeName}
                                  </div>
                                </div>

                                <nav
                                  className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-4"
                                  data-nc-id="SocialsList"
                                >
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <i className="las la-hotel"></i>
                                    </a>
                                    <span className="text-xs font-normal text-black text-center">
                                      Hotel
                                    </span>
                                  </div>
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <i className="las la-concierge-bell"></i>
                                    </a>
                                    <span className="text-xs font-normal text-black text-center">
                                      Meals
                                    </span>
                                  </div>
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <i className="las la-car-side"></i>
                                    </a>
                                    <span className="text-xs font-normal text-black text-center">
                                      Transfer
                                    </span>
                                  </div>
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#!"
                                    >
                                      <i className="las la-magic"></i>
                                    </a>
                                    <span className="text-xs font-normal text-black text-center">
                                      Attraction
                                    </span>
                                  </div>
                                </nav>
                              </div> */}
                            </>
                          )}
                          {/* {isLoading ? (
                            <>
                              <Skeleton height={80} />
                            </>
                          ) : (
                            <>
                              <div className="rounded-lg border lg:block hidden">
                                <div className="gradient px-4">
                                  <div className="flex flex-col lg:flex-row items-center pt-1">
                                    <div className="text-white text-base lg:w-8/12">
                                      For Maximum benifits
                                    </div>
                                    <div className="text-white text-sm lg:w-4/12 textright">
                                      <button>
                                        <div className="p-1 border border-white rounded-full text-xs px-4 text-center">
                                          Premium{" "}
                                          <span>
                                            <i className="las la-angle-double-right"></i>
                                          </span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="p-4 border-t mt-2">
                                    <div className="flex">
                                      <ul className="list-disc text-white lg:w-6/12">
                                        <li className="text-xs lg:text-sm">
                                          Best Offers
                                        </li>
                                      </ul>
                                      <ul className="list-disc text-white lg:w-6/12">
                                        <li className="text-xs lg:text-sm">
                                          Manage Bookings
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="flex pt-3">
                                      <ul className="list-disc text-white lg:w-6/12">
                                        <li className="text-xs lg:text-sm">
                                          Special Deals
                                        </li>
                                      </ul>
                                      <ul className="list-disc text-white lg:w-6/12">
                                        <li className="text-xs lg:text-sm">
                                          Safe and Secure
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )} */}


                          {/* ============== Tour include benifits Mobile view =============*/}
                          <div className="md:flex justify-between">
                            {isLoading ? (
                              <>
                                <Skeleton height={180} />
                              </>
                            ) : (
                              <>
                                <div className="border px-4 py-4 rounded-lg shadow xl:hidden lg:hidden md:block block mt-6 md:mt-0 md:w-6/12 md:mr-2">
                                  <div className="flex items-center justify-between">
                                    <div className="text-base font-medium lg:w-7/12">
                                      Tour Includes
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <div className="lg:w-3/12 text-end"></div>
                                      <div className="border-orange-500 rounded text-orange-600 text-center border text-xs font-medium px-3">
                                        {placeName}
                                      </div>
                                    </div>
                                  </div>

                                  <nav
                                    className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                    data-nc-id="SocialsList"
                                  >
                                    {/*<div className="">
                                  <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="" target="_blank" rel="noopener noreferrer" >
                                      <i className="las la-plane-departure"></i>
                                  </a>
                                  <span className="text-xs font-normal text-black text-center">
                                      Flight
                                  </span>
    </div>*/}
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <i className="las la-hotel"></i>
                                      </a>
                                      <span className="text-xs font-normal text-black text-center">
                                        Hotel
                                      </span>
                                    </div>
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <i className="las la-concierge-bell"></i>
                                      </a>
                                      <span className="text-xs font-normal text-black text-center">
                                        Meals
                                      </span>
                                    </div>
                                    {/*<div className="">
                                  <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="" target="_blank" rel="noopener noreferrer" >
                                      <i className="las la-passport"></i>
                                  </a>
                                  <span className="text-xs font-normal text-black text-center">
                                      Visa
                                  </span>
                              </div>*/}
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <i className="las la-car-side"></i>
                                      </a>
                                      <span className="text-xs font-normal text-black text-center">
                                        Transfer
                                      </span>
                                    </div>
                                    <div className="">
                                      <a
                                        className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                        href="#!"
                                      >
                                        <i className="las la-magic"></i>
                                      </a>
                                      <span className="text-xs font-normal text-black text-center">
                                        Attraction
                                      </span>
                                    </div>
                                  </nav>
                                </div>
                                <div className="rounded-lg border xl:hidden lg:hidden md:block block md:w-6/12 md:mt-0 mt-4 md:mb-0 mb-4">
                                  <div className="gradient px-4 rounded-t-lg">
                                    <div className="flex justify-between items-center pt-3">
                                      <div className="text-white text-sm lg:w-8/12">
                                        For Maximum benifits
                                      </div>
                                      <div className="text-white text-sm lg:w-4/12 textright">
                                        <button type="button">
                                          <div className="p-1 border border-white rounded-full text-sm px-4 text-center">
                                            Premium{" "}
                                            <span>
                                              <i className="las la-angle-double-right"></i>
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                                      <ul className="list-disc text-white ">
                                        <li className="text-sm">Best Offers</li>
                                      </ul>
                                      <ul className="list-disc text-white ">
                                        <li className="text-sm">
                                          Manage Bookings
                                        </li>
                                      </ul>
                                      <ul className="list-disc text-white ">
                                        <li className="text-sm">
                                          Special Deals
                                        </li>
                                      </ul>
                                      <ul className="list-disc text-white ">
                                        <li className="text-sm">
                                          Great Offers
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* ======== image Tour includes mobile view ======== */}
                <div className="w-full lg:hidden block">
                  {isLoading ? (
                    <>
                      <Skeleton height={300} />
                    </>
                  ) : (
                    <>
                      <div className="w-12/12">
                        <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-2 ">
                          <div
                            className="col-span-1 row-span-1 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                            onClick={handleOpenModalImageGallery}
                          >
                            <img
                              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                              src={packageImages[0]}
                              alt={packageName + " Image"}
                              sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                            />
                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                          </div>

                          {Array.isArray(packageImages) &&
                            packageImages
                              .filter((_, i) => i >= 1 && i < 2)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                    }`}
                                >
                                  <div className="aspect-w-8 aspect-h-4 sm:aspect-w-5 sm:aspect-h-4">
                                    <img
                                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-12/12 h-full"
                                      src={item || ""}
                                      alt={packageName + " Image"}
                                      sizes="400px"
                                    />
                                  </div>

                                  {/* OVERLAY */}
                                  <div
                                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                    onClick={handleOpenModalImageGallery}
                                  />
                                </div>
                              ))}

                          <button
                            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                            onClick={handleOpenModalImageGallery}
                          >
                            <Squares2X2Icon className="w-5 h-5" />
                            <span className="ml-2 text-neutral-800 text-sm font-medium">
                              Show all photos
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>


                {/* =================Title section Desktop view ============= */}

                {/* <div className="lg:w-2/12 lg:pt-3 justify-center text-left flex flex-row  items-center ">
                    {isLoading ? (
                      <>
                        <Skeleton height={60} />
                      </>
                    ) : (
                      <>
                        <div>
                          <LazyLoadImage
                            effect="blur"
                            className="block dark:hidden w-12  xl:lg:md:block hidden"
                            src={offersss}
                            alt="Offers"
                          />
                        </div>
                        <div>
                          <div className="lg:text-sm md:text-2xl text-sm lg:-mb-1 text-black uppercase dark:text-black-400 font-medium  xl:lg:md:block hidden">
                            Super Offers
                          </div>
                          <span className="lg:text-base md:text-2xl text-sm lg:pb-2 pt-1 text-black bannerfont dark:text-black-400 font-normal  xl:lg:md:block hidden">
                            Explore Great Deals & Offers
                          </span>
                        </div>
                      </>
                    )}
                  </div> */}

                {/* <div className="xl:w-2/12 lg:w-3/12 lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden">
                    {!isResort && (
                      <>
                        {isLoading ? (
                          <>
                            <Skeleton height={80} />
                          </>
                        ) : (
                          <>
                            <div className=" xl:text-xs lg:text-sm font-regular text-green-600">
                              Trawelmart Offer Price
                            </div>
                            <div className="uppercase xl:text-xs lg:text-xs font-regular text-black">
                              Starts from
                            </div>
                            <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
                              {formatPrice(totalAmount + "")}
                            </div>
                            <div className=" xl:text-xs lg:text-xs font-regular text-black">
                              per person on twin sharing
                            </div>
                            <button
                              type="button"
                              onClick={inopenModal}
                              className=" text-xs bg-white border-sky-600 border text-sky-600 px-4 py-1 rounded-md mt-1 font-medium"
                            >
                              View Pricing
                            </button>
                          </>
                        )}
                      </>
                    )}
                    <Transition appear show={inOpen} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={incloseModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>
                        <div className="font fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                <div className="text-right mt-2 mr-2">
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={incloseModal}
                                  >
                                    X
                                  </button>
                                </div>
                                <Dialog.Title
                                  as="h3"
                                  className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                                >
                                  Trawel Mart's Detailed Tour Price
                                  <p className="xl:text-xs font font-regular text-gray-500">
                                    Prices & discounts are Per Person for Indian
                                    Nationals only.
                                  </p>
                                </Dialog.Title>
                                <div className="p-4">
                                  <table key="table" className="table">
                                    <table
                                      className="rounded-2xl"
                                      style={{ width: "480px" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textAlign: "left",
                                              width: "230px",
                                            }}
                                          >
                                            Premium {placeName} Packages
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textAlign: "center",
                                            }}
                                          >
                                            Price
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              backgroundColor: "#f9f9f9",
                                              width: "230px",
                                            }}
                                          >
                                            Adult
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                              backgroundColor: "#f9f9f9",
                                            }}
                                          >
                                            {formatPrice(adultAmount + "")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              width: "210px",
                                            }}
                                          >
                                            Child With Bed
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {formatPrice(childrenAmount + "")}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              backgroundColor: "#f9f9f9",
                                              width: "230px",
                                            }}
                                          >
                                            Child Without Bed
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                              backgroundColor: "#f9f9f9",
                                            }}
                                          >
                                            {formatPrice(infantAmount + "")}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </table>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  </div> */}

              </header>





              {/* MAIN */}
              <main className=" relative z-10 mt-0 lg:mt-4 flex flex-col lg:flex-row gap-6">
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-6 lg:pb-12">
                  <div className="bg-white border p-3 rounded-lg xl:block lg:block md:hidden hidden">
                    {isLoading ? (
                      <>
                        <Skeleton width={"50%"} />
                        <Skeleton width={"30%"} />
                        <Skeleton width={"20%"} height={12} />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <h2 className="fontfamily text-2xl sm:text-3xl lg:text-2xl lg:pb-2 lg:font-bold ">
                            {packageName}
                          </h2>
                          <div className="font lg:text-xs font-regular text-white bgcolor ml-4 -mt-1 rounded py-1 px-2">
                            {packageType}
                          </div>
                        </div>
                        <div className="fontfamily flex items-center space-x-4 mb-2">
                          <p className="border border-orange-600 p-1 rounded">
                            <h3 className="flex fontfamily xl:text-sm items-center">
                              Rating : <StartRating className="" />
                            </h3>
                          </p>
                          <span>
                            <i className="las la-map-marker-alt"></i>
                            <span className="lg:text-sm fontfamily font-medium">
                              {placeName}
                            </span>
                          </span>
                          <span>
                            <i className="las la-calendar"></i>
                            <span className="lg:text-sm font-medium fontfamily">
                              {" "}
                              {packageDays} Days
                            </span>
                          </span>
                          {isFlight && (
                            <span className="fontfamily  lg:text-sm font-medium text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1">
                              <i className="las la-plane  text-base"></i> With
                              Flight
                            </span>
                          )}
                          {isCruise && (
                            <span className="font  lg:text-sm font-medium text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1">
                              <i className="las la-ship  text-base"></i> With
                              Cruise
                            </span>
                          )}
                          <span>

                          </span>
                        </div>
                        {/* <div className="text-xs lg:pt-2 text-neutral-500 dark:text-neutral-400 font-normal">
                          {guestsTraveled} Guests already travelled
                        </div> */}
                      </>
                    )}
                  </div>






                  {/* <section className="xl:mt-6">
                    <div className="">
                      <div className=" border bg-white rounded-lg">
                        <div className="rounded-t-lg p-4" style={{ background: '#f7f7f7' }}>
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Nusa Penida <span className="font-medium text-neutral-600 xl:text-base">- 1 Night Stay </span>
                          </h2>
                        </div>

                        <div className="flex px-2">
                          <div className="border-r px-4">
                            <h2 className="xl:pt-4 fontfamily xl:text-sm  text-center">
                              Sat,
                              <br></br>
                              21 Sep
                            </h2>
                          </div>
                          <div className="border-r px-4 xl:w-4/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Morning
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1 xl:pb-8 font-light">
                              Arrive in Denpasar Bali airport. Get transferred to your hotel in Nusa Penida.
                            </p>
                          </div>
                          <div className=" px-4 xl:w-7/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Noon to Evening
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1  font-light ">
                              At Leisure. <span className="fontfamily text-green-600 font-light">Add Activity +</span>
                            </p>
                          </div>
                        </div>
                      </div>


                      <div className=" border bg-white rounded-lg xl:mt-6">
                        <div className="flex justify-between rounded-t-lg p-4" style={{ background: '#f7f7f7' }}>
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Gili Trawangan <span className="font-medium text-neutral-600 xl:text-base">- 1 Night Stay </span>
                          </h2>
                          <h2 className="fontfamily font-light xl:text-sm text-green-600 hover:text-green-600 cursor-pointer hover:font-bold">
                            Gili-islands Travel Guide
                          </h2>
                        </div>
                        <div className="flex px-2">
                          <div className="border-r px-4">
                            <h2 className="xl:pt-4 fontfamily xl:text-sm  text-center">
                              Sun,
                              <br></br>
                              22 Sep
                            </h2>
                          </div>
                          <div className="border-r px-4 xl:w-4/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Morning
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1 xl:pb-8 font-light">
                              Checkout of your hotel. Take the ferry to Gili Trawangan. Check-in to your new hotel.
                            </p>
                          </div>
                          <div className=" px-4 xl:w-7/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Noon to Evening
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1  font-light ">
                              At Leisure. <span className="fontfamily text-green-600 font-light">Add Activity +</span>
                            </p>
                          </div>
                        </div>
                      </div>



                      <div className=" border bg-white rounded-lg xl:mt-6">
                        <div className="flex justify-between rounded-t-lg p-4" style={{ background: '#f7f7f7' }}>
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Seminyak <span className="font-medium text-neutral-600 xl:text-base">- 2 Night Stay </span>
                          </h2>
                          <h2 className="fontfamily font-light xl:text-sm text-green-600 hover:text-green-600 cursor-pointer hover:font-bold">
                            Seminyak Travel Guide
                          </h2>
                        </div>
                        <div className="flex px-2">
                          <div className="border-r px-4">
                            <h2 className="xl:pt-4 fontfamily xl:text-sm  text-center">
                              Mon,
                              <br></br>
                              23 Sep
                            </h2>
                          </div>
                          <div className="border-r px-4 xl:w-4/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Morning
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1 xl:pb-8 font-light">
                              Checkout of your hotel. Take the ferry to Seminyak. Check-in to your new hotel.
                            </p>
                          </div>
                          <div className=" px-4 xl:w-7/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Noon to Evening
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1  font-light ">
                              At Leisure. <span className="fontfamily text-green-600 font-light">Add Activity +</span>
                            </p>
                          </div>
                        </div>
                        <hr className="border-t"></hr>
                        <div className="flex px-2">
                          <div className="border-r px-4">
                            <h2 className="pr-1 xl:pt-4 fontfamily xl:text-sm  text-center">
                              Tue,
                              <br></br>
                              24 Sep
                            </h2>
                          </div>
                          <div className="border-r px-4 xl:w-8/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Morning to Noon
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1 xl:pb-4 font-light">
                              Balinese Traditional Costume at Puri Cendana with Photoshoot
                            </p>
                          </div>
                          <div className=" px-4 xl:w-4/12">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Evening
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1  font-light ">
                              Tanah Lot Sunset Tour
                            </p>
                          </div>
                        </div>
                      </div>



                      <div className=" border bg-white rounded-lg xl:mt-6">
                        <div className="flex justify-between rounded-t-lg p-4" style={{ background: '#f7f7f7' }}>
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Canggu <span className="font-medium text-neutral-600 xl:text-base">- 1 Night Stay </span>
                          </h2>
                          <h2 className="fontfamily font-light xl:text-sm text-green-600 hover:text-green-600 cursor-pointer hover:font-bold">
                            Canggu Travel Guide
                          </h2>
                        </div>
                        <div className="flex px-2">
                          <div className="border-r px-4">
                            <h2 className="xl:pt-4 fontfamily xl:text-sm  text-center">
                              Wed,
                              <br></br>
                              25 Sep
                            </h2>
                          </div>
                          <div className=" px-4 ">
                            <p className="xl:pt-4 fontfamily xl:text-xs uppercase font-bold  text-neutral-600">
                              Full Day
                            </p>
                            <p className="fontfamily xl:text-base xl:pt-1 xl:pb-8 font-light">
                              Checkout of your hotel and get transferred to Canggu
                            </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </section> */}



                  {/* <div className="border rounded-lg">
                    <div className="py-4 bg-[#fbf7fb] rounded-t-lg">
                      <h1 className="font-bold text-sm uppercase text-sky-600 px-4 pb-1 font1">
                        Select Booking Details
                      </h1>
                    </div>

                    <div className="xl:px-6 xl:mt-4 w-6/12">
                      <p className="font text-sm font-semibold pb-2">
                        Departure City
                      </p>
                      <Select
                        options={options}
                        isSearchable={true}
                        value={startingFrom}
                        onChange={handleAirportChange}
                        getOptionLabel={(option: { value: any; label: JSX.Element; searchLabel: string; } | null) => option?.searchLabel as string}
                      />
                    </div>
                    <div className="grid grid-cols-4 my-6 gap-2 xl:px-6">
                      <div className="">
                        <p className="font text-sm font-semibold">Check In</p>
                        <Input
                          type="date"
                          className="border border-gray-300 rounded-md mt-1"
                          value={checkInDate}
                          onChange={(e) => setCheckInDate(e.target.value)}
                          min={getCurrentDate()}
                          onKeyDown={(e) => e.preventDefault()}
                        ></Input>
                      </div>
                      <div className="">
                        <p className="font text-sm font-semibold">Check Out</p>
                        <Input
                          type="date"
                          className="border border-gray-300 rounded-md mt-1"
                          value={checkOutDate}
                          onKeyDown={(e) => e.preventDefault()}
                        ></Input>
                      </div>
                      <div className="">
                        <p className="font text-sm font-semibold">
                          Total No of Days
                        </p>
                        <Input
                          type="text"
                          className="border border-gray-300 rounded-md mt-1"
                          value={packageDays}
                        ></Input>
                      </div>
                      <div className="">
                        <p className="font text-sm font-semibold">
                          No of Rooms Required
                        </p>
                        <select
                          onChange={handleSelectChange}
                          value={selectedOption}
                          className="border py-3 border-gray-300 rounded-md mt-1"
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex my-6 gap-2 xl:px-6">
                      <div className="xl:w-7/12">
                        <table className="xl:w-full">
                          <tbody className="w-11/12">
                            {Array.from(
                              { length: selectedOption },
                              (_, rowIndex) => (
                                <>
                                  <tr key={rowIndex}>
                                    <td className="xl:w-4/12 ">
                                      <p className="font text-xs font-semibold pt-2">
                                        Adult
                                      </p>
                                      <select
                                        className="border py-2 border-gray-300 rounded-md mt-1 px-1"
                                        value={adultsValue[rowIndex]}
                                        onChange={(e) => {
                                          const updatedValue = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          setAdultsValue((prevAdultValue) => {
                                            const newAdultsValue = [
                                              ...prevAdultValue,
                                            ];
                                            newAdultsValue[rowIndex] =
                                              updatedValue;
                                            return newAdultsValue;
                                          });
                                        }}
                                      >
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                      </select>
                                    </td>
                                    <td className="xl:w-4/12 px-2">
                                      <p className="font text-xs font-semibold pt-2">
                                        Children (2-12 Years)
                                      </p>
                                      <select
                                        className="border py-2 border-gray-300 rounded-md mt-1 px-1"
                                        value={childrensValue[rowIndex]}
                                        onChange={(e) => {
                                          const updatedValue = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          setChildrensValue(
                                            (prevChildValue) => {
                                              const newChildrensValue = [
                                                ...prevChildValue,
                                              ];
                                              newChildrensValue[rowIndex] =
                                                updatedValue;
                                              return newChildrensValue;
                                            }
                                          );
                                        }}
                                      >
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                      </select>
                                    </td>
                                    <td className="xl:w-4/12">
                                      <p className="font text-xs font-semibold pt-2">
                                        Infant (0-2 Years)
                                      </p>
                                      <select
                                        className="border py-2 border-gray-300 rounded-md mt-1 px-1"
                                        value={infantsValue[rowIndex]}
                                        onChange={(e) => {
                                          const updatedValue = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          setInfantsValue((prevInfantValue) => {
                                            const newInfantsValue = [
                                              ...prevInfantValue,
                                            ];
                                            newInfantsValue[rowIndex] =
                                              updatedValue;
                                            return newInfantsValue;
                                          });
                                        }}
                                      >
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr key={rowIndex}>
                                    <td colSpan={3}>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {roomsErrors[rowIndex]}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="xl:w-5/12">
                        <div className="xl:w-full">
                          <div className="flex">
                            <div className="w-6/12">
                              {Array.from(
                                { length: children },
                                (_, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td className="xl:w-4/12 ">
                                      <p className="font text-xs font-semibold pt-2">
                                        Children Age
                                      </p>
                                      <Input
                                        type="date"
                                        className="border border-gray-200 rounded-md mt-1"
                                        value={childrenDates[rowIndex]}
                                        onChange={(e) => {
                                          setChildrenDates((prevChildDate) => {
                                            const newChildrenDateValue = [
                                              ...prevChildDate,
                                            ];
                                            newChildrenDateValue[rowIndex] =
                                              e.target.value;
                                            return newChildrenDateValue;
                                          });
                                        }}
                                      ></Input>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                        }}
                                      >
                                        {childDateErrors[rowIndex]}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </div>
                            <div className="xl:w-6/12">
                              {Array.from(
                                { length: infants },
                                (_, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td className="xl:w-4/12 ">
                                      <p className="font text-xs font-semibold pt-2">
                                        Infant Age
                                      </p>
                                      <Input
                                        type="date"
                                        className="border border-gray-200 rounded-md mt-1"
                                        value={infantDates[rowIndex]}
                                        onChange={(e) => {
                                          setInfantDates((prevChildDate) => {
                                            const newinfantDateValue = [
                                              ...prevChildDate,
                                            ];
                                            newinfantDateValue[rowIndex] =
                                              e.target.value;
                                            return newinfantDateValue;
                                          });
                                        }}
                                      ></Input>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                        }}
                                      >
                                        {infantDateErrors[rowIndex]}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className=" bg-[#fbf7fb] px-4 py-2 text-xs text-sky-600 font font-regular  rounded-b-lg ">
                      <span className="">Terms & Conditions Apply.</span>
                      <span className=" px-4">
                        5% GST is applicable on given tour price.
                      </span>
                      <span className="">
                        Mentioned tour prices are Per Person for Indian
                        Nationals only.
                      </span>
                    </p>
                  </div> */}
                  <section
                    id="itinerary"
                    className=""
                  >
                    <div className="">
                      <div className="lg:w-9/12">
                        {isLoading ? (
                          <>
                            <h2 className="lg:text-xl text-lg font lg:font-semibold font-semibold lg:pb-2 pb-3 lg:pt-0 pt-2">
                              <Skeleton />
                            </h2>
                          </>
                        ) : (
                          <>
                            {/* <h2 className="lg:text-xl text-lg font lg:font-semibold font-semibold lg:pb-2 pb-3 lg:pt-0 pt-2">
                              Itinerary (Day Wise)
                            </h2> */}
                          </>
                        )}
                      </div>

                      {/* <nav
                        className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                        data-nc-id="SocialsList"
                      >
                        {isLoading ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                              <i className="las la-hotel"></i>
                            </a>
                            <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                              <i className="las la-concierge-bell"></i>
                            </a>
                            <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                              <i className="las la-car-side"></i>
                            </a>
                          </>
                        )}
                      </nav> */}
                    </div>


                    <div className=" border bg-white rounded-lg xl:mt-6">
                      <div className="flex justify-between rounded-t-lg " style={{ background: '#f7f7f7' }}>
                        <p className="p-4 xl:w-9/12">
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Arrive Dubai - Dhow Cruise
                          </h2>
                        </p>
                        <p className="border-l flex items-center p-4 xl:w-3/12">
                          <a
                            className="ml-4 flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="las la-plane-departure text-orangee xl:text-xl"></i>
                            {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                          </a>
                          <h2 className="fontfamily font-medium xl:text-base pl-4 pr-3 text-orange-600 uppercase">
                            Day 1
                          </h2>
                        </p>
                      </div>
                      <div className=" px-4 ">
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Depart as scheduled and arrive at dazzling Dubai. A Elegant Travel representative will be awaiting your
                          safe arrival at the Dubai International Airport. You will be warmly welcomed and your
                          much-anticipated vacation begins! Check into Hotel and freshen up Get ready to cruise the waters
                          and ride on the sand! Discover the beauty of Dubai with the Dhow Cruise. Dhow cruises are usually
                          on traditional dhow carriers. They are an interesting way of enjoying a piece of Dubai's
                          ancient maritime heritage.
                        </p>
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          This 2–3 hour cruise combines sumptuous buffet dinners with
                          both Arabic and international cuisine and optional alcohol. The delicious dinner,
                          soulful music, the amazing Tanoura dance performed by a professional and the
                          magical views will amaze you. You will sail calmly on the Dubai creek that divides
                          the city into two areas Bur Dubai (old Dubai) and Deira (new Dubai) -
                          Dubai Marina with high rises and fine examples of modern architecture on both sides. Enjoy this most
                          romantic evening as the stars shine and the lights twinkle in the Dubai skyline. It is happening.
                          It is real!
                        </p>
                      </div>
                      <hr className="border-t"></hr>
                      <div className="flex px-2 ">
                        <div className="border-r px-2 xl:w-2/12">
                          <h2 className="p-3 xl:pt-5 fontfamily font-medium uppercase xl:text-sm text-green-600 text-center">
                            Dubai Tour
                          </h2>
                        </div>
                        <div className=" px-4 xl:w-7/12">
                          <nav
                            className="p-3  nc-SocialsList  items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                            data-nc-id="SocialsList"
                          >
                            <a
                              className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Facebook"
                            >
                              <i className="las la-star text-orange-500 xl:text-xl"></i>
                              {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                            </a>
                            <div className="  text-sm font-normal ">
                              Return to hotel to cherish Day 1 and rest.
                            </div>
                          </nav>
                        </div>


                        <div className="xl:w-3/12 border-l center ml-4">
                          <div className="xl:mt-3 ml-4 justify-end bgcolor text-white px-2 xl:w-8/12 py-2 text-center rounded xl:text-sm cursor-pointer"
                            onClick={togglePopup}>
                            Add Activity
                          </div>
                        </div>
                        {isPopupVisible && (
                          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                            <div className="bg-white  rounded shadow-lg  relative">
                              <div
                                className="absolute top-2 right-2 cursor-pointer text-black"
                                onClick={togglePopup}
                              >
                                <img src={closee} alt="" className="w-6" />
                              </div>
                              <h5 className="p-6 rounded-t-lg  bg-orange-100 fontfamily xl:text-lg text-center font-semibold text-black">
                                Add Activity
                              </h5>
                              {/* Popup content goes here */}
                              <section className="xl:p-6">

                                <div className="flex gap-4 xl:mt-4">
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/ni5e0s62yxjf6scae92brenq8qfj_shutterstock_1253338396.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 28,500
                                    </p>
                                  </div>
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/iywtyrp9pvonx3yphm37a64rajq0_Downpic.cc-image599637196.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 38,586
                                    </p>
                                  </div>
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/z2kl6wdrpk9h1a0bk1iwwrxlorff_colorful-rainbow-appearing-sky-nature-landscape.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 45,950
                                    </p>
                                  </div>
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/z0u0tw8c86090eby0rrb1dgyedg9_Downpic.cc-image361943253.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 48,500
                                    </p>
                                  </div>
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/r3neijp4zl5phe3tvbkh0ckfs6dg_shutterstock_151345934.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 59,000
                                    </p>
                                  </div>
                                  <div className="">
                                    <img src="https://media1.thrillophilia.com/filestore/lq5u8actij32h8jfcg17b3sd732s_shutterstock_1413786725.jpg?w=100&h=100&dpr=2"
                                      className="rounded-lg h-24 w-24"></img>
                                    <h5 className="xl:text-xs pt-2 fontfamily">
                                      Starting from
                                    </h5>
                                    <p className="xl:text-sm font-bold pt-1">
                                      ₹ 68,990
                                    </p>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}


                      </div>
                    </div>


                    <div className="flex h-16 mx-auto justify-center">
                      <img src={line} alt="" />
                    </div>

                    {/* <nav
                      className="-mt-20 p-3 justify-center z-50 nc-SocialsList  items-center flex mx-auto center space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                      data-nc-id="SocialsList"
                    >
                      <a
                        className="flex items-center justify-center  w-10 h-10 rounded-full bg-orange-600 dark:bg-neutral-800 text-2xl"
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <i className="las la-car-side text-white xl:text-xl"></i>
                        {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> 
                      </a>
                    </nav> */}

                    <div className=" border bg-white rounded-lg ">
                      <div className="flex justify-between rounded-t-lg " style={{ background: '#f7f7f7' }}>
                        <p className="p-4 xl:w-9/12">
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Dubai City Tour and Desert Safari
                          </h2>
                        </p>
                        <p className="border-l flex items-center p-4 xl:w-3/12">
                          <a
                            className="ml-4 flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="las la-plane-departure text-orangee xl:text-xl"></i>
                            {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                          </a>
                          <h2 className="fontfamily font-medium xl:text-base pl-4 pr-3 text-orange-600 uppercase">
                            Day 2
                          </h2>
                        </p>
                      </div>
                      <div className=" px-4 ">
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Today we explore the amazing city of Dubai, stop for photos at Burj Al Arab – Walk around white sand beach –
                          Jumeirah Beach, followed by a drive on the famous Sheikh Zayed Road – longest road in the Emirates is
                          the home to most of Dubai's skyscrapers. We then pass by the famous Dubai Frame – an architectural
                          landmark, Photos at the iconic Palm. Visit Jumeirah Mosque, the most visited and most photographed
                          mosque of all in Dubai.
                        </p>
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Trilling Desert Safari that ends with a Belly Dance performance and Dinner. This off-road
                          Arabian adventure takes you from the bustle of Dubai city to the golden sand dunes of the desert.
                          You will travel to the desert in a luxury 4WD (Toyota Land Cruiser), the travel time is about 45 minutes.
                        </p>
                      </div>
                      <hr className="border-t"></hr>
                      <div className="flex px-2 ">
                        <div className="border-r px-2 xl:w-2/12">
                          <h2 className="p-3 xl:pt-5 fontfamily font-medium uppercase xl:text-sm text-green-600 text-center">
                            Dubai Tour
                          </h2>
                        </div>
                        <div className=" px-4 xl:w-7/12">
                          <nav
                            className="p-3  nc-SocialsList  items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                            data-nc-id="SocialsList"
                          >
                            <a
                              className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Facebook"
                            >
                              <i className="las la-star text-orange-500 xl:text-xl"></i>
                              {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                            </a>
                            <div className="  text-sm font-normal ">
                              Return to hotel
                            </div>
                          </nav>
                        </div>

                        <div className="xl:w-3/12 border-l center ml-4">
                        <div className="xl:mt-3 ml-4 justify-end bgcolor text-white px-2 xl:w-8/12 py-2 text-center rounded xl:text-sm cursor-pointer"
                            onClick={togglePopup}>
                            Add Activity
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="flex h-16 mx-auto justify-center">
                      <img src={line} alt="" />
                    </div>

                    {/* <nav
                      className="-mt-20 p-3 justify-center z-50 nc-SocialsList  items-center flex mx-auto center space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                      data-nc-id="SocialsList"
                    >
                      <a
                        className="flex items-center justify-center  w-10 h-10 rounded-full bg-orange-600 dark:bg-neutral-800 text-2xl"
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <i className="las la-car-side text-white xl:text-xl"></i>
                        {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> 
                      </a>
                    </nav> */}

                    <div className=" border bg-white rounded-lg ">
                      <div className="flex justify-between rounded-t-lg " style={{ background: '#f7f7f7' }}>
                        <p className="p-4 xl:w-9/12">
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Burj Khalifa, Fountain Show and Underwater Zoo
                          </h2>
                        </p>
                        <p className="border-l flex items-center p-4 xl:w-3/12">
                          <a
                            className="ml-4 flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="las la-plane-departure text-orangee xl:text-xl"></i>
                            {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                          </a>
                          <h2 className="fontfamily font-medium xl:text-base pl-4 pr-3 text-orange-600 uppercase">
                            Day 3
                          </h2>
                        </p>
                      </div>
                      <div className=" px-4 ">
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Dubai’s record-breaking attraction, iconic landmark and the tallest skyscraper in the world.
                          Burj Khalifa With 124 Floors, it provides a unique sight for the eyes with breathtaking views
                          of the city “At the Top” – 124th floor.
                        </p>

                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Travel up the longest elevator shaft in the world. Face your fears as you walk on the glass
                          floor thousands of feet in the sky. You can catch a close-up view of the city through its advanced,
                          high-powered telescope.
                        </p>
                      </div>
                      <hr className="border-t"></hr>
                      <div className="flex px-2 ">
                        <div className="border-r px-2 xl:w-2/12">
                          <h2 className="p-3 xl:pt-5 fontfamily font-medium uppercase xl:text-sm text-green-600 text-center">
                            Dubai Tour
                          </h2>
                        </div>
                        <div className=" px-4 xl:w-7/12">
                          <nav
                            className="p-3  nc-SocialsList  items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                            data-nc-id="SocialsList"
                          >
                            <a
                              className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Facebook"
                            >
                              <i className="las la-star text-orange-500 xl:text-xl"></i>
                              {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                            </a>
                            <div className="  text-sm font-normal ">
                              Overnight stay at hotel.
                            </div>
                          </nav>
                        </div>

                        <div className="xl:w-3/12 border-l center ml-4">
                        <div className="xl:mt-3 ml-4 justify-end bgcolor text-white px-2 xl:w-8/12 py-2 text-center rounded xl:text-sm cursor-pointer"
                            onClick={togglePopup}>
                            Add Activity
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="flex h-16 mx-auto justify-center">
                      <img src={line} alt="" />
                    </div>

                    {/* <nav
                      className="-mt-20 p-3 justify-center z-50 nc-SocialsList  items-center flex mx-auto center space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                      data-nc-id="SocialsList"
                    >
                      <a
                        className="flex items-center justify-center  w-10 h-10 rounded-full bg-orange-600 dark:bg-neutral-800 text-2xl"
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <i className="las la-car-side text-white xl:text-xl"></i>
                        {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> 
                      </a>
                    </nav> */}

                    <div className=" border bg-white rounded-lg ">
                      <div className="flex justify-between rounded-t-lg " style={{ background: '#f7f7f7' }}>
                        <p className="p-4 xl:w-9/12">
                          <h2 className="fontfamily font-bold xl:text-xl">
                            Dubai (Abu Dhabi City Tour)
                          </h2>
                        </p>
                        <p className="border-l flex items-center p-4 xl:w-3/12">
                          <a
                            className="ml-4 flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <i className="las la-plane-departure text-orangee xl:text-xl"></i>
                            {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                          </a>
                          <h2 className="fontfamily font-medium xl:text-base pl-4 pr-3 text-orange-600 uppercase">
                            Day 4
                          </h2>
                        </p>
                      </div>
                      <div className=" px-4 ">
                        <p className="xl:pt-4 xl:pb-4 fontfamily xl:text-base ">
                          Know how the capital city of the United Arab Emirates incredibly merges its rich ancient heritage with
                          the most modern contemporary life. We’ve crafted this tour package with cautiously picked highlights of
                          Abu Dhabi in order to ensure that you don’t miss out to take in its significant sights.
                        </p>
                      </div>
                      <hr className="border-t"></hr>
                      <div className="flex px-2 ">
                        <div className="border-r px-2 xl:w-2/12">
                          <h2 className="p-3 xl:pt-5 fontfamily font-medium uppercase xl:text-sm text-green-600 text-center">
                            Dubai Tour
                          </h2>
                        </div>
                        <div className=" px-4 xl:w-7/12">
                          <nav
                            className="p-3  nc-SocialsList  items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                            data-nc-id="SocialsList"
                          >
                            <a
                              className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl"
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Facebook"
                            >
                              <i className="las la-star text-orange-500 xl:text-xl"></i>
                              {/* <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" /> */}
                            </a>
                            <div className="  text-sm font-normal ">
                              Overnight Stay at Hotel
                            </div>
                          </nav>
                        </div>

                        <div className="xl:w-3/12 border-l center">
                        <div className="xl:mt-3 ml-4 justify-end bgcolor text-white px-2 xl:w-8/12 py-2 text-center rounded xl:text-sm cursor-pointer"
                            onClick={togglePopup}>
                            Add Activity
                          </div>
                        </div>
                      </div>
                    </div>



                    <section className="xl:pt-6">
                      <h5 className="fontfamily xl:text-lg font-semibold text-black">
                        Choose Trip Duration
                      </h5>
                      <div className="flex gap-4 xl:mt-4">
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/ni5e0s62yxjf6scae92brenq8qfj_shutterstock_1253338396.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 28,500
                          </p>
                        </div>
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/iywtyrp9pvonx3yphm37a64rajq0_Downpic.cc-image599637196.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 38,586
                          </p>
                        </div>
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/z2kl6wdrpk9h1a0bk1iwwrxlorff_colorful-rainbow-appearing-sky-nature-landscape.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 45,950
                          </p>
                        </div>
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/z0u0tw8c86090eby0rrb1dgyedg9_Downpic.cc-image361943253.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 48,500
                          </p>
                        </div>
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/r3neijp4zl5phe3tvbkh0ckfs6dg_shutterstock_151345934.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 59,000
                          </p>
                        </div>
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/lq5u8actij32h8jfcg17b3sd732s_shutterstock_1413786725.jpg?w=100&h=100&dpr=2"
                            className="rounded-lg h-24 w-24"></img>
                          <h5 className="xl:text-xs pt-2 fontfamily">
                            Starting from
                          </h5>
                          <p className="xl:text-sm font-bold pt-1">
                            ₹ 68,990
                          </p>
                        </div>
                      </div>
                    </section>




                    {/* {daysDetails.map((item, index) => (
                      <div
                        className={
                          index == 0 ? " mt-3" : ""
                        }
                      >
                        {isLoading ? (
                          <>
                            <Skeleton height={50} className="mb-4" />
                          </>
                        ) : (
                          <>

                            <section className="xl:mt-6">
                              <div className="">
                                <div className=" border bg-white rounded-lg">
                                  <div className="flex justify-between rounded-t-lg p-4" style={{ background: '#f7f7f7' }}>
                                    <h2 className=" fontfamily font-bold xl:text-xl">
                                      {" "}
                                      {(item as any).day_title}
                                    </h2>
                                    <h2 className="border-l pl-12 fontfamily font-light xl:text-sm text-green-600 hover:text-green-600 cursor-pointer hover:font-bold">
                                      {(item as any).day_tag}
                                    </h2>
                                  </div>
                                  <div className=" px-4 ">
                                    <p className="fontfamily xl:text-base xl:pt-1 xl:pb-8 font-light">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: (item as any).day_description,
                                        }}
                                      ></div>

                                    </p>
                                  </div>
                                  <hr className="border-t"></hr>
                                  <div className="flex items-center">
                                    <div className="border-r px-4">
                                      <Badge className="xl:pt-6 xl:pb-6 fontfamily xl:text-sm bg-transparent text-center" name={(item as any).day}></Badge>
                                    </div>
                                    <div className="xl:w-8/12 pl-6">
                                      <nav
                                        className="  nc-SocialsList  items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                        data-nc-id="SocialsList"
                                      >
                                        <a
                                          className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title="Facebook"
                                        >
                                          {/* <i className="las la-hotel "></i> 
                                          <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" />
                                        </a>
                                        <div className="  text-sm font-normal ">
                                          {(item as any).day_highlight}
                                        </div>
                                      </nav>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            {/* <div className="items-center ">
                              <div
                                className={`nc-FlightCardgroup relative items-center overflow-hidden  transition-shadow`}
                                data-nc-id="FlightCard"
                              >
                                <div className="text-justify-center">
                                  <Badge className="text-blackk xl:text-base bg-white" name={(item as any).day}></Badge>
                                  <span className="block flex-grow border-l border-neutral-400 border-dashed  lg:ml-4 ml-3">
                                    <div className="lg:py-2 py-2 md:py-4"></div>
                                  </span>
                                </div>
                                <div className="grid lg:grid-cols-2 gap-2 ">
                                  <span className="block  lg:w-9 lg:h-9 w-8 h-8 rounded-full  bg-orangee">
                                    <LazyLoadImage
                                      effect="blur"
                                      src={require(`../../../images/custom/${(item as any).day_icon
                                        }.png`)}
                                      alt={(item as any).day_icon}
                                      className="p-2 font-bold"
                                      onError={(e) => {
                                        const target =
                                          e.target as HTMLImageElement;
                                        target.style.display = "none";
                                      }}
                                    />
                                    <span className="block flex-grow border-l border-neutral-400 border-dashed lg:ml-4 ml-3 lg:py-80 py-80"></span>
                                  </span>
                                </div>
                                <div
                                  className={`flex sm:pr-20 relative items-center xl:pl-0 lg:pl-0 md:pl-4 pl-4`}
                                  data-nc-id="FlightCard"
                                >
                                  {/*  eslint-disable-next-line jsx-a11y/anchor-has-content 
                                  {/* (sm:top-1/2)
                                  <a href="##" className="absolute inset-0" />
                                  <span
                                    className={`absolute right-0 bottom-0 sm:bottom-auto sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${openDays[index]
                                      ? "transform -rotate-180"
                                      : ""
                                      }`}
                                    onClick={() => toggleDay(index)}
                                  >
                                    <i className="text-xl las la-angle-down"></i>
                                  </span>
                                  <div className="flex xl:w-11/12 lg:w-11/12 w-9/12  -mt-8 lg:ml-12 ml-6 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                                    {/* LOGO IMG 
                                          <div className="w-24 lg:w-32 flex-shrink-0 -mt-6">
                                              <Badge name={data.day} />
                                          </div>
                                          {/* FOR MOBILE RESPONSIVE 
                                    <div className="block lg:hidden font-medium text-sm space-y-1">
                                      {(item as any).day_title}
                                    </div>
                                    <div className="hidden lg:block text-center whitespace-nowrap">
                                      <div className="font-medium text-base ">
                                        {" "}
                                        {(item as any).day_title}
                                      </div>
                                    </div>
                                    {/* PRICE 
                                    <div className="flex-[4] whitespace-nowrap sm:text-right -mr-6 hidden lg:block">
                                      <div className="">
                                        <span className="text-xs text-orange-600 border-orange-500 border px-3 py-1 rounded-full">
                                          {(item as any).day_tag}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* DETAIL
                                <div className="pb-4 pt-2 lg:pl-6 pl-3">
                                  {openDays[index] && (
                                    <div className="p-2 md:p-8 lg:border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: (item as any).day_description,
                                        }}
                                      ></div>

                                      <nav
                                        className="p-2 lg:mt-0 mt-2 lg:ml-0 ml-3 bg-bluee nc-SocialsList lg:mt-3 items-center flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                        data-nc-id="SocialsList"
                                      >
                                        <a
                                          className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title="Facebook"
                                        >
                                          {/* <i className="las la-hotel "></i> 
                                          <img src="https://i.ibb.co/smwfwVH/sightseeing.png" alt="" className="w-6" />
                                        </a>
                                        <div className="  text-sm font-normal ">
                                          {(item as any).day_highlight}
                                        </div>
                                      </nav>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div> 
                          </>
                        )}
                      </div>
                    ))} */}
                  </section>


                  <section className="xl:pt-4">
                    <div className="xl:mt-2 border bg-white rounded-lg p-4">
                      <h6 className="font-bold xl:text-lg fontfamily">
                        What's Inside the Package
                      </h6>
                      <hr className="my-4"></hr>
                      <div className="flex xl:mt-4 xl:mb-8">
                        <div className="border-r pr-3">
                          <h4 className="font-bold xl:text-base fontfamily">
                            Inclusions
                          </h4>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base  text-emerald-600 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              2 nights stay in Phuntsholing
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base text-emerald-600 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              2 nights stay in Thimphu City
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base text-emerald-600 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              2 nights stay in Paro
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base  text-emerald-600 font-bold "></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              Thimphu Sightseeing Tour with Simtokha Dzong
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base  text-emerald-600 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              Tiger Nest Hiking, Bhutan
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-check-circle text-base text-emerald-600 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              Daily Breakfast
                            </p>
                          </div>
                        </div>
                        <div className=" pl-4">
                          <h4 className="font-bold xl:text-base fontfamily">
                            Exclusions
                          </h4>
                          <div className="flex pt-3">
                            <i className=" pt-1  las la-times text-base text-red-500 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              Expenses of a personal nature.
                            </p>
                          </div>
                          <div className="flex pt-3">
                            <i className="pt-1 las la-times text-base  text-red-500 font-bold"></i>
                            <p className="pl-2 fontfamily xl:text-base">
                              Meals not mentioned in the itinerary or inclusions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>


                  <section id="includ" className="">
                    <Tab.Group>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <>
                          <section className="bg-gray-100 p-4 rounded-lg border">
                            <Tab.List className="grid grid-cols-5 gap-4 space-x-0 overflow-x-auto">
                              {["About This Tour", "Tour Preparation", "Cancellation ", "Transport", "Accomodation"].map((label, idx) => (
                                <Tab key={idx} as={Fragment}>
                                  {({ selected }) => (
                                    <button
                                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm lg:text-sm fontfamily sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                        ? "bgorange text-white rounded"
                                        : "text-neutral-800 rounded bg-white dark:text-neutral-400 dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                        } `}
                                    >
                                      {label}
                                    </button>
                                  )}
                                </Tab>
                              ))}
                            </Tab.List>
                          </section>
                        </>
                      )}
                      <Tab.Panels>
                        <Tab.Panel className="mt-6">
                          <div className="border rounded-lg bg-white">
                            <p className="fontfamily xl:text-base py-6 px-4 mb-32">
                              Explore the world of Dubai Parks and Resorts which offers every experience people would want in
                              a holiday, as it transports you into a world all for you! The moonlight… Dubai Creek… The traditionally
                              decorated wooden Dhow… A romantic evening… with a buffet dinner and traditional Arabic background music.
                              This magical mystery ride awaits, setting the tone for your holiday! A city tour of Dubai awaits you
                              the next day, to kickstart your holiday – the beautiful Clubhouse building, Jumeirah Mosque, Jumeirah
                              Open Beach, Burj Al Arab, Burj Khalifa Dubai, Palm Islands. Our packages stand apart, and this next feature
                              will prove it! Hop onto the Desert Safari for a thrilling experience of Dubai and end it with a beautiful
                              sunset is sure that will steal your hearts. A delicious barbecue dinner along with a belly dance to end
                              the day the Dubai way. Continue your vacay mode with a visit to the Palm Atlantis with a lazy river,
                              water coaster, nine-story nearly vertical slide into a shark-filled lagoon. Then head over to the Lost
                              Chambers and see 65,000 marine animals so closely and rediscover Atlantis. Miracle Garden and the Dubai
                              Frame are awaiting to mesmerize you the next day! Embrace yourself for a thrilling and racy climax
                              by visiting Abu Dhabi Yas Island, which is a popular leisure island in Abu Dhabi. The Ferrari
                              ride in Ferrari World Abu Dhabi at Yas Marina Circuit will get your adrenaline pumping in this
                              Dubai Package with Yas Island!
                            </p>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel className="mt-6">
                          <div className="border rounded-lg bg-white">
                            <p className="fontfamily xl:text-base py-6 px-4 mb-24">
                              Preparing for an international tour involves several important steps. As everyone wishes to
                              invest in themselves and explore the world, we have a few helpful tips to ensure a stress-free holiday.
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Passport and Visa:
                              </p>
                              Ensure your passport is valid for at least six months beyond your return date. Obtain the necessary
                              visas for your destination(s).
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Travel Itinerary:
                              </p>
                              Carry a copy of your itinerary, with all the bookings and reservations made in advance. Share your
                              itinerary with a trusted person and check in regularly.
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Health Preparations:
                              </p>
                              Consult your doctor for required vaccinations or medications. Carry a copy of your medical records
                              and any necessary prescriptions.
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Travel Insurance:
                              </p>
                              Purchase comprehensive travel insurance to cover unexpected events.
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Money and Finances:
                              </p>
                              Notify your bank of your travel plans to avoid issues with your cards. Carry some local
                              currency and a credit/debit card for emergencies.
                              <p className="fontfamily xl:text-base font-semibold py-2">
                                Important Documents:
                              </p>
                              Make photocopies of your passport, visa, and travel insurance.
                              Store digital copies of these documents securely.
                            </p>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel className="mt-6">
                          <div className="border rounded-lg bg-white px-6 pt-6 pb-24">
                            <table key="table" className="table">
                              <table className="mt-2" style={{ width: "710px" }}>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        textAlign: "left",
                                      }}
                                    >
                                      Number of days before departure, TRAWEL MART WORLD TOURS
                                      receives your cancellations
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        textAlign: "left",
                                      }}
                                    >
                                      Amount of cancellations charge shown as a % of a total holiday
                                      price you must pay
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        textAlign: "left",
                                      }}
                                    >
                                      If we cancel your holiday, amount you will receive from us
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      More than 45 days
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Deposit Only
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Full Advance Received
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      44 - 30 Days
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      40% of total holiday cost
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Full Amount Received
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      29 - 15 days
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      60% of total holiday cost
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Full Amount Received
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      15 days or less
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      90% of total holiday cost
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #949494",
                                        padding: "8px",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Full Amount Received plus 10% of the amount
                                    </td>
                                  </tr>
                                  {/* Add more rows as needed */}
                                </tbody>
                              </table>
                            </table>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel className="mt-6">
                          <div className="border rounded-lg bg-white">
                            <div className="fontfamily xl:text-base py-6 px-4 mb-24">
                              <p className="py-1">
                                Making the finest for you!.
                              </p>
                              <p className="py-1">
                                We are trying to reserve the airplane tickets for this trip. We will update it here, as we are done.
                              </p>
                              <p className="py-1">
                                Post Booking, you will be notified through your contact details about all tour related updates.
                              </p>
                            </div>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel className="mt-6">
                          <div className="border rounded-lg bg-white">
                            <div className="fontfamily xl:text-base py-6 px-4 mb-24">
                              <p className="py-1">
                                Processing the best for you!
                              </p>
                              <p className="py-1">
                                We are in the process of booking the accommodation for this tour. We will update it here, as we are done.
                              </p>
                              <p className="py-1">
                                Post Booking, you will be notified through your contact details about all tour related updates.
                              </p>
                            </div>
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </section>


                  <section className="bg-white pt-12 pb-6 xl:mt-12 px-6">
                    <div className="grid xl:grid-cols-3 xl:gap-4">
                      <div className="border-r">
                        <h4 className="fontfamily xl:text-base font-bold">
                          Total Reviews
                        </h4>
                        <p className="flex items-center font-bold xl:text-4xl fontfamily pt-2">
                          10.0k
                          <span className="ml-3 text-green-600 px-3 py-1 rounded-xl xl:text-xs fontfamily"
                            style={{ background: '#dcffddab' }}>21%</span>
                        </p>
                        <p className="font-light fontfamily xl:text-xs pt-1">
                          Growth in reviews on this year
                        </p>
                      </div>
                      <div className="border-r pl-6">
                        <h4 className="fontfamily xl:text-base font-bold">
                          Average Rating
                        </h4>
                        <p className="font-bold xl:text-4xl fontfamily pt-2">
                          4.0
                        </p>
                        <p className="font-light fontfamily xl:text-xs pt-1">
                          Average Rating on this year
                        </p>
                      </div>

                      <div className=" pl-6">
                        <img src="https://i.ibb.co/WWqyzZC/rating.png" alt="" />
                      </div>
                    </div>

                    <hr className="border-t mt-8"></hr>

                    <div className="flex xl:mt-8 xl:mb-8">
                      <div className="xl:w-5/12 flex gap-2">
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-20 rounded-lg"></img>
                        </div>
                        <div className="">
                          <h2 className="fontfamily font-bold xl:text-sm">
                            SONAL BHATIA
                          </h2>
                          <p className="pt-1 fontfamily font-light xl:text-sm">
                            Reviewed: 13 Dec 2023
                          </p>

                          <div className="rating mt-2">
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-7/12">
                        <p className="fontfamily xl:text-sm ">
                          We had a great trip of Bhutan 🇧🇹. Our trip was very well organized by Elegant Carve. The agent
                          especially Mr Pemba provided by them took great pain in organizing all the transportation and
                          accommodation arrangements and made our experience buttery smooth.
                          Overall booking experience was also good with timely reply by Mr Jibon Jyoti Ramchiari and Mr Shubham Sharma.
                          Looking forward to travel with Elegant Carve soon.
                        </p>
                        <div className="grid xl:grid-cols-5 xl:gap-2 xl:mt-6">
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/p5qechkz7a6o3w9fru1qqj4blxx5_BeautyPlus_20231206000847310_save.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/0aqxf1fqreqbqznjpr2938u2p69b_BeautyPlus_20231205235216684_save.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/cbjy4rhhdgzaw1wkc93kkxxj1k1b_20231206_085349.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="border-t mt-4"></hr>

                    <div className="flex xl:mt-8 xl:mb-8">
                      <div className="xl:w-5/12 flex gap-2">
                        <div className="">
                          <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-20 rounded-lg"></img>
                        </div>
                        <div className="">
                          <h2 className="fontfamily font-bold xl:text-sm">
                            SONAL BHATIA
                          </h2>
                          <p className="pt-1 fontfamily font-light xl:text-sm">
                            Reviewed: 13 Dec 2023
                          </p>
                          <div className="rating mt-2">
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                            <i className="las la-star text-yellow-400"></i>
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-7/12">
                        <p className="fontfamily xl:text-sm ">
                          We had a great trip of Bhutan 🇧🇹. Our trip was very well organized by Elegant Carve. The agent
                          especially Mr Pemba provided by them took great pain in organizing all the transportation and
                          accommodation arrangements and made our experience buttery smooth.
                          Overall booking experience was also good with timely reply by Mr Jibon Jyoti Ramchiari and Mr Shubham Sharma.
                          Looking forward to travel with Elegant Carve soon.
                        </p>
                        <div className="grid xl:grid-cols-5 xl:gap-2 xl:mt-6">
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/p5qechkz7a6o3w9fru1qqj4blxx5_BeautyPlus_20231206000847310_save.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/0aqxf1fqreqbqznjpr2938u2p69b_BeautyPlus_20231205235216684_save.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/864fn35vy09pda1zx4jbiy1bs5r4_20231206_004455.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                          <div className="">
                            <img src="https://media1.thrillophilia.com/filestore/cbjy4rhhdgzaw1wkc93kkxxj1k1b_20231206_085349.jpg?w=200&dpr=1.3" className="h-20 w-24 rounded-lg"></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>



                  {/* Included starts */}
                  {/* <section id="includ" className="listingSection__wrap">
                    <Tab.Group>
                      {isLoading ? (
                        <>
                          <Skeleton height={40} />
                        </>
                      ) : (
                        <>
                          <Tab.List className="grid grid-cols-2 gap-0 space-x-0 overflow-x-auto lg:-mt-2 bg-blue-500 rounded-lg">
                            {categories1.map((item) => (
                              <Tab key={item} as={Fragment}>
                                {({ selected }) => (
                                  <button
                                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm lg:text-lg sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                      ? "bg-blue-500 text-white "
                                      : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                      } `}
                                  >
                                    {item}
                                  </button>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                        </>
                      )}
                      <Tab.Panels>
                        <Tab.Panel className="">
                          <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                            <div className="grid grid-cols-1 xl:grid-cols-1 gap-4 text-sm text-neutral-700 dark:text-neutral-300 lg:-mt-4 -mt-4">
                              <div className="lg:space-y-4 space-y-2">
                                {isLoading ? (
                                  <>
                                    <Skeleton
                                      height={14}
                                      count={includes.length}
                                      className="mt-4"
                                    />
                                  </>
                                ) : (
                                  includes.map((item, index) => (
                                    <div
                                      className="space-x-3 flex items-center"
                                      key={index}
                                    >
                                      <div className="">
                                        <a
                                          className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#!"
                                        >
                                          <i className="las la-check-circle "></i>
                                        </a>
                                      </div>
                                      <div className="">
                                        <span className="text-sm font-normal">
                                          {(item as any).included}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel className="">
                          <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                            <div className="grid grid-cols-1 xl:grid-cols-1 gap-4 text-sm text-neutral-700 dark:text-neutral-300 lg:-mt-4 -mt-4">
                              <div className="lg:space-y-4 space-y-2">
                                {isLoading ? (
                                  <>
                                    <Skeleton
                                      height={14}
                                      count={excludes.length}
                                      className="mt-4"
                                    />
                                  </>
                                ) : (
                                  excludes.map((item, index) => (
                                    <div
                                      className="space-x-3 flex items-center"
                                      key={index}
                                    >
                                      <div className="">
                                        <a
                                          className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#!"
                                        >
                                          <i className="las la-check-circle "></i>
                                        </a>
                                      </div>

                                      <div className="">
                                        <span className="text-sm font-normal">
                                          {(item as any).excluded}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </section> */}

                  {/* Included Excluded Ends */}
                  {/* <section id="includ" className="hidden lg:block">
                    {isLoading ? (
                      <>
                        <Skeleton
                          height={55}
                          count={tourInfoDetails.length + accordionData3.length}
                          className="mt-3"
                        />
                      </>
                    ) : (
                      <>
                        {tourInfoDetails.map((item, index) => (
                          <div
                            className="accordion border border-sky-50 shadow rounded-md bg-white  my-2 "
                            key={index}
                          >
                            <div
                              className="accordion-header flex justify-between items-center p-4 bg-neutral-50"
                              onClick={() => toggleAccordion(index)}
                            >
                              <h2 className="text-black font font-medium text-base ">
                                {(item as any).title}
                              </h2>
                              <span className="text-black font font-regular text-base">
                                {openAccordions[index] ? "-" : "+"}
                              </span>
                            </div>
                            {openAccordions[index] && (
                              <div
                                className="accordion-content pt-2 p-2 overflow-x-scroll lg:overflow-x-hidden lg:p-4"
                                dangerouslySetInnerHTML={{
                                  __html: (item as any).description,
                                }}
                              ></div>
                            )}
                          </div>
                        ))}
                        {accordionData3.map((item, index) => (
                          <Accordion
                            key={index}
                            title={item.title}
                            content={item.content}
                          />
                        ))}
                      </>
                    )}
                  </section> */}
                  {/* About Your */}

                  {/* <section className="mt-4 xl:hidden lg:hidden md:hidden">
                    <span className="lg:text-lg text-xl lg:font-semibold font-semibold">
                      {isLoading ? <Skeleton /> : "Tour Information"}
                    </span>
                    <p className="lg:text-base text-sm">
                      {isLoading ? (
                        <Skeleton height={12} />
                      ) : (
                        "Read this to prepare for your tour in the best way!"
                      )}
                    </p>
                  </section> */}

                  {/* <div className="app block lg:hidden">
                    {isLoading ? (
                      <>
                        <Skeleton
                          height={55}
                          count={tourInfoDetails.length + accordionData.length}
                          className="mt-3"
                        />
                      </>
                    ) : (
                      <>
                        {tourInfoDetails.map((item, index) => (
                          <div
                            className="accordion border border-sky-50 shadow rounded-md bg-white  my-2 "
                            key={index}
                          >
                            <div
                              className="accordion-header flex justify-between items-center p-4 bg-neutral-50"
                              onClick={() => toggleAccordion(index)}
                            >
                              <h2 className="text-black font font-medium text-base ">
                                {(item as any).title}
                              </h2>
                              <span className="text-black font font-regular text-base">
                                {openAccordions[index] ? "-" : "+"}
                              </span>
                            </div>
                            {openAccordions[index] && (
                              <div
                                className="accordion-content pt-2 p-2 overflow-x-scroll lg:overflow-x-hidden lg:p-4"
                                dangerouslySetInnerHTML={{
                                  __html: (item as any).description,
                                }}
                              ></div>
                            )}
                          </div>
                        ))}
                        {accordionData.map((item, index) => (
                          <Accordion
                            key={index}
                            title={item.title}
                            content={item.content}
                          />
                        ))}
                      </>
                    )}
                  </div> */}

                  {/* Stay Information Ends */}
                  {/* Tour Details */}

                  {/* <section id="reviews" className="mt-4">
                    <span className="lg:text-xl text-xl font-semibold">
                      {isLoading ? (
                        <Skeleton width={"30%"} />
                      ) : (
                        "Upgrades Available"
                      )}
                    </span>
                    <p className="">
                      {isLoading ? (
                        <Skeleton width={"55%"} height={12} />
                      ) : (
                        "Want luxury? Add luxury at minimum cost!"
                      )}
                    </p>
                  </section> */}

                  {/* <div className="app block lg:hidden">
                    {isLoading ? (
                      <Skeleton height={40} count={accordionData2.length} />
                    ) : (
                      accordionData2.map((item, index) => (
                        <Accordion
                          key={index}
                          title={item.title}
                          content={item.content}
                        />
                      ))
                    )}
                  </div> */}

                  {/* <section className="listingSection__wrap hidden lg:block">
                    {isLoading ? (
                      <>
                        <Skeleton
                          height={40}
                          count={categories3.length}
                          className="mt-3"
                        />
                      </>
                    ) : (
                      <>
                        <Tab.Group>
                          <Tab.List className="grid grid-cols-2 gap-0 space-x-0 overflow-x-auto bg-blue-500 rounded-lg">
                            {categories3.map((item) => (
                              <Tab key={item} as={Fragment}>
                                {({ selected }) => (
                                  <button
                                    className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                      ? "bg-blue-500 text-white "
                                      : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                      } `}
                                  >
                                    {item}
                                  </button>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                          <Tab.Panels>
                            <Tab.Panel className="">
                              <div className="-mt-2 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1 text-base font-regular">
                                Need to upgrade to business or first class?
                                Please get in touch with our team on +91
                                8046427999 for more details.
                              </div>
                            </Tab.Panel>

                            <Tab.Panel className="">
                              <div className="grid grid-cols-1 gap-0 md:gap-2 lg:grid-cols-1">
                                <div className="font-regular text-normal">
                                  You can select the Hotel Upgrade in your coach
                                  at an additional cost. Please get in touch
                                  with our team on +91 8046427999 for more
                                  details.
                                </div>
                              </div>
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </>
                    )}
                  </section> */}

                  <section id="reviews"></section>
                </div>

                {/* SIDEBAR */}
                <div className="xl:w-4/12 block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
                  <div className=" bg-white rounded-lg border xl:mb-4">
                    <div className=" p-4">
                      <div className="flex justify-between">
                        <div className="">
                          <h2 className="fontfamily font-bold xl:text-2xl">
                            INR 45,950 <span className="xl:text-sm font-normal pl-1">Per Adult</span>
                          </h2>
                          <p className="fontfamily font-medium xl:text-xs uppercase ">
                            Starts From
                          </p>
                        </div>
                        <div className="text-end justify-end">
                          <h3 className="flex fontfamily xl:text-sm items-center">
                            Rating : <StartRating className="" />
                          </h3>
                          <div className="bgcolor text-white fontfamily xl:text-xs font-medium p-1 rounded mt-1">
                            Freedom Sale!
                          </div>
                        </div>
                      </div>
                      <hr className="mt-4"></hr>
                      <div className="px-4 flex justify-between gap-2">
                        <div className="xl:w-6/12 bgorange py-2 text-white text-center fontfamily xl:text-base text-center  mt-4 rounded font-semibold">
                          Send Enquiry
                        </div>
                        <div className="xl:w-6/12 bgorange py-2 text-white text-center fontfamily xl:text-base text-center mt-4 rounded font-semibold">
                          <a href="/booking">
                            Book Now
                          </a>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border xl:mb-4 xl:p-4">
                    <nav
                      className="pt-4 nc-SocialsList flex  text-2xl  justify-between"
                      data-nc-id="SocialsList"
                    >
                      <div className="justify-center center">
                        <a
                          className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 "
                          href="#!"
                        >
                          <i className="las la-car-side text-orangee xl:text-2xl"></i>
                        </a>
                        <span className="text-xs font-normal text-black text-center fontfamily">
                          Transport
                        </span>
                      </div>
                      <div className="justify-center center">
                        <a
                          className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 "
                          href="#!"
                        >
                          <i className="las la-utensils text-orangee xl:text-2xl"></i>
                        </a>
                        <span className="text-xs font-normal text-black text-center fontfamily">
                          Meals
                        </span>
                      </div>
                      <div className="justify-center center">
                        <a
                          className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 "
                          href="#!"
                        >
                          <i className="las la-binoculars text-orangee xl:text-2xl"></i>
                        </a>
                        <span className="text-xs font-normal text-black text-center fontfamily">
                          Sightseeing
                        </span>
                      </div>
                      <div className="justify-center center">
                        <a
                          className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 "
                          href="#!"
                        >
                          <i className="las la-hotel text-orangee xl:text-2xl"></i>
                        </a>
                        <span className="text-xs font-normal text-black text-center fontfamily">
                          Stay
                        </span>
                      </div>

                      <div className="justify-center center">
                        <a
                          className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 "
                          href="#!"
                        >
                          <i className="las la-plane-departure text-orangee xl:text-2xl"></i>
                        </a>
                        <span className="text-xs font-normal text-black text-center fontfamily">
                          Airports
                        </span>
                      </div>


                    </nav>
                  </div>


                  <div className="sticky top-28">
                    <div className="">
                      <div className=" ">
                        <div className="">
                          <span className="">
                            {/* {isLoading ? <Skeleton /> : "Booking Details"} */}
                            {/* <div className="text-xs font-normal text-blue-800 font">
                              {!isLoading &&
                                `${guestsTraveled} Guests already travelled`}
                            </div> */}
                          </span>
                          {/* <div className="text-right ">
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>
                                <div className="border-orange-500 rounded text-orange-600 px-6 text-center border text-xs font-semibold py-1">
                                  {placeName}
                                </div>
                              </>
                            )}
                            {!isLoading && (
                              <StartRating className="w-full pt-2 ml-6" />
                            )}
                          </div> */}
                        </div>
                      </div>

                      <div className=" "></div>
                      <div className="">
                        <div className="">
                          <div
                            className={
                              currentStep === 1 ? "step11" : "step11 hidden"
                            }
                          >
                            <h2 className="text-sm font-medium pb-2 ">
                              Add guests (Max 6 guests at a time)
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            {/* FORM */}
                            <div className="space-y-4 mt-6">
                              <div className="flex flex-col lg:flex-row items-center  ">
                                <div className="">
                                  <i className="las la-male text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                </div>
                                <div className="lg:w-full">
                                  <NcInputNumber
                                    label="Adults"
                                    defaultValue={1}
                                    onChange={handleAdultChange}
                                  />
                                  plusDisabled={maximumPeople >= 6}
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row items-center  ">
                                <div className="">
                                  <i className="las la-child text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                </div>
                                <div className="lg:w-full">
                                  <NcInputNumber
                                    label="Child"
                                    defaultValue={0}
                                    onChange={handleChildrenChange}
                                  />
                                  plusDisabled={maximumPeople >= 6}
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row items-center lg:pb-4">
                                <div className="">
                                  <i className="las la-baby text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                </div>
                                <div className="lg:w-full">
                                  <NcInputNumber
                                    label="Infant"
                                    defaultValue={0}
                                    onChange={handleInfantChange}
                                  />
                                  plusDisabled={maximumPeople >= 6}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              currentStep === 2 ? "step12" : "step12 hidden"
                            }
                          >
                            <div className="flex"></div>
                            <h2 className="text-sm font-medium pb-2 ">
                              Choose your room
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            <div className="space-y-4 mt-8">
                              {adultValue <= 2 && (
                                <>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="127000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          127000,
                                          "1 Twin Room - 2 Adults"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      1 Twin Room - 2 Adults <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Twin Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="370000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          370000,
                                          "2 Single Room - 1 Adult in each room"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      2 Single Room - 1 Adult in each room{" "}
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Single Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex ">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="126000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          126000,
                                          "1 Double Room - 2 Adults"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      1 Double Room - 2 Adults <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Double Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <br />
                                </>
                              )}
                              {adultValue == 3 && (
                                <>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="127000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          127000,
                                          "2 Twin Room - 2 Adults & 1 Adult"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      2 Twin Room - 2 Adults & 1 Adult <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Twin Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="370000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          370000,
                                          "3 Single Room - 1 Adult in each room"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      3 Single Room - 1 Adult in each room{" "}
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Single Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex ">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="126000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          126000,
                                          "2 Double Room - 2 Adults & 1 Adult"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      2 Double Room - 2 Adults & 1 Adult{" "}
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Double Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <br />
                                </>
                              )}
                              {adultValue == 4 && (
                                <>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="127000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          127000,
                                          "2 Twin Room - 2 Adults"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      2 Twin Room - 2 Adults <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Twin Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="370000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          370000,
                                          "4 Single Room - 1 Adult in each room"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      4 Single Room - 1 Adult in each room{" "}
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Single Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex ">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="126000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          126000,
                                          "2 Double Room - 2 Adults"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      2 Double Room - 2 Adults <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Double Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <br />
                                </>
                              )}
                              {adultValue == 5 && (
                                <>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="127000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          127000,
                                          "3 Twin Room - 2 Adults & 1 Adult"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      3 Twin Room - 2 Adults & 1 Adult<br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Twin Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex border-b lg:pb-4">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="370000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          370000,
                                          "5 Single Room - 1 Adult in each room"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      5 Single Room - 1 Adult in each room{" "}
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Single Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex ">
                                    <input
                                      type="radio"
                                      id="age1"
                                      name="age"
                                      value="126000"
                                      onChange={() =>
                                        updateRoomPrice(
                                          126000,
                                          "3 Double Room - 2 Adults & 1 Adult"
                                        )
                                      }
                                    />
                                    <label className="text-sm font-semibold ml-3  -mt-2">
                                      3 Double Room - 2 Adults & 1 Adult
                                      <br></br>
                                      <span className="text-neutral-400 text-xs font-normal">
                                        <i className="las la-bed mr-2"></i>1
                                        Double Bed in each room
                                      </span>
                                    </label>
                                  </div>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              currentStep === 3 ? "step13" : "step13 hidden"
                            }
                          >
                            {/* <h2 className="text-sm font-medium pb-2 flex justify-between items-center">
                            Booking Details
                            <i
                              className="las la-edit text-sky-600 text-xl font-bold cursor-pointer"
                              onClick={handleEditIconClick}
                            ></i>
                          </h2> */}
                            {/* <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div> */}
                            {/* <div className="space-y-3 mt-2 mb-4">
                              <h4 className="font-normal text-neutral-400 text-xs fontfamily">
                                Departure City
                              </h4>
                              {startingFrom !== null &&
                                (startingFrom as any).label}

                              <h4 className="font-normal text-neutral-400 text-xs pb-1 fontfamily">
                                Check In & Check Out
                              </h4>
                              <span className="text-sm font-semibold fontfamily">
                                {formatDate(checkInDate)}{" "}
                                <i className="las la-long-arrow-alt-right px-2"></i>{" "}
                                {formatDate(checkOutDate)}
                              </span>
                              <h4 className="font-normal text-neutral-400 text-xs pb-1 fontfamily">
                                No of room required
                              </h4>
                              <span className="text-sm font-semibold fontfamily">
                                {selectedOption}
                              </span>
                              <h4 className="font-normal text-neutral-400 text-xs pb-1 fontfamily">
                                Travellers
                              </h4>
                              <span className="text-sm font-semibold fontfamily">
                                {adultValue} Adult(s){" "}
                                <i className="las la-grip-lines-vertical"></i>{" "}
                                {infantValue} Infant{" "}
                                <i className="las la-grip-lines-vertical"></i>{" "}
                                {childrenValue} Child
                              </span>
                            </div> */}
                          </div>

                          <div className="pt-4">
                            {/* <div className="w-full border-t-2 border-dashed border-neutral-200 dark:border-neutral-700 pb-3"></div> */}
                            <div className="flex flex-col lg:flex-row items-center">
                              {/* <div className="lg:w-5/12">
                                <div className="text-2xl font-extrabold font1">
                                  {formatPrice(totalAmount + '')}
                                </div>
                                <div className="text-xs text-green-500  font">
                                  per person on twin sharing
                                </div>
                                <div className="text-sm  font text-red-500">
                                  Detailed Tour Price
                                </div>
                              </div> */}

                              <div
                                className="lg:w-7/12 text-right"
                                id="travellers"
                              >
                                {currentStep > 1 &&
                                  currentStep !== totalSteps && (
                                    <button
                                      id="prevButton"
                                      className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                      onClick={handlePrevButtonClick}
                                    >
                                      Previous
                                    </button>
                                  )}

                                {/* {currentStep === totalSteps ? (
                                  <button
                                    id="enquiryButton"
                                    className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                    onClick={handleEnquiryButtonClick}
                                  >
                                    Enquiry
                                  </button>
                                ) : (
                                  <button
                                    id="nextButton"
                                    className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                    onClick={handleNextButtonClick}
                                  >
                                    Next
                                  </button>
                                )} */}

                                {/* {currentStep === totalSteps && (
                                  <button
                                    id="bookOnlineButton"
                                    type="button"
                                    className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                    onClick={handleCheckout}
                                  >
                                    Book Now
                                  </button>
                                )} */}

                                {/* Enquiry Popup */}
                                {showEnquiryPopup && (
                                  <div className="fixed top-16 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center max-w-full">
                                    <div
                                      className="bg-white rounded-lg"
                                      style={{ width: "500px" }}
                                    >
                                      <div className="text-right mt-2 mr-2">
                                        <button
                                          type="button"
                                          className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                          onClick={() => {
                                            setShowEnquiryPopup(false);
                                            // Logic to handle form submission
                                          }}
                                        >
                                          X
                                        </button>
                                      </div>
                                      {/*  enquiry popup content here */}
                                      <div className="font p-4 bg-[#fbf7fb] rounded-t-lg text-center lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black">
                                        Quick Enquiry
                                        <p className="pt-2 font-normal text-xs text-sky-500">
                                          Plan an unforgettable trip with Trawel
                                          Mart
                                        </p>
                                      </div>
                                      {isLoading ? (
                                        <>
                                          <Skeleton
                                            height={50}
                                            count={7}
                                            className="mt-3"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <form onSubmit={handleSubmit}>
                                            <div className="mx-auto xl:-mt-4">
                                              <label className="block xl:px-8 px-4">
                                                <Input
                                                  type="text"
                                                  value={name}
                                                  onChange={(e) =>
                                                    setName(e.target.value)
                                                  }
                                                  placeholder="Full Name"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                                                />
                                                {nameError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid name
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <Input
                                                  type="number"
                                                  value={mobileNumber}
                                                  onChange={(e) =>
                                                    setMobileNumber(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Phone Number"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                                />
                                                {mobileError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid mobile
                                                    number
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <Input
                                                  type="email"
                                                  value={email}
                                                  onChange={(e) =>
                                                    setEmail(e.target.value)
                                                  }
                                                  placeholder="Email ID"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                                />
                                                {emailError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid email
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <Input
                                                  type=""
                                                  value={departureCity}
                                                  onChange={(e) =>
                                                    setDepartureCity(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Departure City"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                                />
                                                {departureError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid departure
                                                    city
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <DatePicker
                                                  selected={
                                                    travelDate
                                                      ? new Date(travelDate)
                                                      : null
                                                  }
                                                  onChange={handleDateChange}
                                                  id="travelDateInput"
                                                  placeholderText="Travel Date"
                                                  minDate={new Date()}
                                                  dateFormat={"d-M-Y"}
                                                  popperPlacement="top"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                                />
                                                {travelError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid travel
                                                    date
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <Input
                                                  type="number"
                                                  value={noofPassanger}
                                                  onChange={(e) =>
                                                    setNoofPassanger(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="No of Passenger"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                                />
                                                {passangerError && (
                                                  <p
                                                    style={{
                                                      textAlign: "start",
                                                      color: "red",
                                                    }}
                                                  >
                                                    Please enter valid number of
                                                    passenger
                                                  </p>
                                                )}
                                              </label>
                                              <label className="block xl:px-8 px-4">
                                                <textarea
                                                  value={description}
                                                  onChange={(e) =>
                                                    setDescription(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Drop us a small description"
                                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                                                />
                                              </label>
                                            </div>

                                            <div className="xl:px-8 px-4 mt-4 pb-6">
                                              <button
                                                type="submit"
                                                id="submitButton"
                                                className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                          font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                          focus-visible:ring-opacity-75"
                                              >
                                                Submit Enquiry
                                              </button>
                                            </div>
                                            {/* <p className="mt-4 pb-6 text-xs text-center">
                            Get the most recent travel offers, new tour <br></br>
                            announcements, trip ideas and much more.
                          </p> */}
                                            {/* <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                            <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                              <div className="lg:w-6/12 text-left flex items-center">
                                <div className="lg:w-2/12">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="block dark:hidden w-8"
                                    src={presents}
                                    alt="Presents"
                                  />
                                </div>
                                <div className="lg:w-9/12 pl-2">
                                  <span className="text-sm font-medium ">
                                    Super Offers
                                  </span>
                                  <p className="text-sky-700 italic font-normal text-xs ">
                                    Explore Great Deals
                                  </p>
                                </div>
                              </div>
                              <div className="lg:w-6/12 text-left flex items-center">
                                <div className="lg:w-2/12">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="block dark:hidden w-8"
                                    src={luggage}
                                    alt="Luggage"
                                  />
                                </div>
                                <div className="lg:w-10/12 pl-2">
                                  <span className="text-sm font-medium ">
                                    My Trips
                                  </span>
                                  <p className="text-sky-700 font-normal italic text-xs ">
                                    Manage Your Bookings
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                                          </form>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}

                                <Transition appear show={isModalOpen} as={Fragment}>
                                  <Dialog
                                    as="div"
                                    className="relative z-10"
                                    onClose={closeRegisterModal}
                                  >
                                    <Transition.Child
                                      as={Fragment}
                                      enter="ease-out duration-300"
                                      enterFrom="opacity-0"
                                      enterTo="opacity-100"
                                      leave="ease-in duration-200"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                                    </Transition.Child>

                                    <div className="font fixed inset-0 overflow-y-auto">
                                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                                        <Transition.Child
                                          as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0 scale-95"
                                          enterTo="opacity-100 scale-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100 scale-100"
                                          leaveTo="opacity-0 scale-95"
                                        >
                                          <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden text-center align-middle transition-all">
                                            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-8 sm:grid-cols-2 grid-cols-1">
                                              <div className="hidden sm:block">
                                                <div className=" my-6 rounded-md p-6 bgpopupp ">
                                                  <h1 className="font font-medium uppercase xl:text-base text-white">
                                                    Unlock this Benefits
                                                  </h1>

                                                  <div className="">
                                                    <div className="flex items-center xl:pt-6">
                                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                                        <img
                                                          className="block dark:hidden w-8"
                                                          src={coin}
                                                          alt="logo1"
                                                        />
                                                      </div>
                                                      <div className="lg:w-10/12 md:w-10/12 border-b text-left ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-semibold font">
                                                          Big Savings
                                                        </span>
                                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                                          On Your Every trips
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="">
                                                    <div className="flex items-center xl:pt-4">
                                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                                        <img
                                                          className="block dark:hidden w-8"
                                                          src={presents}
                                                          alt="logo1"
                                                        />
                                                      </div>
                                                      <div className="lg:w-10/12  md:w-10/12 border-b text-left  ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-semibold font">
                                                          Special Offers
                                                        </span>
                                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                                          Explore great deals & offers
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="">
                                                    <div className="flex items-center xl:pt-4">
                                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                                        <img
                                                          className="block dark:hidden w-8"
                                                          src={coin}
                                                          alt="logo1"
                                                        />
                                                      </div>
                                                      <div className="lg:w-10/12 md:w-10/12 border-b text-left ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-medium font ">
                                                          My Trips
                                                        </span>
                                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                                          Manage your bookings with Trawel Mart
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <p className="xl:pt-6 pt-4 text-xs font-medium xl:text-xs text-white font">
                                                    By proceeding, you agree to Elegant Travel
                                                    Privacy Policy, User Agreement and Terms of
                                                    Service
                                                  </p>
                                                </div>
                                              </div>

                                              <div className="font xl:py-6 xl:-ml-10 rounded-xl shad bg-white ">
                                                <div className="font p-4 bg-[#fbf7fb]  xl:-mt-6 lg:-mt-0 text-center rounded-t-2xl lg:pt-6 text-xl font-semibold leading-6 text-sky-600">
                                                  <div className="text-right xl:-mt-4 -mr-3 -mt-2 z-50">
                                                    <button
                                                      type="button"
                                                      className="inline-flex justify-center rounded-full border border-transparent  px-2 py-1 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                      onClick={closeRegisterModal}
                                                    >
                                                      X
                                                    </button>
                                                  </div>
                                                  <p className="xl:-mt-0 md:-mt-0 -mt-3">
                                                    Welcome to Trawel Mart
                                                  </p>
                                                  <p className="pt-1 font-normal text-center text-xs text-sky-500">
                                                    Plan an unforgettable trip with Trawel Mart
                                                  </p>
                                                </div>

                                                <p className="text-xs text-gray-500 xl:pl-4 xl:pt-10">
                                                  {mobileNumberEntry && (
                                                    <p className=" font-normal mt-4 text-sm xl:text-base">
                                                      Please enter your mobile number to{" "}
                                                      <br></br>receive a verification code
                                                    </p>
                                                  )}
                                                  {OTPEntry && (
                                                    <p className=" font-normal mt-3 5text-sm xl:text-base">
                                                      Please enter your verification code
                                                      received on {mobileNumber}
                                                    </p>
                                                  )}
                                                  {signUpEntry && (
                                                    <p className=" font-normal mt-3">
                                                      Please enter your details for registration
                                                    </p>
                                                  )}
                                                </p>
                                                <div className="">
                                                  <div className="mx-auto">
                                                    <label className="block xl:px-6 md:px-12 px-6">
                                                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                                        {mobileNumberEntry && (
                                                          <>
                                                            <img
                                                              src={india}
                                                              className="w-7 h-7 mr-3"
                                                            />
                                                            <Input
                                                              type="number"
                                                              placeholder="Phone number"
                                                              className="border-none border-l outline-none"
                                                              value={mobileNumber}
                                                              onChange={
                                                                handleMobileNumberChange
                                                              }
                                                            />
                                                            {showPopup && (
                                                              <div className="popup">
                                                                Please enter a valid phone
                                                                number (up to 10 digits).
                                                              </div>
                                                            )}
                                                          </>
                                                        )}
                                                        {OTPEntry && (
                                                          <Input
                                                            type="text"
                                                            placeholder="Verification Code"
                                                            className="border-none border-l outline-none "
                                                            value={OTP}
                                                            onChange={(e) => {
                                                              setOTP(e.target.value);
                                                            }}
                                                          />
                                                        )}
                                                        {signUpEntry && (
                                                          <>
                                                            <input
                                                              type="text"
                                                              placeholder="First Name"
                                                              className="border-none border-l outline-none "
                                                              value={firstName}
                                                              onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                              }}
                                                            />
                                                          </>
                                                        )}
                                                      </div>
                                                    </label>
                                                    {signUpEntry && (
                                                      <>
                                                        <label className="block xl:px-6">
                                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                                            <input
                                                              type="text"
                                                              placeholder="Last Name"
                                                              className="border-none border-l outline-none "
                                                              value={lastName}
                                                              onChange={(e) => {
                                                                setLastName(e.target.value);
                                                              }}
                                                            />
                                                          </div>
                                                        </label>
                                                        <label className="block xl:px-6">
                                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                                            <input
                                                              type="text"
                                                              placeholder="Email address"
                                                              className="border-none border-l outline-none "
                                                              value={email}
                                                              onChange={(e) => {
                                                                setEmail(e.target.value);
                                                              }}
                                                            />
                                                          </div>
                                                        </label>
                                                        <label className="block xl:px-6">
                                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                                            <input
                                                              type="text"
                                                              placeholder="Location"
                                                              className="border-none border-l outline-none "
                                                              value={userLocation}
                                                              onChange={(e) => {
                                                                setUserLocation(e.target.value);
                                                              }}
                                                            />
                                                          </div>
                                                        </label>
                                                      </>
                                                    )}
                                                  </div>
                                                  <div className="m-auto w-44 mb-4">
                                                    {mobileNumberEntry && (
                                                      <>
                                                        <div id="recaptcha-container"></div>
                                                        {isMobileError && (
                                                          <span
                                                            style={{
                                                              fontSize: "12px",
                                                              color: "red",
                                                              fontWeight: "bold",
                                                            }}
                                                          >
                                                            Enter valid mobile number
                                                          </span>
                                                        )}
                                                        <button
                                                          id="sendOTPBtn"
                                                          type="button"
                                                          onClick={sendOTP}
                                                          className="rounded-md bg-sky-500 mt-6 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                                        >
                                                          Request OTP
                                                        </button>
                                                      </>
                                                    )}
                                                    {OTPEntry && (
                                                      <>
                                                        <button
                                                          type="button"
                                                          onClick={validateOTP}
                                                          className="rounded-md bg-sky-500 mt-6 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                                        >
                                                          Validate OTP
                                                        </button>
                                                        <br />
                                                        <div className="px-6 pb-3">
                                                          <a href="#!" onClick={resendOTP}>
                                                            <h6>Resend OTP</h6>
                                                          </a>
                                                        </div>
                                                      </>
                                                    )}
                                                    {signUpEntry && (
                                                      <button
                                                        type="button"
                                                        onClick={registerUser}
                                                        className="rounded-md bg-sky-500 mt-6 px-12 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                                      >
                                                        Register
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="mt-2">
                                            </div>
                                          </Dialog.Panel>
                                        </Transition.Child>
                                      </div>
                                    </div>
                                  </Dialog>
                                </Transition>
                              </div>
                            </div>
                          </div>
                        </div>



                        {/* {isLoading ? 
                      <>
                        <Skeleton height={50} count={7} className="mt-3"/> 
                      </>
                      :
                      <>
                        <form onSubmit={handleSubmit}>
                          <div className="mx-auto xl:-mt-12">
                            <label className="block xl:px-8 px-4">
                              <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Full Name"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                              />
                              {nameError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid name
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <Input
                                type="number"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                placeholder="Phone Number"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                              />
                              {mobileError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid mobile number
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email ID"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                              />
                              {emailError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid email
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <Input
                                type=""
                                value={departureCity}
                                onChange={(e) => setDepartureCity(e.target.value)}
                                placeholder="Departure City"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                              />
                              {departureError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid departure city
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <DatePicker
                                selected= {travelDate ? new Date(travelDate) : null}
                                onChange={handleDateChange}
                                id="travelDateInput"
                                placeholderText="Travel Date"
                                minDate={new Date()}
                                dateFormat={'d-M-Y'}
                                popperPlacement="top"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                              />
                              {travelError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid travel date
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <Input
                                type=""
                                value={noofPassanger}
                                onChange={(e) => setNoofPassanger(e.target.value)}
                                placeholder="No of Passenger"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                              />
                              {passangerError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid number of passenger
                                </p>
                              )}
                            </label>
                            <label className="block xl:px-8 px-4">
                              <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Drop us a small description"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                              />
                            </label>
                          </div>

                          <div className="xl:px-8 px-4 mt-4">
                            <button
                              type="submit"
                              id="submitButton"
                              className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                          font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                          focus-visible:ring-opacity-75"
                            >
                              Submit Enquiry
                            </button>
                          </div>
                          <p className="mt-4 text-xs text-center">
                            Get the most recent travel offers, new tour <br></br>
                            announcements, trip ideas and much more.
                          </p>
                          <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                            <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                              <div className="lg:w-6/12 text-left flex items-center">
                                <div className="lg:w-2/12">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="block dark:hidden w-8"
                                    src={presents}
                                    alt="Presents"
                                  />
                                </div>
                                <div className="lg:w-9/12 pl-2">
                                  <span className="text-sm font-medium ">
                                    Super Offers
                                  </span>
                                  <p className="text-sky-700 italic font-normal text-xs ">
                                    Explore Great Deals
                                  </p>
                                </div>
                              </div>
                              <div className="lg:w-6/12 text-left flex items-center">
                                <div className="lg:w-2/12">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="block dark:hidden w-8"
                                    src={luggage}
                                    alt="Luggage"
                                  />
                                </div>
                                <div className="lg:w-10/12 pl-2">
                                  <span className="text-sm font-medium ">
                                    My Trips
                                  </span>
                                  <p className="text-sky-700 font-normal italic text-xs ">
                                    Manage Your Bookings
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>} */}
                      </div>
                    </div>
                    <div className="bg-white border rounded-lg  xl:mb-12">
                      <div className="bgcolor p-4">
                        <h4 className="text-white text-center fontfamily xl:text-xl">
                          Why Travel
                        </h4>
                      </div>
                      <div className="p-4">
                        <div className="flex ">
                          <i className="las la-check text-green xl:text-2xl font-bold"></i>
                          <div className="pl-3">
                            <h4 className="fontfamily xl:text-base font-semibold">
                              Verified Reviews
                            </h4>
                            <p className="pt-1 fontfamily xl:text-sm">
                              25000+ Pictures and Reviews on the platform.
                            </p>
                          </div>
                        </div>


                        <div className="flex  mt-6">
                          <i className="las la-check text-green xl:text-2xl font-bold"></i>
                          <div className="pl-3">
                            <h4 className="fontfamily xl:text-base font-semibold">
                              10000+ Tours and Activities
                            </h4>
                            <p className="pt-1 fontfamily xl:text-sm">
                              We have activities across 17 countries, across every
                              category so that you never miss best things to do anywhere.
                            </p>
                          </div>
                        </div>

                        <div className="flex  mt-6">
                          <i className="las la-check text-green xl:text-2xl font-bold"></i>
                          <div className="pl-3">
                            <h4 className="fontfamily xl:text-base font-semibold">
                              Customer Delight
                            </h4>
                            <p className="pt-1 fontfamily xl:text-sm">
                              We are always able to support you so that you
                              have a hassle free experience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </main>

            </div>
          </DetailPagetLayout>
        )}
        {pageType === "island" &&
          (isLoading ? (
            <>
              <Skeleton height={50} count={10} className="mt-3" />
            </>
          ) : (
            <DetailPagetLayout>
              <div className="nc-ListingStayDetailPage font">
                {/*  HEADER*/}{" "}
                <ul className="breadcrumb  lg:w-4/12">
                  <li className="lg:text-base text-xs">
                    <a href="/">Home</a>
                  </li>
                  <li className="lg:text-base text-xs">
                    <a href={"/" + placeName.toLowerCase() + "-tour-packages"}>
                      {placeName}
                    </a>
                  </li>
                  <li className="lg:text-base text-xs">Itinerary Details</li>
                </ul>
                <header className="rounded-md sm:rounded-xl lg:pt-5">
                  {/* =================Title section Desktop view ============= */}
                  <div className="flex flex-col lg:flex-row  text-left lg:pb-4 ">
                    <div className="lg:w-10/12 xl:block lg:block md:block block">
                      <h2 className="font text-2xl sm:text-3xl lg:text-3xl md:text-2xl lg:pb-2 md:pb-2 lg:font-semibold ">
                        {packageName}
                      </h2>
                      <div className="flex items-center space-x-4 ">
                        <StartRating1 />
                        <span className="xl:block lg:block md:block hidden">
                          <i className="las la-map-marker-alt"></i>
                          <span className="text-sm font font-medium">
                            {placeName}
                          </span>
                        </span>
                        <span>
                          <i className="las la-calendar"></i>
                          <span className="text-sm font-medium">
                            {" "}
                            {packageDays} Days
                          </span>
                        </span>
                        {isFlight && (
                          <span className="font  lg:text-sm font-medium text-sky-600 dark:text-sky-400 text-xs space-x-2 py-1">
                            <i className="las la-plane  text-base"></i> With
                            Flight
                          </span>
                        )}
                        {isCruise && (
                          <span className="font  lg:text-sm font-medium text-sky-600 dark:text-sky-400 text-xs space-x-2 py-1">
                            <i className="las la-ship  text-base"></i> With
                            Cruise
                          </span>
                        )}
                        <span>
                          <div className="font text-xs font-regular text-sky-600 border-sky-600 border rounded py-1 px-2">
                            {packageType}
                          </div>
                        </span>
                      </div>
                      <div className="text-xs lg:pt-2 pt-2 text-neutral-500 dark:text-neutral-400 font-normal">
                        {guestsTraveled} Guests already travelled
                      </div>
                    </div>

                    <div className="lg:w-2/12 lg:pt-3 pt-4 justify-center text-left flex flex-row  items-center ">
                      <div>
                        <img
                          className="block dark:hidden w-12  xl:lg:md:block hidden"
                          src={offersss}
                          alt="logo1"
                        />
                      </div>
                      <div>
                        <div className="lg:text-sm md:text-2xl text-sm lg:-mb-1 text-black uppercase dark:text-black-400 font-medium  xl:lg:md:block hidden">
                          Super Offers
                        </div>
                        <span className="lg:text-base md:text-2xl text-sm lg:pb-2 pt-1 text-black bannerfont dark:text-black-400 font-normal  xl:lg:md:block hidden">
                          Explore Great Deals & Offers
                        </span>
                      </div>
                    </div>

                    {/* <div className="xl:w-0/12 lg:w-0/12 lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden">
                    <Transition appear show={inOpen} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={incloseModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>
                        <div className="font fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                <div className="text-right mt-2 mr-2">
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={incloseModal}
                                  >
                                    X
                                  </button>
                                </div>
                                <Dialog.Title
                                  as="h3"
                                  className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                                >
                                  Trawel Mart's Detailed Tour Price
                                  <p className="xl:text-xs font font-regular text-gray-500">
                                    Prices & discounts are Per Person for Indian
                                    Nationals only.
                                  </p>
                                </Dialog.Title>
                                <div className="p-4">
                                  <table key="table" className="table">
                                    <table
                                      className="rounded-2xl"
                                      style={{ width: "460px" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textAlign: "left",
                                              width: "230px",
                                            }}
                                          >
                                            Standard Maldives Packages
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textAlign: "center",
                                            }}
                                          >
                                            Price
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              backgroundColor: "#f9f9f9",
                                              width: "230px",
                                            }}
                                          >
                                            Adult
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                              backgroundColor: "#f9f9f9",
                                            }}
                                          >
                                            ₹ 1,14,576
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              width: "210px",
                                            }}
                                          >
                                            Child With Bed
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                            }}
                                          >
                                            ₹ 48,000
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              textAlign: "left",
                                              backgroundColor: "#f9f9f9",
                                              width: "230px",
                                            }}
                                          >
                                            Child Without Bed
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #d9d7d7",
                                              padding: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              textAlign: "center",
                                              backgroundColor: "#f9f9f9",
                                            }}
                                          >
                                            ₹ 39,000
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </table>
                                </div>
                                <div className="text-left font font-medium text-sm xl:ml-4">
                                  Exclusion
                                </div>
                                <ul className="list-disc text-left xl:text-xs list-inside xl:ml-4 pb-6">
                                  <li className="list-disc list-inside py-1">
                                    Air Ticket
                                  </li>
                                  <li className="list-disc list-inside py-1">
                                    Visa
                                  </li>
                                  <li className="list-disc list-inside py-1">
                                    GST 5% , TCS 5%
                                  </li>
                                </ul>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  </div> */}
                  </div>

                  {/* =================Title section Mobile view ============= */}

                  {/* ======== image Activities desktop view ======== */}
                  <div className="w-full flex flex-wrap ">
                    <div className="xl:w-8/12 lg:w-7/12 lg:block hidden">
                      <div className="relative grid grid-cols-3 sm:grid-cols-2 gap-1 sm:gap-2 ">
                        <div
                          className="col-span-1 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                          onClick={handleOpenModalImageGallery}
                        >
                          <img
                            className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                            src={packageImages[0]}
                            alt={packageName + " Image"}
                            sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                          />
                          <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                        </div>
                        {Array.isArray(packageImages) &&
                          packageImages
                            .filter((_, i) => i >= 1 && i < 3)
                            .map((item, index) => (
                              <div
                                key={index}
                                className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                  }`}
                              >
                                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-5 sm:aspect-h-2 xl:aspect-w-5 xl:aspect-h-2 lg:aspect-w-6 lg:aspect-h-3">
                                  <img
                                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-6/12 h-full"
                                    src={item || ""}
                                    alt={packageName + " Image"}
                                    sizes="400px"
                                  />
                                </div>

                                {/* OVERLAY */}
                                <div
                                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                  onClick={handleOpenModalImageGallery}
                                />
                              </div>
                            ))}

                        <button
                          className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                          onClick={handleOpenModalImageGallery}
                        >
                          <Squares2X2Icon className="w-5 h-5" />
                          <span className="ml-2 text-neutral-800 text-sm font-medium">
                            Show all photos
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="xl:w-4/12 lg:w-5/12 lg:block hidden xl:pl-0 lg:pl-6">
                      <div className="!space-y-3 ">
                        {/* ============== Tour include benifits desktop view =============*/}

                        <div className="rounded-lg border lg:block hidden">
                          <div className="gradient px-4 xl:h-36 rounded-lg">
                            <div className="flex">
                              <ul className="xl:ml-12 border-r h-10 text-white lg:w-6/12">
                                <li className="xl:-ml-4 text-sm font-normal font xl:pt-3 lg:text-sm">
                                  Meal Plan - All Inclusive
                                </li>
                              </ul>
                              <ul className="text-white xl:ml-12 lg:w-6/12">
                                <li className="xl:-ml-3 text-sm font-normal font xl:pt-3 lg:text-sm">
                                  Transfers - Speed Boat
                                </li>
                              </ul>
                            </div>
                            <div className="p-4 border-t mt-2">
                              <div className="flex">
                                <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                  <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                    {resortTimings[0]
                                      ? getMonthName(
                                        (
                                          resortTimings[0] as any
                                        ).start_month.split("-")[1]
                                      )
                                      : "SEP"}{" "}
                                    -{" "}
                                    {resortTimings[0]
                                      ? getMonthName(
                                        (
                                          resortTimings[0] as any
                                        ).end_month.split("-")[1]
                                      ) +
                                      " " +
                                      (
                                        resortTimings[0] as any
                                      ).end_month.split("-")[0]
                                      : "OCT"}
                                  </li>
                                </ul>
                                <ul className="text-white xl:ml-12 lg:w-6/12">
                                  <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                    {resortTimings[1]
                                      ? getMonthName(
                                        (
                                          resortTimings[1] as any
                                        ).start_month.split("-")[1]
                                      )
                                      : "NOV"}{" "}
                                    -{" "}
                                    {resortTimings[1]
                                      ? getMonthName(
                                        (
                                          resortTimings[1] as any
                                        ).end_month.split("-")[1]
                                      ) +
                                      " " +
                                      (
                                        resortTimings[0] as any
                                      ).end_month.split("-")[0]
                                      : "DEC"}
                                  </li>
                                </ul>
                              </div>
                              <div className="flex pt-3 xl:-mt-12">
                                <ul className="xl:ml-8 text-white lg:w-6/12">
                                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                    {" "}
                                    {resortTimings[0]
                                      ? formatPrice(
                                        (resortTimings[0] as any).amount
                                      )
                                      : "98,650"}
                                    /-
                                  </li>
                                </ul>
                                <ul className="xl:ml-16 text-white lg:w-6/12">
                                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                    {" "}
                                    {resortTimings[1]
                                      ? formatPrice(
                                        (resortTimings[1] as any).amount
                                      )
                                      : "11,8650"}
                                    /-
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {resortTimings[2] && (
                            <div className="gradient px-4 mt-3 xl:h-36 rounded-lg">
                              <div className="p-4 border-t mt-2">
                                <div className="flex">
                                  <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                    <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                      {resortTimings[2]
                                        ? getMonthName(
                                          (
                                            resortTimings[2] as any
                                          ).start_month.split("-")[1]
                                        )
                                        : "SEP"}{" "}
                                      -{" "}
                                      {resortTimings[2]
                                        ? getMonthName(
                                          (
                                            resortTimings[2] as any
                                          ).end_month.split("-")[1]
                                        ) +
                                        " " +
                                        (
                                          resortTimings[0] as any
                                        ).end_month.split("-")[0]
                                        : "OCT"}
                                    </li>
                                  </ul>
                                  <ul className="text-white xl:ml-12 lg:w-6/12">
                                    <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                      {resortTimings[3]
                                        ? getMonthName(
                                          (
                                            resortTimings[3] as any
                                          ).start_month.split("-")[1]
                                        )
                                        : "NOV"}{" "}
                                      -{" "}
                                      {resortTimings[3]
                                        ? getMonthName(
                                          (
                                            resortTimings[3] as any
                                          ).end_month.split("-")[1]
                                        ) +
                                        " " +
                                        (
                                          resortTimings[0] as any
                                        ).end_month.split("-")[0]
                                        : "DEC"}
                                    </li>
                                  </ul>
                                </div>
                                <div className="flex pt-3 xl:-mt-12">
                                  <ul className="xl:ml-8 text-white lg:w-6/12">
                                    <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                      {" "}
                                      {resortTimings[2]
                                        ? formatPrice(
                                          (resortTimings[2] as any).amount
                                        )
                                        : "98,650"}
                                      /-
                                    </li>
                                  </ul>
                                  <ul className="xl:ml-16 text-white lg:w-6/12">
                                    <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                      {" "}
                                      {resortTimings[3]
                                        ? formatPrice(
                                          (resortTimings[3] as any).amount
                                        )
                                        : "11,8650"}
                                      /-
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* ============== Tour include benifits Mobile view =============*/}
                        <div className="md:flex justify-between">
                          <div className="border px-4 py-4 rounded-lg shadow xl:hidden lg:hidden md:hidden hidden mt-6 md:mt-0 md:w-6/12 md:mr-2">
                            <div className="flex items-center justify-between">
                              <div className="text-base font-medium lg:w-7/12">
                                Activities
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="lg:w-3/12 text-end"></div>
                                <div className="border-orange-500 rounded-md text-orange-600 text-center border text-xs font-medium px-3">
                                  {placeName}
                                </div>
                              </div>
                            </div>

                            <nav
                              className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                              data-nc-id="SocialsList"
                            >
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-hotel"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Parasailing
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-concierge-bell"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Diving
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-car-side"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Kids Club
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-magic"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Surfing
                                </span>
                              </div>
                            </nav>
                          </div>
                          <div className="rounded-lg border xl:hidden lg:hidden md:hidden hidden md:w-6/12 md:mt-0 mt-4 md:mb-0 mb-4">
                            <div className="gradient px-4 rounded-t-lg">
                              <div className="flex justify-between items-center pt-3">
                                <div className="text-white text-sm lg:w-8/12">
                                  Tour Cost Person
                                </div>
                                <div className="text-white text-sm lg:w-4/12 textright">
                                  <button type="button">
                                    <div className="p-1 border border-white rounded-full text-sm px-4 text-center">
                                      Standard{" "}
                                      <span>
                                        <i className="las la-angle-double-right"></i>
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Best Offers</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Manage Bookings</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Special Deals</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Great Offers</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ======== image Activities mobile view ======== */}
                  <div className="w-full lg:hidden block">
                    <div className="w-12/12">
                      <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-2 ">
                        <div
                          className="col-span-1 row-span-1 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                          onClick={handleOpenModalImageGallery}
                        >
                          <img
                            className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                            src={packageImages[0]}
                            alt=""
                            sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                          />
                          <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                        </div>
                        {Array.isArray(packageImages) &&
                          packageImages
                            .filter((_, i) => i >= 1 && i < 2)
                            .map((item, index) => (
                              <div
                                key={index}
                                className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                  }`}
                              >
                                <div className="aspect-w-8 aspect-h-4 sm:aspect-w-5 sm:aspect-h-4">
                                  <img
                                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-12/12 h-full"
                                    src={item || ""}
                                    alt=""
                                    sizes="400px"
                                  />
                                </div>

                                {/* OVERLAY */}
                                <div
                                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                  onClick={handleOpenModalImageGallery}
                                />
                              </div>
                            ))}

                        <button
                          className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                          onClick={handleOpenModalImageGallery}
                        >
                          <Squares2X2Icon className="w-5 h-5" />
                          <span className="ml-2 text-neutral-800 text-sm font-medium">
                            Show all photos
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="lg:w-4/12">
                      <div className="!space-y-3 ">
                        {/* ============== Tour include benifits desktop view =============*/}
                        <div className="rounded-lg border lg:block hidden">
                          <div className="gradient px-4 xl:h-36 rounded-lg">
                            <div className="flex">
                              <ul className="xl:ml-12 border-r h-10 text-white lg:w-6/12">
                                <li className="xl:-ml-4 text-sm font-normal font xl:pt-3 lg:text-sm">
                                  Meal Plan - All Inclusive
                                </li>
                              </ul>
                              <ul className="text-white xl:ml-12 lg:w-6/12">
                                <li className="xl:-ml-3 text-sm font-normal font xl:pt-3 lg:text-sm">
                                  Transfers - Speed Boat
                                </li>
                              </ul>
                            </div>
                            <div className="p-4 border-t mt-2">
                              <div className="flex">
                                <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                  <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                    {resortTimings[0]
                                      ? (resortTimings[0] as any).start_month
                                      : "SEP"}{" "}
                                    -{" "}
                                    {resortTimings[0]
                                      ? (resortTimings[0] as any).end_month
                                      : "OCT"}
                                  </li>
                                </ul>
                                <ul className="text-white xl:ml-12 lg:w-6/12">
                                  <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                    {resortTimings[1]
                                      ? (resortTimings[1] as any).start_month
                                      : "NOV"}{" "}
                                    -{" "}
                                    {resortTimings[1]
                                      ? (resortTimings[1] as any).end_month
                                      : "DEC"}
                                  </li>
                                </ul>
                              </div>
                              <div className="flex pt-3 xl:-mt-12">
                                <ul className="xl:ml-8 text-white lg:w-6/12">
                                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                    {" "}
                                    {resortTimings[0]
                                      ? formatPrice(
                                        (resortTimings[0] as any).amount
                                      )
                                      : "98,650"}
                                    /-
                                  </li>
                                </ul>
                                <ul className="xl:ml-16 text-white lg:w-6/12">
                                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                    {" "}
                                    {resortTimings[1]
                                      ? formatPrice(
                                        (resortTimings[1] as any).amount
                                      )
                                      : "11,8650"}
                                    /-
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {resortTimings[2] && (
                            <div className="gradient px-4 mt-4 xl:h-36 rounded-lg">
                              <div className="p-4 border-t mt-2">
                                <div className="flex">
                                  <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                                    <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                                      {resortTimings[2]
                                        ? getMonthName((resortTimings[2] as any).start_month.split("-")[1])
                                        : "SEP"}{" "}
                                      -{" "}
                                      {resortTimings[2]
                                        ? getMonthName((resortTimings[2] as any).end_month.split("-")[1])
                                        : "OCT"}
                                    </li>
                                  </ul>
                                  <ul className="text-white xl:ml-12 lg:w-6/12">
                                    <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                                      {resortTimings[3]
                                        ? getMonthName((resortTimings[3] as any).start_month.split("-")[1])
                                        : "NOV"}{" "}
                                      -{" "}
                                      {resortTimings[3]
                                        ? getMonthName((resortTimings[3] as any).end_month.split("-")[1])
                                        : "DEC"}
                                    </li>
                                  </ul>
                                </div>
                                <div className="flex pt-3 xl:-mt-12">
                                  <ul className="xl:ml-8 text-white lg:w-6/12">
                                    <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                      {" "}
                                      {resortTimings[2]
                                        ? formatPrice(
                                          (resortTimings[2] as any).amount
                                        )
                                        : "98,650"}
                                      /-
                                    </li>
                                  </ul>
                                  <ul className="xl:ml-16 text-white lg:w-6/12">
                                    <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                                      {" "}
                                      {resortTimings[3]
                                        ? formatPrice(
                                          (resortTimings[3] as any).amount
                                        )
                                        : "11,8650"}
                                      /-
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* ============== Tour include benifits Mobile view =============*/}
                        <div className="md:flex justify-between">
                          <div className="border px-4 py-4 rounded-lg shadow xl:hidden lg:hidden md:hidden hidden mt-6 md:mt-0 md:w-6/12 md:mr-2">
                            <div className="flex items-center justify-between">
                              <div className="text-base font-medium lg:w-7/12">
                                Activities
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="lg:w-3/12 text-end"></div>
                                <div className="border-orange-500 rounded-md text-orange-600 text-center border text-xs font-medium px-3">
                                  {placeName}
                                </div>
                              </div>
                            </div>

                            <nav
                              className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                              data-nc-id="SocialsList"
                            >
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-hotel"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Parasailing
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-concierge-bell"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Diving
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-car-side"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Kids Club
                                </span>
                              </div>
                              <div className="">
                                <a
                                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                  href=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="las la-magic"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                  Surfing
                                </span>
                              </div>
                            </nav>
                          </div>
                          <div className="rounded-lg border xl:hidden lg:hidden md:hidden hidden md:w-6/12 md:mt-0 mt-4 md:mb-0 mb-4">
                            <div className="gradient px-4 rounded-t-lg">
                              <div className="flex justify-between items-center pt-3">
                                <div className="text-white text-sm lg:w-8/12">
                                  Tour Cost Person
                                </div>
                                <div className="text-white text-sm lg:w-4/12 textright">
                                  <button type="button">
                                    <div className="p-1 border border-white rounded-full text-sm px-4 text-center">
                                      {packageType}{" "}
                                      <span>
                                        <i className="las la-angle-double-right"></i>
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Best Offers</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Manage Bookings</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Special Deals</li>
                                </ul>
                                <ul className="list-disc text-white ">
                                  <li className="text-sm">Great Offers</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                {/* MAIN */}
                <main className=" relative z-10 mt-0 lg:mt-11 flex flex-col lg:flex-row gap-6">
                  <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-6 lg:pb-12">
                    <section
                      id="itinerary"
                      className="lg:p-4 p-2 lg:bg-white border lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-6 lg:pt-3 md:pt-4"
                    >
                      <h2 className="text-xl font-semibold font1">
                        {bannerTitle}
                      </h2>
                      <p className="font1 text-sm pt-2">{bannerDescription}</p>
                    </section>
                    <section
                      id="itinerary"
                      className=" lg:bg-white lg:dark:bg-black/20  xl:mt-0 lg:mt-0 md:mt-0 xl:pt-2 lg:pt-2 md:pt-2"
                    >
                      {resorts.map((item: any, index: number) => (
                        <div
                          className={`nc-PropertyCardH group lg:mb-6 xl:my-4 lg:my-0 md:my-4 my-4 relative shadow dark:bg-neutral-900 border border-sky-100 dark:border-neutral-800 rounded-lg overflow-hidden hover:shadow-xl transition-shadow `}
                          data-nc-id="PropertyCardH w-full"
                        >
                          <div className="">
                            <div className="h-full w-full sm:items-center">
                              <div className="xl:flex lg:flex md:flex sm:flex">
                                {item.image && (
                                  <NcImage
                                    src={
                                      "https://admin.trawelmart.com/admin/public/" +
                                      item.image
                                    }
                                    alt={item.resort_name + " Image"}
                                  />
                                )}
                                <div className="flex flex-wrap p-3 space-y-3 w-full">
                                  <div className="lg:w-7/12 md:w-7/12 sm:w-full space-y-0 pt-2">
                                    <div className="xl:text-lg lg:text-base text-base font-semibold">
                                      {item.resort_name}
                                    </div>
                                    <div className="items-center">
                                      <span className="las la-star checked"></span>
                                      <span className="las la-star checked"></span>
                                      <span className="las la-star checked"></span>
                                      <span className="las la-star checked"></span>
                                      <span className="las la-star checked"></span>
                                      <span className="font-normal text-xs pl-2">
                                        (120 reviews)
                                      </span>
                                    </div>
                                    <div className="flex items-center font xl:pt-6 lg:pt-4 md:pt-6 pt-4">
                                      <div className="">
                                        <i className="las la-user xl:text-xl text-sky-600"></i>
                                      </div>
                                      <div className="items-center ">
                                        <h3 className="pl-2 text-xs uppercase font-semibold font">
                                          Occupancy
                                        </h3>
                                        <div className="pl-2 text-xs pt-1">
                                          {item.resort_occupancy}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-center font xl:pt-4 lg:pt-4 md:pt-4 pt-4">
                                      <div className="">
                                        <i className="las la-home xl:text-xl text-sky-600"></i>
                                      </div>
                                      <div className="items-center">
                                        <h3 className="pl-2 text-xs uppercase font-semibold font">
                                          No of Villas
                                        </h3>
                                        <div className="pl-2 text-xs pt-1">
                                          {item.resort_villa_count}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-center font xl:pt-4 lg:pt-4 md:pt-4 pt-4">
                                      <div className="">
                                        <i className="las la-expand xl:text-xl text-sky-600"></i>
                                      </div>
                                      <div className="items-center">
                                        <h3 className="pl-2 text-xs uppercase font-semibold font">
                                          Room Size
                                        </h3>
                                        <div className="pl-2 text-xs pt-1 font">
                                          {item.resort_room_size}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pl-3 xl:block lg:block md:block block lg:w-5/12 md:w-5/12 sm:w-12/12 xl:border-l-2 lg:border-l-2 md:border-l-2 xl:border-dashed lg:border-dashed md:border-dashed text-left pt-8 justify-start space-y-0.5">
                                    <span className="font nc-Badge text-xs bg-sky-100 text-sky-700 px-4 py-1 xl:-mt-4 rounded-full">
                                      Standard{" "}
                                    </span>
                                    <p className="text-base font-normal pt-4 text-neutral-500">
                                      <i className="las la-clock text-sky-600 text-base pr-1"></i>
                                      <span className="text-xs font-medium text-black uppercase">
                                        Check-In -
                                      </span>{" "}
                                      2 PM
                                    </p>
                                    <p className="text-base font-normal pt-2 text-neutral-500">
                                      <i className="las la-clock text-sky-600 text-base pr-1"></i>
                                      <span className="text-xs font-medium text-black uppercase">
                                        Check-Out -
                                      </span>{" "}
                                      12 PM
                                    </p>

                                    <nav
                                      className="pt-4 pb-2 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                      data-nc-id="SocialsList"
                                    >
                                      <div className="">
                                        <a
                                          className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title="Facebook"
                                        >
                                          <i className="las la-wifi"></i>
                                        </a>
                                      </div>
                                      <div className="">
                                        <a
                                          className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title="Facebook"
                                        >
                                          <i className="las la-concierge-bell"></i>
                                        </a>
                                      </div>
                                      <div className="">
                                        <a
                                          className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title="Facebook"
                                        >
                                          <i className="las la-concierge-bell"></i>
                                        </a>
                                      </div>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <BtnLikeIcon
                              colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
                              isLiked={true}
                              className="absolute right-5 top-5 sm:right-3 sm:top-3 "
                            />
                          </div>
                        </div>
                      ))}
                    </section>

                    <div className="app block lg:block hidden">
                      {tourInfoDetails.map((item: any, index: number) => (
                        <Accordion
                          key={index}
                          title={item.title}
                          content={item.description}
                        />
                      ))}
                    </div>

                    {/* Tour Details */}

                    {/* <section id="reviews" className="mt-4">
                    <span className="lg:text-xl text-xl font-semibold">
                      Upgrades Available
                    </span>
                    <p className="">Want luxury? Add luxury at minimum cost!</p>
                  </section> */}

                    <div className="app block lg:hidden">
                      {tourInfoDetails.map((item: any, index: number) => (
                        <Accordion
                          key={index}
                          title={item.title}
                          content={item.description}
                        />
                      ))}
                    </div>

                    {/* <section className="listingSection__wrap hidden lg:block">
                    <Tab.Group>
                      <Tab.List className="grid grid-cols-2 gap-0 space-x-0 overflow-x-auto bg-blue-500 rounded-lg">
                        {categories3.map((item) => (
                          <Tab key={item} as={Fragment}>
                            {({ selected }) => (
                              <button
                                className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${
                                  selected
                                    ? "bg-blue-500 text-white "
                                    : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                } `}
                              >
                                {item}
                              </button>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel className="">
                          <div className="-mt-2 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1 text-base font-regular">
                            Need to upgrade to business or first class? Please
                            get in touch with our team on +91 8046427999 for
                            more details.
                          </div>
                        </Tab.Panel>

                        <Tab.Panel className="">
                          <div className="grid grid-cols-1 gap-0 md:gap-2 lg:grid-cols-1">
                            <div className="font-regular text-normal">
                              You can select the Hotel Upgrade in your coach at
                              an additional cost. Please get in touch with our
                              team on +91 8046427999 for more details.
                            </div>
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </section> */}

                    <section id="reviews"></section>
                  </div>

                  {/* SIDEBAR */}
                  <div className="block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
                    <div className="sticky top-28">
                      <div className=" bg-white rounded-xl shadow-xl height">
                        <div className=" p-4 bg-[#fbf7fb] rounded-lg">
                          <div className="flex justify-between">
                            <span className="text-xl font-semibold">
                              Booking Details
                              <div className="text-xs font-normal text-blue-800 font">
                                {guestsTraveled} Guests already travelled
                              </div>
                            </span>
                            <div className="text-right ">
                              <div className="border-orange-500 rounded text-orange-600 px-6 text-center border text-xs font-semibold py-1">
                                Maldives
                              </div>
                              <StartRating1 className="w-full pt-2 ml-6" />
                            </div>
                          </div>
                        </div>

                        <div className="w-full border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
                        <div className="">
                          <form>
                            <div className="mx-auto xl:mt-4">
                              <label className="block xl:px-8 px-4">
                                <Input
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder="Full Name"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                                />
                                {nameError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid name
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <Input
                                  type="number"
                                  value={mobileNumber}
                                  onChange={(e) =>
                                    setMobileNumber(e.target.value)
                                  }
                                  placeholder="Phone Number"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                />
                                {mobileError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid mobile number
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <Input
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder="Email ID"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                                />
                                {emailError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid email
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <Input
                                  type=""
                                  value={departureCity}
                                  onChange={(e) =>
                                    setDepartureCity(e.target.value)
                                  }
                                  placeholder="Departure City"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                />
                                {departureError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid departure city
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <Input
                                  value={travelDate}
                                  onChange={(e) =>
                                    setTravelDate(e.target.value)
                                  }
                                  type={inputDateType}
                                  placeholder="Travel Date"
                                  onFocus={() => setInputDateType("date")}
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                />
                                {travelError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid travel date
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <Input
                                  type="number"
                                  value={noofPassanger}
                                  onChange={(e) =>
                                    setNoofPassanger(e.target.value)
                                  }
                                  placeholder="No of Passenger"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                                />
                                {passangerError && (
                                  <p
                                    style={{ textAlign: "start", color: "red" }}
                                  >
                                    Please enter valid number of passenger
                                  </p>
                                )}
                              </label>
                              <label className="block xl:px-8 px-4">
                                <textarea
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  placeholder="Drop us a small description"
                                  className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                                />
                              </label>
                            </div>

                            <div className="xl:px-8 px-4 mt-4">
                              <button
                                type="button"
                                id="submitButton"
                                className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75"
                                onClick={handleSubmit}
                              >
                                Submit Enquiry
                              </button>
                            </div>
                            <p className="mt-4 text-xs text-center">
                              Get the most recent travel offers, new tour{" "}
                              <br></br>announcements, trip ideas and much more.
                            </p>
                            <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                              <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                <div className="lg:w-6/12 text-left flex items-center">
                                  <div className="lg:w-2/12">
                                    <img
                                      className="block dark:hidden w-8"
                                      src={presents}
                                      alt="logo1"
                                    />
                                  </div>
                                  <div className="lg:w-9/12 pl-2">
                                    <span className="text-sm font-medium ">
                                      Super Offers
                                    </span>
                                    <p className="text-sky-700 font font-normal text-xs ">
                                      Explore Great Deals
                                    </p>
                                  </div>
                                </div>
                                <div className="lg:w-6/12 text-left flex items-center">
                                  <div className="lg:w-2/12">
                                    <img
                                      className="block dark:hidden w-8"
                                      src={luggage}
                                      alt="logo1"
                                    />
                                  </div>
                                  <div className="lg:w-10/12 pl-2">
                                    <span className="text-sm font-medium ">
                                      My Trips
                                    </span>
                                    <p className="text-sky-700 font-normal font text-xs ">
                                      Manage Your Bookings
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>


                </main>
              </div>
            </DetailPagetLayout>
          ))}
      </>
    </div>
  );
}

export default Dynamic_without_checkout;