import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";

import DatePicker from "react-datepicker";



const Booking: FC = () => {

  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setCheckInDate(start);
    setCheckOutDate(end);
  };

  const dayClassName = (date: Date) => {
    if (checkInDate && checkOutDate) {
      if (date.getTime() === checkInDate.getTime()) {
        return "check-in-day"; // Add class for check-in date
      }
      if (date.getTime() === checkOutDate.getTime()) {
        return "check-out-day"; // Add class for check-out date
      }
      if (date > checkInDate && date < checkOutDate) {
        return "in-range-day"; // Add class for dates between check-in and check-out
      }
    }
    return "";
  };


  const [numOfRooms, setNumOfRooms] = useState(0);
  const [roomsData, setRoomsData] = useState(
    Array.from({ length: 0 }, () => ({ adults: 0, children: 0, infants: 0 }))
  );

  const handleRoomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const numberOfRooms = Number(event.target.value);
    setNumOfRooms(numberOfRooms);
    setRoomsData(
      Array.from({ length: numberOfRooms }, () => ({
        adults: 0,
        children: 0,
        infants: 0,
      }))
    );
  };

  const handleChange = (
    index: number,
    type: "adults" | "children" | "infants",
    value: number
  ) => {
    const updatedRoomsData = [...roomsData];
    updatedRoomsData[index][type] = value;
    setRoomsData(updatedRoomsData);
  };

  return (
    <div>
      <Helmet>
        <title>Book a Tour</title>
      </Helmet>
      <section className="xl:py-16 bg-gray-50">
        <div className="container mx-auto">
          <h6 className="font-bold xl:text-5xl fontfamily">Book a Tour</h6>
          <div className="relative z-10 mt-0 lg:mt-4 xl:mt-12 flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-6  lg:pb-12">
              <div className="bg-white border rounded xl:py-8 xl:px-6">
                <h1 className="xl:text-xs uppercase fontfamily font-bold">
                  Departure City
                </h1>
                <select className="xl:w-12/12 rounded xl:mt-3">
                  <option>Bangalore Airport</option>
                  <option>Chennai Airport</option>
                  <option>Hyderabad Airport</option>
                </select>
                <div className=" xl:mt-6 gap-4">
                  {/* <div className="xl:w-6/12">
                    <h1 className="xl:text-xs uppercase fontfamily font-bold">
                      Check-In
                    </h1>
                    <input
                      className="xl:w-full rounded xl:mt-3 border"
                      type="date"
                    />
                  </div>
                  <div className="xl:w-6/12">
                    <h1 className="xl:text-xs uppercase fontfamily font-bold">
                      Check-Out
                    </h1>
                    <input
                      className="xl:w-full rounded xl:mt-3 border"
                      type="date"
                    />
                  </div> */}
                   <div className=" xl:mt-6 gap-4">
                   {/* <DatePicker
                    selected={checkInDate}
                    onChange={handleDateChange}
                    startDate={checkInDate}
                    endDate={checkOutDate}
                    selectsRange
                    inline
                    dayClassName={dayClassName} // Add custom classes to days
                  /> */}

                  <div className="grid xl:grid-cols-3 xl:gap-4">
                    <div className="border">
Check In
                    </div>
                    <div className="border">
                      Check Out
                    </div>
                    <div className="border">
                      Rooms & Gues
                    </div>
                  </div>
                </div>
                </div>

                <div className="flex xl:mt-6 gap-4">
                  <div className="xl:w-6/12">
                    <h1 className="xl:text-xs uppercase fontfamily font-bold">
                      Total No Of Days
                    </h1>
                    <input
                      className="xl:w-full rounded xl:mt-3 border"
                      type="text"
                    />
                  </div>
                  <div className="xl:w-6/12">
                    <h1 className="xl:text-xs uppercase fontfamily font-bold">
                      No Of Rooms Required
                    </h1>
                    <select
                      className="rounded xl:mt-3"
                      value={numOfRooms}
                      onChange={handleRoomChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>

                {numOfRooms > 0 &&
                  roomsData.map((room, index) => (
                    <div key={index} className="flex xl:mt-6 gap-4 flex-col">
                      {/* <h2 className="font-bold text-lg">Room {index + 1}</h2> */}

                      <div className="flex gap-4">
                        <div className="xl:w-4/12">
                          <h1 className="xl:text-xs uppercase fontfamily font-bold">
                            Adults
                          </h1>
                          <select
                            className="rounded xl:mt-3"
                            value={room.adults}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "adults",
                                Number(e.target.value)
                              )
                            }
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>

                        <div className="xl:w-4/12">
                          <h1 className="xl:text-xs uppercase fontfamily font-bold">
                            Children
                          </h1>
                          <select
                            className="rounded xl:mt-3"
                            value={room.children}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "children",
                                Number(e.target.value)
                              )
                            }
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>

                        <div className="xl:w-4/12">
                          <h1 className="xl:text-xs uppercase fontfamily font-bold">
                            Infants
                          </h1>
                          <select
                            className="rounded xl:mt-3"
                            value={room.infants}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "infants",
                                Number(e.target.value)
                              )
                            }
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>

                      {room.children > 0 && (
                        <div className="mt-4">
                          <h3 className="font-bold">Children's Ages</h3>
                          <div className="flex flex-wrap gap-4">
                            {Array.from({ length: room.children }).map(
                              (_, childIndex) => (
                                <input
                                  key={childIndex}
                                  className="rounded xl:mt-3 border xl:w-1/5"
                                  type="number"
                                  placeholder={`Child ${childIndex + 1} Age`}
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {room.infants > 0 && (
                        <div className="mt-4">
                          <h3 className="font-bold">Infants' Ages</h3>
                          <div className="flex flex-wrap gap-4">
                            {Array.from({ length: room.infants }).map(
                              (_, infantIndex) => (
                                <input
                                  key={infantIndex}
                                  className="rounded xl:mt-3 border xl:w-1/5"
                                  type="number"
                                  placeholder={`Infant ${infantIndex + 1} Age`}
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="xl:w-4/12 block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
              {/* Additional content can go here */}
              <div className="sticky top-12">
                {/* Sticky content goes here */}
                <div className="p-4 border rounded bg-white shadow">
                  <div className="text-orange-600 uppercase fontfamily text-sm font-medium">
                    Booking Details
                  </div>
                  <p className="fontfamily xl:text-xl pt-2 font-bold">
                    Enchanting Bali 7 Days
                  </p>
                  <hr className="my-3"></hr>

                  <h5 className="fontfamily text-base font-semibold">
                    Departure City
                  </h5>

                  <div className="flex justify-between pt-2">
                    <p className="fontfamily xl:text-base">
                      Bangalore, India
                      Bengaluru International Airport
                    </p>
                    <p className="text-green-700 fontfamily xl:text-sm font-semibold">
                      BLR
                    </p>
                  </div>



                  <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-6 font-bold">
                    Key Dates
                  </p>
                  <div className="flex justify-between xl:pt-2">
                    <p className="fontfamily font-semibold">
                      Check In
                    </p>
                    <p className="uppercase fontfamily ">
                      Sep 4, 2024
                    </p>
                  </div>
                  <div className="flex justify-between xl:pt-2">
                    <p className="fontfamily font-semibold">
                      Check Out
                    </p>
                    <p className="uppercase fontfamily ">
                      Sep 4, 2024
                    </p>
                  </div>

                  <hr className="my-3"></hr>

                  <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-2 font-bold">
                    Room & Travellers
                  </p>
                  <div className="flex justify-between xl:pt-2">
                    <p className="fontfamily font-semibold">
                      No Of Room Required
                    </p>
                    <p className="uppercase fontfamily ">
                      02
                    </p>
                  </div>
                  <div className="flex justify-between xl:pt-3">
                    <p className="fontfamily font-semibold">
                      Travellers
                    </p>
                    <p className="uppercase fontfamily ">
                      02 Adults
                    </p>
                  </div>

                  <hr className="my-3"></hr>

                  <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-2 font-bold">
                    Costs (INR)
                  </p>
                  <div className="flex justify-between xl:pt-2 items-center">
                    <div className="xl:w-8/12">
                      <p className="uppercase fontfamily text-sm font-medium">
                        02 Adults
                      </p>
                      <p className="uppercase fontfamily text-sm font-medium xl:pt-1">
                        01 Children
                      </p>
                    </div>
                    <div className="xl:w-4/12 text-right">
                      <p className="fontfamily font-extrabold xl:text-2xl">
                        ₹ 1,54,000
                        <p className="pt-1 text-green-600 xl:text-xs font-medium">
                          Per Person On Twin Sharing
                        </p>
                      </p>
                    </div>
                  </div>

                  <hr className="my-3"></hr>


                  <div className="bgorange rounded py-2 text-white text-center fontfamily">
                    <a href="/checkoutt">
                    Book Now
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Booking;
