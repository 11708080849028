import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import { TaxonomyType } from "data/types";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionVideos from "./SectionVideos";
import awardedindiabesttourismcompanytrawelmart from "images/sample/awarded-india-best-tourism-company-trawel-mart.png";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import client1 from "images/uk/client1.webp";
import client2 from "images/uk/client2.webp";
import client3 from "images/uk/client3.webp";
import client4 from "images/uk/client4.webp";
import client5 from "images/uk/client5.webp";
import client6 from "images/uk/client6.webp";
import client7 from "images/uk/client7.webp";

import { Link, useLocation } from "react-router-dom";
import besttourpackagescompanybangaloretrawelmart from "images/sample/best-tour-packages-company-bangalore-trawel-mart.jpg";
import PagePopup from "./PagePopup";
import mblbanner from "images/sample/mblbanner.jpg";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import PageEnquiryy from "./PageEnquiryy";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";
import TMHomeBannerBackground from "images/sample/TM-Home-Banner-Background.jpg";
import { useInView } from "react-intersection-observer";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Slider from "react-slick";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LocationInput from "components/HeroSearchForm/LocationInput";
import StayDatesRangeInput from "components/HeroSearchForm/(stay-search-form)/StayDatesRangeInput";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { GuestsObject } from "components/HeroSearchForm/type";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tmdubaitours from "images/sample/tmdubaitours.jpg";
import tmbalitours from "images/sample/tmbalitours.jpg";
import tmsingaporetours from "images/sample/tmsingaporetours.jpg";
import tmsrilankatours from "images/sample/tmsrilankatours.jpg";
import tmmalaysiatours from "images/sample/tmmalaysiatours.jpg";
import tmcambodiatours from "images/sample/tmcambodiatours.jpg";
import tmthailandtours from "images/sample/tmthailandtours.jpg";
import tmvietnamtours from "images/sample/tmvietnamtours.jpg";
import tmeuropetours from "images/sample/tmeuropetours.jpg";
import tmnepaltours from "images/sample/tmnepaltours.jpg";
// import tmbannerr from "images/sample/tmbannerr.jpg";
import tmphilippinestours from "images/sample/tmphilippinestours.jpg";
import tmmobilebannerr from "images/sample/tmmobilebannerr.jpg";
import tmawarddd from "images/sample/tmawarddd.png";
import tmawardd from "images/sample/tmawardd.jpg";
import tmchristmasbanner from "images/sample/tmchristmasbanner.jpg";
import tmgreecetours from "images/sample/tmgreecetours.jpg";
import tmkenyatours from "images/sample/tmkenyatours.jpg";
import tmmoroccotours from "images/sample/tmmoroccotours.jpg";
import sankranti from "images/sample/sankranti.jpg"
import homepagebanner from "images/sample/homepagebanner.png";
import desktopbannernew from "images/sample/desktopbannernew.jpg";
// import trawelmartmobilebannerfrom "images/sample/trawelmartmobilebannerfrom";
import trawelmartmobilebanner from "images/sample/trawelmartmobilebanner.jpg";
import bluebannernew from "images/sample/bluebannernew.jpg";
import bluebannernewone from "images/bluebannernewone.jpg";
import mobilebluebanner from "images/mobilebluebanner.jpg";
import latestbluebanner1 from "images/latestbluebanner1.jpg";
import bgbanner from "images/uk/bgbanner.jpg";
import world from "images/uk/world.png";
import customer from "images/uk/customer.png";
import guarantee from "images/uk/guarantee.png";
import Testimonials from "../PageContact/Testimonials";

import elegantbali from "images/uk/elegantbali.webp";
import elegantvietnam from "images/uk/elegantvietnam.webp";
import elegantaustralia from "images/uk/elegantaustralia.webp";
import elegantcambodia from "images/uk/elegantcambodia.webp";
import elegantsrilanka from "images/uk/elegantsrilanka.webp";
import elegantnepal from "images/uk/elegantnepal.webp";
import elegantsingapore from "images/uk/elegantsingapore.webp";
import elegantmalaysia from "images/uk/elegantmalaysia.webp";
import elegantthailand from "images/uk/elegantthailand.webp";
import elegantdubaii from "images/uk/elegantdubaii.webp";
import elegantvietnamm from "images/uk/elegantvietnamm.webp";
import elegantbalii from "images/uk/elegantbalii.webp";
import elegantcambodiaa from "images/uk/elegantcambodiaa.webp";
import elegantjapann from "images/uk/elegantjapann.webp";
import elegantnewzealand from "images/uk/elegantnewzealand.webp";
import homebanner from "images/uk/homebanner.mp4";
import elegantootyy from"images/uk/elegantootyy.webp";
import elegantpunee from"images/uk/elegantpunee.webp";



const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/dubai-tour-packages",
    name: "Dubai",
    taxonomy: "category",
    count: 188288,
    text: "₹5,000",
    thumbnail: elegantdubaii,
  },
  {
    id: "2",
    href: "/japan-tour-packages",
    name: "Japan",
    taxonomy: "category",
    count: 21288,
    text: "₹4,000",
    thumbnail: elegantjapann,
  },
  {
    id: "3",
    href: "/bali-tour-packages",
    name: "Bali",
    taxonomy: "category",
    count: 188288,
    text: "₹1,500",
    thumbnail: elegantbalii,
  },
  {
    id: "4",
    href: "/cambodia-tour-packages",
    name: "Cambodia",
    taxonomy: "category",
    count: 188288,
    text: "₹6,000",
    thumbnail: elegantcambodiaa,
  },
  {
    id: "5",
    href: "/vietnam-tour-packages",
    name: "Vietnam",
    taxonomy: "category",
    count: 18288,
    text: "₹4,000",
    thumbnail: elegantvietnamm,
  },
  {
    id: "6",
    href: "/newzealand-tour-packages",
    name: "Newzealand",
    taxonomy: "category",
    count: 19288,
    text: "₹4,000",
    thumbnail: elegantnewzealand,
  },
  
];

function PageHome() {
  const [landingPages, setLandingPages] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEnquiryOpened, setIsEnquiryOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [activeeTab, setActiveeTab] = useState(1);
  const [activeeeTab, setActiveeeTab] = useState(1);
  const [activeeeeTab, setActiveeeeTab] = useState(1);
  const [baliPackages, setBaliPackages] = useState([]);
  const [dubaiPackages, setDubaiPackages] = useState([]);
  const [cambodiaPackages, setCambodiaPackages] = useState([]);
  const [malaysiaPackages, setMalaysiaPackages] = useState([]);
  const [thailandPackages, setThailandPackages] = useState([]);
  const [singaporePackages, setSingaporePackages] = useState([]);
  const [vietnamPackages, setVietnamPackages] = useState([]);
  const [maldivesPackages, setMaldivesPackages] = useState([]);
  const [srilankaPackages, setSrilankaPackages] = useState([]);
  const [nepalPackages, setNepalPackages] = useState([]);
  const [philippinesPackages, setPhilippinesPackages] = useState([]);
  const [greecePackages, setGreecePackages] = useState([]);
  const [MoraccoPackages, setMoraccoPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  const handleTabClick1 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeTab(tabNumber);
  };

  const handleTabClick2 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeTab(tabNumber);
  };

  const handleTabClick3 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeeTab(tabNumber);
  };

  useEffect(() => {
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getLandingPagesForWebsite"
      )
      .then((response) => {
        if (response.data.status) {
          setLandingPages(response.data.pages);
        }
      });

    const isLoggedInString = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(isLoggedInString ? JSON.parse(isLoggedInString) : false);
    const isEnquiryOpened = localStorage.getItem("isEnquiryOpened");
    setIsEnquiryOpened(isEnquiryOpened ? JSON.parse(isEnquiryOpened) : false);
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite"
      )
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;

          const filterByCity = (city: any) =>
            packages.filter((package1: any) => package1.tour_city === city);

          Promise.all([
            setBaliPackages(filterByCity("Bali")),
            setDubaiPackages(filterByCity("Dubai")),
            setSingaporePackages(filterByCity("Singapore")),
            setGreecePackages(filterByCity("Greece")),
            setCambodiaPackages(filterByCity("Cambodia")),
            setVietnamPackages(filterByCity("Vietnam")),
            setThailandPackages(filterByCity("Thailand")),
            setSrilankaPackages(filterByCity("Sri Lanka")),
            setMalaysiaPackages(filterByCity("Malaysia")),
            setNepalPackages(filterByCity("Nepal")),
            setPhilippinesPackages(filterByCity("Philippines")),
            setMoraccoPackages(filterByCity("Morocco")),
          ]);
        }
      });
  }, []);

  const [showMoreStates, setShowMoreStates] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleReadMoreLess = (index: number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [placeText, setPlaceText] = useState("");
  const [showPopover, setShowPopover] = useState(false);

  // const [startDate, setStartDate] = useState<Date | null>(
  //   new Date()
  // );
  // const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    setShowPopover(false);
  }, [false]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    setPlaceText(item);
    setShowPopover(false);
  };

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  //testimonials slider //
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    className: "center",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  const settings1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 4000,
    autoplay: true,
    className: "center",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-3 rounded-xl overflow-hidden group"
      >
        {/* <Link to={post.href} /> */}
        <LazyLoadImage
          effect="blur"
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-1 p-4">
          <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100  relative">
            {post.date}
          </h5>
          <h2 className="font block xl:text-lg lg:text-base md:text-lg text-base lg:font-bold font-medium text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {["Dubai", "Bali", "Singapore", "Vietnam"].map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item}
            </span>
          </span>
        ))}
      </>
    );
  };

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="font block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>
        <div className="mt-2">
          {["Dubai", "Bali", "Singapore", "Vietnam"].map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  // const onChangeDate = (dates: [Date | null, Date | null]) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  // const renderInput = () => {
  //   return (
  //     <>
  //       <div className="text-neutral-300 dark:text-neutral-400">
  //         <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
  //       </div>
  //       <div className="flex-grow text-left">
  //         <span className="block xl:text-lg font-semibold">
  //           {startDate?.toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "2-digit",
  //           }) || "Add dates"}
  //           {endDate
  //             ? " - " +
  //               endDate?.toLocaleDateString("en-US", {
  //                 month: "short",
  //                 day: "2-digit",
  //               })
  //             : ""}
  //         </span>
  //         <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
  //           {"Check in - Check out"}
  //         </span>
  //       </div>
  //     </>
  //   );
  // };

  // Anusha code 05-01-2024

  // const [startDate, setStartDate] = useState<Date | null>(new Date("2024/02/06"));
  // const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  // const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  // const onChangeDate = (dates: [Date | null, Date | null]) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  // Mahima code 05-01-2024

  const [startDate, setStartDate] = useState<Date | null>(
    new Date("2024/02/06")
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg md:text-sm font-semibold">
            {selectedMonth
              ? `${selectedMonth} ${startDate?.getFullYear() || ""}`
              : "Travel Month"}
          </span>
          {/* <span className="block mt-1 text-sm text-neutral-400 font-light  leading-none">
            {"Select Month"}
          </span> */}
        </div>
      </>
    );
  };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
    setPopoverVisible(false);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const options = {
    items: 9,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
        items1: 1,
        items5: 1,
      },
      600: {
        items: 2,
        items1: 2,
        items5: 2,
      },
      1000: {
        items: 4,
        items1: 4,
        items5: 4,
      },
      1100: {
        items: 3,
        items1: 3,
        items5: 3,
      },
      1400: {
        items: 8,
        items1: 5,
        items5: 8,
      },
    },
  };

  const items = [
    <div
      key={1}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmdubaitours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Dubai Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-sm"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/dubai-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers
        </div>
      </h4>
    </div>,
    <div
      key={2}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmeuropetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Europe Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/europe-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={3}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmsingaporetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Singapore Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/singapore-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={4}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmmalaysiatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Malaysia Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/malaysia-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={5}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmthailandtours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Thailand Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/thailand-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={6}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmbalitours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Bali Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/bali-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={7}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmsrilankatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Sri Lanka Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/srilanka-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    // Add more items as needed
  ];




  const items5 = [
    <div
      key={1}
      className="item px-2 "
    >
      <h4 className=" ">
        <div className="p-3">
          <a href="/cambodia-tour-packages">
            <div className="rounded-full textcenter">

              <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
                <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage" >
                  <img src={elegantbali}
                    style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                    className="object-cover w-full h-full" alt="nc-imgs" />
                </div></div>
            </div>
            <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
              Bali
            </div>
            <p className="text-center xl:text-xs ">
              150,450 travellers
            </p>
          </a>
        </div>
      </h4>
    </div>,
    <div
      key={2}
      className="item px-2 "

    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantcambodia}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Cambodia
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,
    <div
      key={3}
      className="item px-2 "

    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantsingapore}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Singapore
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    <div
      key={4}
      className="item px-2 "
    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantsrilanka}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Srilanka
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    <div
      key={5}
      className="item px-2 "
    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantthailand}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Thailand
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,
    <div
      key={6}
      className="item px-2 "
    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantvietnam}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Vietnam
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    <div
      key={7}
      className="item px-2 "

    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantmalaysia}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Malaysia
          </div>
          <p className="text-center xl:text-xs ">
            150,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    <div
      key={8}
      className="item px-2 "

    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantnepal}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Nepal
          </div>
          <p className="text-center xl:text-xs ">
            10,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    <div
      key={9}
      className="item px-2 "

    >
      <h4 className=" ">
        <div className="p-3">
          <div className="rounded-full textcenter">
            <div className="relative flex-shrink-0 lg:w-32 lg:h-32 w-12 h-12 rounded-full overflow-hidden justify-center text-center">
              <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                <img src={elegantaustralia}
                  style={{ border: '5px solid #e6e6e6', borderRadius: '100%' }}
                  className="object-cover w-full h-full" alt="nc-imgs" />
              </div></div>
          </div>
          <div className="text-center mt-4 fontfamily xl:text-xl text-3xl font-bold textheading">
            Australia
          </div>
          <p className="text-center xl:text-xs ">
            185,450 travellers
          </p>
        </div>
      </h4>
    </div>,

    // Add more items as needed
  ];









  const items1 = [
    <div
      key={8}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmcambodiatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Cambodia Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/cambodia-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={9}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmvietnamtours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Vietnam Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/vietnam-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={10}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmkenyatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Kenya Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/kenya-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={11}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmphilippinestours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Philippines Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/philippines-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={12}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmmoroccotours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Morocco Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/morocco-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={13}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmgreecetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Greece Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/greece-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={14}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmnepaltours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Nepal Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/nepal-tour-packages">
              <div className="text-center rounded-md p-1 font textheading bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    // Add more items as needed
  ];

  return (
    <>
      <Helmet>
        <title>
          Trawel Mart India’s leading and award winning travel agency{" "}
        </title>
        <meta
          name="description"
          content="We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
        />
        <meta
          name="keywords"
          content="Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
        />
      </Helmet>
      <div className=" nc-PageHome relative overflow-hidden">
        {/* ====== Banner Desktop & LG Screen view =======*/}
        <div className="font xl:block lg:block md:hidden hidden">
          <section
            style={{
              width: "100%",
              height: "480px",
              position: "relative",
              overflow: "hidden",
              zIndex: 10, // Ensures this section stays above the next section
            }}
          >
            {/* Video Element as Background */}
            <video
              autoPlay
              loop
              muted
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: -1, // Ensures the video stays behind any text/content
              }}
            >
              <source src={homebanner} type="video/mp4" />
            </video>
            <div className="text-center xl:pt-32 lg:pt-24">
              {/* <div className="marquee">
                <h2 className="overlay-text marquee-text">
                  Beautiful Switzerland
                </h2>
                <h2 className="overlay-text marquee-text">
                  Beautiful Switzerland
                </h2>
                <h2 className="overlay-text marquee-text">
                  Beautiful Switzerland
                </h2>
              </div> */}
              <h2 className="text-white z-50 xl:pt-10 xl:text-8xl font-bold">
                Beautiful Switzerland
              </h2>
              <div className="z-50 xl:pt-12 flex justify-center">
                <p className="py-3 px-16 bg-white textheading xl:text-sm">
                  See All Offers
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="container mx-auto relative xl:-mt-12 z-20 xl:px-52">
          <div className="bgorange rounded-lg ">
            <div className="grid xl:grid-cols-3 xl:gap-16 xl:py-8 xl:px-14">
              <div className="flex items-center">
                <div className="">
                  <img src={world} className="w-16"></img>
                </div>
                <div className="xl:pl-8">
                  <h1 className="font-bold fontfamily xl:text-base uppercase text-white">
                    700+ Destinations
                  </h1>
                  <p className="pt-1 text-white fontfamily xl:text-sm">
                    Our Expert team handpicked all destinations in this site
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <div className="">
                  <img src={guarantee} className="w-16"></img>
                </div>
                <div className="xl:pl-8">
                  <h1 className="font-bold fontfamily xl:text-base uppercase text-white">
                    Best Price Guarantee
                  </h1>
                  <p className="pt-1 text-white fontfamily xl:text-sm">
                    Our Expert team handpicked all destinations in this site
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <div className="">
                  <img src={customer} className="w-16"></img>
                </div>
                <div className="xl:pl-8">
                  <h1 className="font-bold fontfamily xl:text-base uppercase text-white">
                    Top Notch Support
                  </h1>
                  <p className="pt-1 text-white fontfamily xl:text-sm">
                    Our Expert team handpicked all destinations in this site
                  </p>
                </div>
              </div>


            </div>
          </div>
        </section>


        <div className=" xl:pt-12  lg:block hidden">
          <div className="container relative  lg:pb-2 lg:pt-2">
            <h4 className="text-center textheading fontfamily font-semibold xl:text-3xl xl:pb-6">
              Suggestions for Discovery
            </h4>
            <div className="">
              <OwlCarousel {...options}>{items5}</OwlCarousel>
              <div className="owl-carousel">{items5}</div>
            </div>
          </div>
        </div>


        {/* <PageEnquiryy /> */}



        {/* ==== Explore popular city desktop view ===== */}

        <div className="container relative space-y-24 mb-8 lg:space-y-28 lg:mb-12">
          <SectionSliderNewCategories
            categories={DEMO_CATS}
            uniqueClassName="PageHome_s1"
          />
        </div>


        {/* <section className="container relative space-y-24 mb-8 lg:space-y-28 lg:mb-6 xl:mt-12">
          <img className="rounded-lg" src="https://media1.thrillophilia.com/filestore/abu-dabhi-desktop.png?w=1080&dpr=1.3"></img>
        </section> */}

        <div className="container mx-auto xl:pb-6 xl:pt-4">
          <h4 className="text-left textheading fontfamily font-semibold xl:text-3xl xl:pb-6">
            World
          </h4>
          <div className="grid xl:grid-cols-3 xl:gap-4">
            {/* <div className="">
            <div className="nc-NcImage " data-nc-id="NcImage">
              <img src="https://admin.trawelmart.com/admin/public/tour_thumbnail/659523f1a21c8_Siem_Reap_tour-packages.jpg" 
              className="object-cover w-full h-full rounded-t" alt="Fun Filled Exploration of Cambodia 4 Days"/></div>
            </div> */}

            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="https://admin.trawelmart.com/admin/public/tour_thumbnail/659523f1a21c8_Siem_Reap_tour-packages.jpg"
                      className="object-cover w-full h-full" alt="Fun Filled Exploration of Cambodia 4 Days" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg><span className="fontfamily xl:text-sm">Cambodia </span>
                      <span className="pl-4 fontfamily   text-sm space-x-2 py-1">
                        <i className="las la-plane  text-base font-semibold" style={{ color: '#11bb67' }}></i>{" "}
                        With Flight
                      </span>
                      <span className="pl-4 fontfamily   text-sm space-x-2 py-1">
                        <i className="las la-ship  text-base font-semibold" style={{ color: '#11bb67' }}></i>{" "}
                        With Cruise
                      </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Fun Filled Exploration of Cambodia 4 Days</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Siem Reap (3N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹31,500<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/cambodia-3nights-4days-tour-package"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>



            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="https://admin.trawelmart.com/admin/public/tour_thumbnail/658d7cf33d295_beautiful-landscape-cityscape-hua-hin-city-around-sea-ocean-bay.jpg"
                      className="object-cover w-full h-full" alt="Fun Filled Exploration of Cambodia 4 Days" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg><span className="fontfamily xl:text-sm">Srilanka </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Lively Sri Lanka 4 Days</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Kandy (1N) , Bentota (1N)
                      Colombo (1N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹25,000<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/srilanka-3nights-4days-tour-package"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>


            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="https://admin.trawelmart.com/admin/public/tours/655c417ba9610_Langkawi_Island-Malaysia_11zon.jpeg"
                      className="object-cover w-full h-full" alt="Fun Filled Exploration of Cambodia 4 Days" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg><span className="fontfamily xl:text-sm">Malaysia </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Vibrant Malaysia 6 Days</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Langkawi (2N) , Kuala Lumpur (3N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹45,00<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/malaysia-5nights-6days-tour-package//"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>
          </div>
        </div>

        <div className="container mx-auto xl:pb-16 xl:pt-2">
          <h4 className="text-left textheading fontfamily font-semibold xl:text-3xl xl:pb-6">
            India
          </h4>
          <div className="grid xl:grid-cols-3 xl:gap-4">
            {/* <div className="">
            <div className="nc-NcImage " data-nc-id="NcImage">
              <img src="https://admin.trawelmart.com/admin/public/tour_thumbnail/659523f1a21c8_Siem_Reap_tour-packages.jpg" 
              className="object-cover w-full h-full rounded-t" alt="Fun Filled Exploration of Cambodia 4 Days"/></div>
            </div> */}

            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src={elegantpunee}
                      className="object-cover w-full h-full" alt="Exploring the Vibrant Cityscape and Beyond" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                      <span className="fontfamily xl:text-sm">Pune </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Exploring the Vibrant Cityscape and Beyond</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Pune (3N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹15,000<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/cambodia-3nights-4days-tour-package"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>



            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src={elegantootyy}
                      className="object-cover w-full h-full" alt="Ooty Adventure: Unveiling the Mysteries" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                      <span className="fontfamily xl:text-sm">Ooty </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Ooty Adventure: Unveiling the Mysteries</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Ooty (3N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹16,000<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/cambodia-3nights-4days-tour-package"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>


            <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow"
              data-nc-id="StayCard">
              <div className="relative w-full">
                <a className="block aspect-w-4 aspect-h-3" href="/cambodia-3nights-4days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="https://admin.trawelmart.com/admin/public/tours/666822865cd3a_Alleppey.jpg"
                      className="object-cover w-full h-full" alt="Kerala Odyssey: Explore the Land of Backwaters and Spice" /></div></a></div>
              <div className="">
                <div className="px-4 pt-5 space-y-2 ">
                  <div className="space-y-2 ">
                    <div className="flex items-center text-xs space-x-1 textheading">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#11bb67" viewBox="0 0 24 24" stroke="currentColor" style={{ color: 'rgb(255, 255, 255' }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                      <span className="fontfamily xl:text-sm">Kerala </span>
                    </div>
                    <div className="flex items-center space-x-2"><h2 className=" lg:font-medium font-regular capitalize xl:text-lg}">
                      <span className="fontfamily font-semibold line-clamp-1 xl:text-lg">Kerala Odyssey: Explore the Land of Backwaters and Spice</span></h2></div></div>
                  <div className="card">
                    <p className="font text-sm fontfamily font-normal xl:h-12">Kerala (3N) <span></span></p></div>
                  <div className="flex items-center"><div className="xl:w-6/12 ">
                    <div className="fontfamily nc-Badge bggreen-100 textgreen px-4 py-1 xl:text-sm uppercase font-semibold text-center rounded mt-2 xl:w-6/12">Premium </div></div>
                    <div className="xl:w-6/12"><span className="text-xl font-extrabold text-right">
                      <div className="fontfamily text-sm textheading dark:text-neutral-400 font-normal">Starts From</div>
                      <div className="fontfamily xl:text-3xl textheading font-bold">₹17,500<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span></div></span></div></div></div>
                <div className="border-dashed border-t border-black-100 dark:border-black mt-2"></div>
                <div className=""><div className="p-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                      <div className="">
                        <a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                          <i className="las la-hotel text-orangee xl:text-2xl"></i></a></div><div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer">
                            <i className="las la-concierge-bell text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-car-side text-orangee xl:text-2xl"></i></a></div>
                      <div className=""><a className="flex items-center justify-center  w-10 h-10 rounded-full bgorange-100 dark:bg-neutral-800 text-2xl" rel="noopener noreferrer"><i className="las la-binoculars text-orangee xl:text-2xl"></i></a></div></nav>
                    <a href="/cambodia-3nights-4days-tour-package"><div className="font text-xs md:text-xs xl:text-sm text-white bggreen rounded px-6 py-2 font-medium ">Explore More</div></a></div></div></div></div></div>
          </div>
        </div>





        {/* <div className="container mx-auto xl:pb-6 xl:pt-6">
          <h4 className="text-center textheading fontfamily font-semibold xl:text-3xl xl:pb-12">
            Latest Awards
          </h4>
          <div className="flex items-center justify-center">
            <div className="">
              <img src={client1} alt="" className="rounded-lg w-32 h-24" />
            </div>
            <div className="px-6">
              <img src={client2} alt="" className="rounded-lg w-32 h-24" />
            </div>
            <div className="">
              <img src={client3} alt="" className="w-32 h-24 rounded-lg" />
            </div>
            <div className="px-6">
              <img src={client4} alt="" className="rounded-lg w-32 h-24" />
            </div>
            <div className="px-6">
              <img src={client5} alt="" className="rounded-lg w-32 h-24" />
            </div>
            <div className="px-6">
              <img src={client6} alt="" className="rounded-lg w-32 h-24" />
            </div>
            <div className="px-6">
              <img src={client7} alt="" className="rounded-lg w-32 h-24" />
            </div>
          </div>
        </div> */}

        <section className="bg-orange-50 ">
          <div className="container mx-auto xl:py-16">
            <h4 className="text-center uppercase  fontfamily font-semibold text-orange-600 xl:text-sm xl:pb-2">
              Travel Category
            </h4>
            <h4 className="text-center textheading fontfamily font-semibold xl:text-3xl xl:pb-6">
              Our Popular Tour Types
            </h4>

            <div className="grid xl:grid-cols-5 xl:gap-4 xl:px-32">
              <div className="">
                <div className=" bg-white  py-8 rounded justify-center center">
                  <img className="w-12" src="https://i.ibb.co/7WYCh2m/adventure.png"></img>
                  <p className="text-black font-semibold xl:text-lg fontfamily xl:pt-3 text-center">
                    Adventure
                  </p>
                </div>
              </div>
              <div className="">
                <div className=" bg-white  py-8 rounded justify-center center">
                  <img className="w-12" src="https://i.ibb.co/qprNG6r/citytour.png"></img>
                  <p className="text-black font-semibold xl:text-lg fontfamily xl:pt-3 text-center">
                    City Tour
                  </p>
                </div>
              </div>
              <div className="">
                <div className=" bg-white  py-8 rounded justify-center center">
                  <img className="w-12" src="https://i.ibb.co/FJkgStr/cruise.png"></img>
                  <p className="text-black font-semibold xl:text-lg fontfamily xl:pt-3 text-center">
                    Cruises Tour
                  </p>
                </div>
              </div>
              <div className="">
                <div className=" bg-white p-8 rounded justify-center center">
                  <img className="w-12" src="https://i.ibb.co/8P8mDDW/beach.png"></img>
                  <p className="text-black font-semibold xl:text-xl fontfamily xl:pt-3 text-center">
                    Sea Tour
                  </p>
                </div>
              </div>
              <div className="">
                <div className=" bg-white p-8 rounded justify-center center">
                  <img className="w-12" src="https://i.ibb.co/f4v8v7k/travel.png"></img>
                  <p className="text-black font-semibold xl:text-xl fontfamily xl:pt-3 text-center">
                    Travel
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="container mx-auto xl:pb-12">
          <h4 className="text-left textheading fontfamily font-semibold xl:text-3xl xl:pb-6">
            Features
          </h4>
          <Slider {...settings} className="items-center">
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/GT-Holidays-EMI-Options-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/GT-Holidays-EMI-Option-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/EMI-Options-GT-Holidays-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/EMI-Options-at-GT-Holidays-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/EMI-Option-GT-Holidays-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/GT-Holidays-EMI-Options-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
            <div className="">
              <img src="https://www.gtholidays.in/wp-content/uploads/2022/05/GT-Holidays-EMI-Option-1-768x1085.jpg" alt="" className="rounded-lg" />
            </div>
          </Slider>
        </div> */}



        {/* ================= before Beach Section ===============*/}
        <div className="container relative lg:mt-12 space-y-24 mb-4 lg:space-y-6 xl:mb-16 md:mt-6 md:mb-6">
          <h4 className="text-left textheading fontfamily font-semibold xl:text-3xl ">
            Exclusive Collections
          </h4>
          <div className="">
            <SectionVideos />
          </div>
        </div>








        {/* <section className="">
          <div className="container mx-auto xl:py-16">
            <div className="flex mx-auto justify-center">
              <Testimonials />
            </div>
          </div>
        </section> */}


      </div>
    </>
  );
}

export default PageHome;
