import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategoryHotelProps {
    className?: string;
    taxonomy: TaxonomyType;
}

const CardCategoryHotel: FC<CardCategoryHotelProps> = ({
    className = "",
    taxonomy,
}) => {
    const { count, name, text, href = "/", thumbnail } = taxonomy;
    return (
        <Link
            to={href}
            className={`nc-CardCategory3 flex flex-col ${className}`}
            data-nc-id="CardCategory3"
        >

            <div
                className={` flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-5 h-0 rounded-2xl overflow-hidden group`}
            >
                <NcImage
                    src={thumbnail}
                    className="object-cover w-full h-full rounded-2xl"
                />
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-opacity-10 transition-opacity"></span>
            </div>
            <div className="mt-4 truncate">
                <h2
                    className={`font lg:text-lg text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate`}
                >
                    {name}
                </h2>
                {/*<span
                    className={`block font text-sm text-neutral-6000 dark:text-neutral-400`}
                >
                   Starts from {text}
    </span>*/}
            </div>
        </Link>
    );
};

export default CardCategoryHotel;
