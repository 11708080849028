import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";


export const PHOTOS: string[] = [
  /*"https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",*/
  "https://i.ibb.co/KNRspbQ/Dhow-Cruise.jpg",
  "https://images.pexels.com/photos/823696/pexels-photo-823696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://media.tacdn.com/media/attractions-splice-spp-360x240/06/70/44/8b.jpg",
  "https://etimg.etb2bimg.com/photo/89765617.cms",
  "https://assets.vogue.in/photos/6239a88deb60bae3c5bc6821/2:3/w_1920,c_limit/Dubai.jpg",
  "https://lh3.googleusercontent.com/p/AF1QipMki67sEEWcJgSIsSsbCyJdTds8Kpmpz5sdhHr5=s1360-w1360-h1020",
  "https://media.tacdn.com/media/attractions-splice-spp-360x240/06/70/44/8b.jpg",
  "https://media.cnn.com/api/v1/images/stellar/prod/220331151338-dubai-expo-district-2020-aerial-spc-intl.jpg?c=16x9&q=h_720,w_1280,c_fill/f_webp",
  "https://i.ytimg.com/vi/MTXCzi1r7WE/maxresdefault.jpg",
  "https://static.nationalgeographic.co.uk/files/styles/image_3200/public/FAM_DUBAI_KY7P59_HR_WEB.webp?w=1600&h=900",
  "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/c9/5b/f9/beschreibung-nicht-notwendig.jpg?w=1200&h=-1&s=1",
  /*"https://img.freepik.com/free-photo/modetn-city-luxury-center-dubai-united-arab-emirates_231208-7596.jpg?w=1060&t=st=1686726464~exp=1686727064~hmac=6a69d0e3275e3475a3434f9e6c7b8f649f5237774223d09842ada90ea810f2cf",
   /* "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/914128/pexels-photo-914128.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/840667/pexels-photo-840667.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/732632/pexels-photo-732632.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/450062/pexels-photo-450062.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/917510/pexels-photo-917510.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/1194233/pexels-photo-1194233.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/236973/pexels-photo-236973.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/1392099/pexels-photo-1392099.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/547116/pexels-photo-547116.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/1002272/pexels-photo-1002272.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/917511/pexels-photo-917511.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/771079/pexels-photo-771079.jpeg?auto=compress&cs=tinysrgb&w=1600",
   "https://images.pexels.com/photos/13461077/pexels-photo-13461077.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
   "https://images.pexels.com/photos/9074921/pexels-photo-9074921.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
   "https://images.pexels.com/photos/9336042/pexels-photo-9336042.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
   "https://images.pexels.com/photos/5418318/pexels-photo-5418318.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
   "https://images.pexels.com/photos/4815278/pexels-photo-4815278.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
 "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",*/
];

export const PHOTOS1: string[] = [
  "/dubai-1/D5N6_1.jpg",
  "/dubai-1/D5N6_2.jpg",
  "/dubai-1/D5N6_3.jpg",
  "/dubai-1/D5N6_4.jpg",
  "/dubai-1/D5N6_5.jpeg",
  "/dubai-1/D5N6_6.jpg",
];

export const PHOTOS2: string[] = [
  "/dubai-2/D6N7_1.jpg",
  "/dubai-2/D6N7_2.png",
  "/dubai-2/D6N7_3.jpg",
  "/dubai-2/D6N7_4.webp",
  "/dubai-2/D6N7_5.png",
  "/dubai-2/D6N7_6.png",
  "/dubai-2/D6N7_7.jpg",
];

export const PHOTOS3: string[] = [
  "/dubai-3/LD5N6_1.jpg",
  "/dubai-3/LD5N6_2.jpg",
  "/dubai-3/LD5N6_3.jpg",
  "/dubai-3/LD5N6_4.jpg",
  "/dubai-3/LD5N6_5.jpg",
  "/dubai-3/LD5N6_6.jpg",
];

export const PHOTOS4: string[] = [
  "/dubai-4/LD6N7_1.jpg",
  "/dubai-4/LD6N7_2.jpg",
  "/dubai-4/LD6N7_3.jpeg",
  "/dubai-4/LD6N7_4.jpg",
  "/dubai-4/LD6N7_5.png",
  "/dubai-4/LD6N7_6.jpg",
];

export const PHOTOS5: string[] = [
  "/dubai-5/LD7N8_1.jpg",
  "/dubai-5/LD7N8_2.jpg",
  "/dubai-5/LD7N8_3.webp",
  "/dubai-5/LD7N8_4.jpg",
  "/dubai-5/LD7N8_5.jpg",
  "/dubai-5/LD7N8_6.jpg",
  "/dubai-5/LD7N8_7.jpg",
  "/dubai-5/LD7N8_8.jpg",
];

export const PHOTOS6: string[] = [
  "/dubai-6/PA6N7D_1.jpg",
  "/dubai-6/PA6N7D_4.jpg",
  "/dubai-6/PA6N7D_2.jpeg",
  "/dubai-6/PA6N7D_3.jpg",
  "/dubai-6/PA6N7D_5.jpg",
  "/dubai-6/PA6N7D_6.jpg",
  "/dubai-6/PA6N7D_7.jpg",
  "/dubai-6/PA6N7D_8.jpg",
];

export const PHOTOS7: string[] = [
  "/dubai-7/YI6N7D_1.jpg",
  "/dubai-7/YI6N7D_2.jpg",
  "/dubai-7/YI6N7D_3.jpg",
  "/dubai-7/YI6N7D_4.png",
  "/dubai-7/YI6N7D_5.png",
  "/dubai-7/YI6N7D_6.jpeg",
  "/dubai-7/YI6N7D_7.jpg",
  "/dubai-7/YI6N7D_8.webp",
];

export const PHOTOS8: string[] = [
  "/dubai-8/L6N7D_1.jpg",
  "/dubai-8/L6N7D_2.jpg",
  "/dubai-8/L6N7D_3.jpg",
  "/dubai-8/L6N7D_4.png",
  "/dubai-8/L6N7D_5.webp",
  "/dubai-8/L6N7D_6.jpg",
  "/dubai-8/L6N7D_7.jpg",
  "/dubai-8/L6N7D_8.jpg",
  "/dubai-8/L6N7D_9.jpg",
  "/dubai-8/L6N7D_10.jpg",
];

export const PHOTOS9: string[] = [
  "/bali-1/B4N5_1.jpg",
  "/bali-1/B4N5_2.jpg",
  "/bali-1/B4N5_3.jpg",
  "/bali-1/B4N5_4.jpg",
  "/bali-1/B4N5_5.jpg",
];

export const PHOTOS10: string[] = [
  "/bali-2/Bali6N7D_1.jpg",
  "/bali-2/Bali6N7D_2.jpg",
  "/bali-2/Bali6N7D_3.webp",
  "/bali-2/Bali6N7D_4.jpg",
  "/bali-2/Bali6N7D_5.webp",
];


export const PHOTOS11: string[] = [
  "/bali-3/B5N6D_1.webp",
  "/bali-3/B5N6D_2.jpg",
  "/bali-3/B5N6D_3.webp",
  "/bali-3/B5N6D_4.webp",
  "/bali-3/B5N6D_5.jpg",
];

export const PHOTOS12: string[] = [
  "/bali-4/Bali6Nights7Days_1.jpg",
  "/bali-4/Bali6Nights7Days_2.jpeg",
 
  "/bali-4/Bali6Nights7Days_4.webp",
  "/bali-4/Bali6Nights7Days_5.jpg",
  "/bali-4/Bali6Nights7Days_6.jpg",
];

export const PHOTOS13: string[] = [
  "/bali-5/B7N8D_4.webp",
  "/bali-5/B7N8D_5.webp",
  "/bali-5/B7N8D_1.jpg",
  "/bali-5/B7N8D_2.jpg",
  "/bali-5/B7N8D_3.jpg",
  "/bali-5/B7N8D_6.webp",
];

export const PHOTOS14: string[] = [
  "/bali-6/B7NN8D_3.jpg",
  "/bali-5/B7N8D_6.webp",
  "/bali-6/B7NN8D_6.jpg",
  "/bali-5/B7N8D_5.webp",
  "/bali-6/B7NN8D_1.jpg",
  "/bali-6/B7NN8D_2.webp",
];

export const PHOTOS15: string[] = [
  "/bali-7/B7Nightss6Dayss_1.webp",
  "/bali-7/B7Nightss6Dayss_2.webp",
  "/bali-7/B7Nightss6Dayss_3.jpg",
  "/bali-7/B7Nightss6Dayss_4.jpg",
  "/bali-7/B7Nightss6Dayss_5.jpg",
  "/bali-7/B7Nightss6Dayss_6.jpg",
];

export const PHOTOS16: string[] = [
  "/bali-8/B10N11D_1.jpg",
  "/bali-8/B10N11D_5.jpg",
  "/bali-8/B10N11D_2.jpg",
  "/bali-8/B10N11D_4.webp",
  "/bali-8/B10N11D_5.jpg",
  "/bali-8/B10N11D_6.jpg",
  "/bali-8/B10N11D_7.jpg",
];

export const PHOTOS17: string[] = [
  "/bali-9/B7NN8DD_1.webp",
  "/bali-9/B7NN8DD_2.jpg",
  "/bali-9/B7NN8DD_3.jpg",
  "/bali-9/B7NN8DD_4.jpg",
  "/bali-9/B7NN8DD_5.webp",
  "/bali-9/B7NN8DD_6.jpg",
];

export const PHOTOS18: string[] = [
  "/cambodia-1/C3N4D_1.jpg",
  "/cambodia-1/C3N4D_2.jpg",
  "/cambodia-1/C3N4D_3.webp",
  "/cambodia-1/C3N4D_4.jpg",
  "/cambodia-1/C3N4D_5.webp",
];

export const PHOTOS19: string[] = [
  "/cambodia-2/C4N5D_1.webp",
  "/cambodia-2/C4N5D_2.jpeg",
  "/cambodia-2/C4N5D_3.jpg",
  "/cambodia-2/C4N5D_4.webp",
  "/cambodia-2/C4N5D_5.webp",
  "/cambodia-2/C4N5D_6.jpg",
];

export const PHOTOS20: string[] = [
  "/cambodia-3/C5N6D_3.webp",
  "/cambodia-3/C5N6D_4.jpg",
  "/cambodia-3/C5N6D_5.jpg",
  "/cambodia-3/C5N6D_1.webp",
  "/cambodia-3/C5N6D_2.webp"
];


export const PHOTOS21: string[] = [
  "/vietnam-1/VIET3N4D.jpg",
  "/vietnam-1/VIET3N4D1.jpg",
  "/vietnam-1/VIET3N4D2.jpg",
  "/vietnam-1/VIET3N4D3.jpg",
  "/vietnam-1/VIET3N4D4.jpg",
  "/vietnam-1/VIET3N4D5.jpg",
];

export const PHOTOS22: string[] = [
  "/vietnam-2/VIET4N5D.jpg",
  "/vietnam-2/VIET4N5D1.jpg",
  "/vietnam-2/VIET4N5D2.jpg",
  "/vietnam-2/VIET4N5D3.jpg",
  "/vietnam-2/VIET4N5D4.jpg",
  "/vietnam-2/VIET4N5D5.jpg",
];

export const PHOTOS23: string[] = [
  "/vietnam-3/VIET5N6D.jpg",
  "/vietnam-3/VIET5N6D1.jpg",
  "/vietnam-3/VIET5N6D2.jpg",
  "/vietnam-3/VIET5N6D3.jpg",
  "/vietnam-3/VIET5N6D4.jpg",
  "/vietnam-3/VIET5N6D5.jpg",
];

export const PHOTOS24: string[] = [
  "/vietnam-4/VIET5N6D.jpg",
  "/vietnam-4/VIET5N6D1.jpg",
  "/vietnam-4/VIET5N6D2.jpg",
  "/vietnam-4/VIET5N6D3.jpg",
  "/vietnam-4/VIET5N6D4.jpg",
  "/vietnam-4/VIET5N6D5.jpg",
];

export const PHOTOS25: string[] = [
  "/vietnam-5/VIET9N10D.jpg",
  "/vietnam-5/VIET9N10D1.jpg",
  "/vietnam-5/VIET9N10D2.jpg",
  "/vietnam-5/VIET9N10D3.jpg",
  "/vietnam-5/VIET9N10D4.jpg",
  "/vietnam-5/VIET9N10D5.webp",
];

export const PHOTOS26: string[] = [
  "/vietnam-6/VIET8N9D.jpg",
  "/vietnam-6/VIET8N9D1.jpg",
  "/vietnam-6/VIET8N9D2.jpg",
  "/vietnam-6/VIET8N9D3.jpg",
  "/vietnam-6/VIET8N9D4.jpg",
  "/vietnam-6/VIET8N9D5.jpg",
];

export const PHOTOS27: string[] = [
  "/vietnam-7/VIET12D13N.jpg",
  "/vietnam-7/VIET12D13N1.jpg",
  "/vietnam-7/VIET12D13N2.jpg",
  "/vietnam-7/VIET12D13N3.jpg",
 
  "/vietnam-7/VIET12D13N5.jpg",
  "/vietnam-7/VIET12D13N6.jpg",
];

export const PHOTOS28: string[] = [
  "/thailand-1/Thailand4N5D.jpg",
  "/thailand-1/Thailand4N5D_1.jpg",
  "/thailand-1/Thailand4N5D_2.jpg",
  "/thailand-1/Thailand4N5D_3.jpeg",
  "/thailand-1/Thailand4N5D_4.jpg",
];

export const PHOTOS29: string[] = [
  "/thailand-2/THAI6N7D_1.jpg",
  "/thailand-2/THAI6N7D_2.jpg",
  "/thailand-2/THAI6N7D_3.jpg",
  "/thailand-2/THAI6N7D_4.jpg",
  "/thailand-2/THAI6N7D_5.jpg",
  "/thailand-2/THAI6N7D_6.jpg",
];

export const PHOTOS30: string[] = [
  
  "/thailand-5/THAI10N11D_1.png",
  "/thailand-5/THAI10N11D_2.jpg",
  "/thailand-5/THAI10N11D_3.jpg",
  "/thailand-5/THAI10N11D_4.jpg",
  "/thailand-5/THAI10N11D_5.jpg",
  "/thailand-5/THAI10N11D_6.jpg",
  "/thailand-5/THAI10N11D_7.jpg",
  "/thailand-5/THAI10N11D_8.jpg",
  "/thailand-5/THAI10N11D_9.jpg",
  "/thailand-5/THAI10N11D_10.jpg",
];

export const PHOTOS31: string[] = [
  "/thailand-7/THAI3N4D.jpeg",
  "/thailand-7/THAI3N4D_1.jpg",
  "/thailand-7/THAI3N4D_2.jpg",
  "/thailand-7/THAI3N4D_3.jpg",
];

export const PHOTOS32: string[] = [
  "/thailand-6/THAI4N5D.jpg",
  "/thailand-6/THAI4N5D_1.jpg",
  "/thailand-6/THAI4N5D_2.jpg",
  "/thailand-6/THAI4N5D_3.jpg",
  "/thailand-6/THAI4N5D_4.jpg",
  "/thailand-6/THAI4N5D_5.jpg",
  "/thailand-6/THAI4N5D_6.jpg",
];

export const PHOTOS33: string[] = [
  "/thailand-8/THAI6N7D.jpg",
  "/thailand-8/THAI6N7D_1.jpg",
  "/thailand-8/THAI6N7D_2.jpg",
  "/thailand-8/THAI6N7D_3.jpg",
  "/thailand-8/THAI6N7D_4.jpg",
  "/thailand-8/THAI6N7D_5.jpg",
];

export const PHOTOS34: string[] = [
  "/thailand-10/THAI10N11D.jpg",
  "/thailand-10/THAI10N11D_1.jpg",
  "/thailand-10/THAI10N11D_2.jpg",
  "/thailand-10/THAI10N11D_3.jpg",
  "/thailand-10/THAI10N11D_4.jpg",
  "/thailand-10/THAI10N11D_5.webp",
  "/thailand-10/THAI10N11D_6.jpg",
];

export const PHOTOS35: string[] = [
  "/thailand-9/THAI8N9D.webp",
  "/thailand-9/THAI8N9D_1.jpg",
  "/thailand-9/THAI8N9D_2.jpg",
  "/thailand-9/THAI8N9D_3.png",
  "/thailand-9/THAI8N9D_4.jpg",
  "/thailand-9/THAI8N9D_5.jpg",
  "/thailand-9/THAI8N9D_6.jpg",
  "/thailand-9/THAI8N9D_7.jpg",
  "/thailand-9/THAI8N9D_8.jpg",
];

export const PHOTOS36: string[] = [
  "/thailand-3/THAI4N5D.jpg",
  "/thailand-3/THAI4N5D_1.jpg",
  "/thailand-3/THAI4N5D_2.png",
  "/thailand-3/THAI4N5D_3.jpg",
  "/thailand-3/THAI4N5D_4.jpg",
];

export const PHOTOS37: string[] = [
  "/thailand-4/THAI4N5D.jpg",
  "/thailand-4/THAI4N5D_1.jpg",
  "/thailand-4/THAI4N5D_5.webp",
  "/thailand-4/THAI4N5D_3.jpg",
  "/thailand-4/THAI4N5D_4.jpeg",
];

export const PHOTOS38: string[] = [
  "/malaysia-1/M5N6D_1.jpg",
  "/malaysia-1/M5N6D_2.jpg",
  "/malaysia-1/M5N6D_3.jpg",
  "/malaysia-1/M5N6D_4.jpg",
  "/malaysia-1/M5N6D_5.jpg",
  "/malaysia-1/M5N6D_6.jpg",

];

export const PHOTOS39: string[] = [
  "/malaysia-2/3N4D1.jpg",
  "/malaysia-2/3N4D2.jpg",
  "/malaysia-2/3N4D3.jpg",
  "/malaysia-2/3N4D4.jpg",

];

export const PHOTOS40: string[] = [
  "/malaysia-3/7N8D.jpg",
  "/malaysia-3/7N8D1.jpg",
  "/malaysia-3/7N8D2.jpg",
  "/malaysia-3/7N8D3.jpg",
  "/malaysia-3/7N8D4.jpg",
  "/malaysia-3/7N8D5.jpg",
];

export const PHOTOS41: string[] = [
  "/singapore-1/S3N4D_1.webp",
  "/singapore-1/S3N4D_2.webp",
  "/singapore-1/S3N4D_3.jpg",
  "/singapore-1/S3N4D_4.jpg",
  "/singapore-1/S3N4D_5.jpg",
];

export const PHOTOS42: string[] = [
  "/singapore-2/S4N5D_1.jpg",
  "/singapore-2/S4N5D_2.jpg",
  "/singapore-2/S4N5D_3.jpg",
  "/singapore-2/S4N5D_4.jpg",
  "/singapore-2/S4N5D_5.jpg",
  "/singapore-2/S4N5D_6.jpg",
];

export const PHOTOS43: string[] = [
  "/singapore-3/S6N7D_1.jpg",
  "/singapore-3/S6N7D_2.jpg",
  "/singapore-3/S6N7D_3.jpg",
  "/singapore-3/S6N7D_4.webp",
  "/singapore-3/S6N7D_5.jpg",
  "/singapore-3/S6N7D_6.jpg",
];

export const PHOTOS44: string[] = [
  "/singapore-4/S6N7D_1.jpg",
  "/singapore-4/S6N7D_2.jpg",
  "/singapore-4/S6N7D_3.jpg",
  "/singapore-4/S6N7D_4.jpg",
  "/singapore-4/S6N7D_5.jpg",
  "/singapore-4/S6N7D_6.webp",
];

export const PHOTOS45: string[] = [
  "/singapore-5/S6N7D_1.jpg",
  "/singapore-5/S6N7D_2.webp",
  "/singapore-5/S6N7D_3.jpg",
  "/singapore-5/S6N7D_4.jpg",
  "/singapore-5/S6N7D_5.jpg",
  "/singapore-5/S6N7D_6.jpg",
];

export const PHOTOS46: string[] = [
  "/singapore-6/S7N8D_1.webp",
  "/singapore-6/S7N8D_2.jpg",
  "/singapore-6/S7N8D_3.jpg",
  "/singapore-6/S7N8D_4.jpg",
  "/singapore-6/S7N8D_5.jpg",
  "/singapore-6/S7N8D_6.jpg",
  "/singapore-6/S7N8D_7.jpg",
];

export const PHOTOS47: string[] = [
  "/singaporemalaysia-1/SM6N7D_1.jpg",
  "/singaporemalaysia-1/SM6N7D_2.jpg",
  "/singaporemalaysia-1/SM6N7D_3.webp",
  "/singaporemalaysia-1/SM6N7D_4.jpg",
  "/singaporemalaysia-1/SM6N7D_5.jpg",
  "/singaporemalaysia-1/SM6N7D_6.webp",
  "/singaporemalaysia-1/SM6N7D_7.jpg",
];

export const PHOTOS48: string[] = [
  "/singaporemalaysia-2/SM6N7D_1.jpg",
  "/singaporemalaysia-2/SM6N7D_2.jpg",
  "/singaporemalaysia-2/SM6N7D_3.jpg",
  "/singaporemalaysia-2/SM6N7D_4.jpg",
  "/singaporemalaysia-2/SM6N7D_5.jpg",
  "/singaporemalaysia-2/SM6N7D_6.jpg",
  "/singaporemalaysia-2/SM6N7D_7.jpg",
];

export const PHOTOS49: string[] = [
  "/singaporemalaysia-3/SM8N9D_1.webp",
  "/singaporemalaysia-3/SM8N9D_2.jpg",
  "/singaporemalaysia-3/SM8N9D_3.jpg",
  "/singaporemalaysia-3/SM8N9D_4.jpg",
  "/singaporemalaysia-3/SM8N9D_5.jpg",
  "/singaporemalaysia-3/SM8N9D_6.jpg",
  "/singaporemalaysia-3/SM8N9D_7.jpg",
  "/singaporemalaysia-3/SM8N9D_8.jpg",
];

export const PHOTOS50: string[] = [
  "/singaporemalaysia-4/SM8N9D_1.jpg",
  "/singaporemalaysia-4/SM8N9D_2.jpg",
  "/singaporemalaysia-4/SM8N9D_3.jpg",
  "/singaporemalaysia-4/SM8N9D_4.jpg",
  "/singaporemalaysia-4/SM8N9D_5.jpg",
  "/singaporemalaysia-4/SM8N9D_6.jpg",
  "/singaporemalaysia-4/SM8N9D_7.jpg",
  "/singaporemalaysia-4/SM8N9D_8.jpg",
];

export const PHOTOS51: string[] = [
  "/singapore10N11D/SING10N11D.jpg",
  "/singapore10N11D/SING10N11D1.jpg",
  "/singapore10N11D/SING10N11D2.jpg",
  "/singapore10N11D/SING10N11D3.jpg",
  "/singapore10N11D/SING10N11D4.jpg",
  "/singapore10N11D/SING10N11D5.jpg",
  "/singapore10N11D/SING10N11D6.jpg",
];

export const PHOTOS52: string[] = [
  "/singapore12N13D/SING12N13D.jpg",
  "/singapore12N13D/SING12N13D1.jpg",
  "/singapore12N13D/SING12N13D2.jpg",
  "/singapore12N13D/SING12N13D3.jpg",
  "/singapore12N13D/SING12N13D4.jpg",
  "/singapore12N13D/SING12N13D5.jpg",
  "/singapore12N13D/SING12N13D6.jpg",
  "/singapore12N13D/SING12N13D7.jpg",
];

export const PHOTOS53: string[] = [
  "/singapore14N15D/SING14N15D.jpg",
  "/singapore14N15D/SING14N15D1.jpg",
  "/singapore14N15D/SING14N15D2.jpg",
  "/singapore14N15D/SING14N15D3.webp",
  "/singapore14N15D/SING14N15D5.jpg",
  "/singapore14N15D/SING14N15D6.jpg",
  "/singapore14N15D/SING14N15D7.webp",
  "/singapore14N15D/SING14N15D8.jpg",
  "/singapore14N15D/SING14N15D9.jpg",
  "/singapore14N15D/SING14N15D10.webp",
  "/singapore14N15D/SING14N15D11.jpg",

];

export const PHOTOS54: string[] = [
  "/srilanka-1/S3N4D1.jpg",
  "/srilanka-1/S3N4D2.jpg",
  "/srilanka-1/S3N4D3.webp",
  "/srilanka-1/S3N4D4.jpeg",
  "/srilanka-1/S3N4D5.jpg",
];

export const PHOTOS55: string[] = [
  "/srilanka-2/S4N5D1.jpg",
  "/srilanka-2/S4N5D2.jpg",
  "/srilanka-2/S4N5D3.webp",
  "/srilanka-2/S4N5D4.jpg",
  "/srilanka-2/S4N5D5.jpg",
];

export const PHOTOS56: string[] = [
  "/srilanka-3/S4N5D1.jpg",
  "/srilanka-3/S4N5D2.jpg",
  "/srilanka-3/S4N5D3.jpg",
  "/srilanka-3/S4N5D4.jpg",
  "/srilanka-3/S4N5D5.webp",
];

export const PHOTOS57: string[] = [
  "/srilanka-4/S4N5D1.jpg",
  "/srilanka-4/S4N5D2.jpg",
  "/srilanka-4/S4N5D3.jpg",
  "/srilanka-4/S4N5D4.jpg",
  "/srilanka-4/S4N5D5.jpg",
  "/srilanka-4/S4N5D6.jpg",
];

export const PHOTOS58: string[] = [
  "/srilanka-5/S5N6D1.jpg",
  "/srilanka-5/S5N6D2.jpg",
  "/srilanka-5/S5N6D3.jpg",
  "/srilanka-5/S5N6D4.jpg",
  "/srilanka-5/S5N6D5.jpg",
  "/srilanka-5/S5N6D6.jpg",
];

export const PHOTOS59: string[] = [
  "/srilanka-6/S6N7D1.jpg",
  "/srilanka-6/S6N7D2.jpg",
  "/srilanka-6/S6N7D3.jpg",
  "/srilanka-6/S6N7D4.jpg",
  "/srilanka-6/S6N7D5.jpg",
  "/srilanka-6/S6N7D6.jpg",
  "/srilanka-6/S6N7D8.jpg",
];

export const PHOTOS60: string[] = [
  "/srilanka-7/S6N7D1.jpg",
  "/srilanka-7/S6N7D3.jpg",
  "/srilanka-7/S6N7D4.jpg",
  "/srilanka-7/S6N7D5.jpg",
  "/srilanka-7/S6N7D6.jpg",
  "/srilanka-7/S6N7D7.jpg",

];

export const PHOTOS61: string[] = [
  "/srilanka-8/S6N7D1.jpg",
  "/srilanka-8/S6N7D2.jpg",
  "/srilanka-8/S6N7D3.jpg",
  "/srilanka-8/S6N7D4.webp",
  "/srilanka-8/S6N7D5.jpg",
  "/srilanka-8/S6N7D6.jpg",

];

export const PHOTOS62: string[] = [
  "/srilanka-9/S6N7D1.jpg",
  "/srilanka-9/S6N7D2.webp",
  "/srilanka-9/S6N7D3.jpg",
  "/srilanka-9/S6N7D4.jpg",
  "/srilanka-8/S6N7D6.jpg",
  "/srilanka-9/S6N7D7.jpg",
];

export const PHOTOS63: string[] = [
  "/srilanka-10/S7N8D1.jpg",
  "/srilanka-10/S7N8D2.jpg",
  "/srilanka-10/S7N8D3.jpg",
  "/srilanka-10/S7N8D5.jpg",
  "/srilanka-10/S7N8D6.jpg",
  "/srilanka-10/S7N8D7.jpg",
];

export const PHOTOS64: string[] = [
  "/srilanka-11/S7N8D1.jpg",
  "/srilanka-11/S7N8D2.jpg",
  "/srilanka-11/S7N8D4.jpg",
  "/srilanka-11/S7N8D5.jpg",
  "/srilanka-11/S7N8D6.jpg",
  "/srilanka-11/S7N8D7.jpg",
];

export const PHOTOS65: string[] = [
  "/srilanka-12/S7N8D1.jpg",
  "/srilanka-12/S7N8D2.jpg",
  "/srilanka-12/S7N8D3.jpg",
  "/srilanka-12/S7N8D4.jpg",
  "/srilanka-12/S7N8D5.jpg",
  "/srilanka-12/S7N8D6.webp",
  "/srilanka-12/S7N8D7.jpg",
];

export const PHOTOS66: string[] = [
  "/srilanka-13/S7N8D1.jpg",
  "/srilanka-13/S7N8D2.jpg",
  "/srilanka-13/S7N8D3.jpg",
  "/srilanka-13/S7N8D4.webp",
  "/srilanka-13/S7N8D5.jpg",
  "/srilanka-13/S7N8D6.jpg",
  "/srilanka-13/S7N8D7.jpg",
];

export const PHOTOS67: string[] = [
  "/srilanka-14/S8N9D1.jpg",
  "/srilanka-14/S8N9D3.jpg",
  "/srilanka-14/S8N9D4.jpg",
  "/srilanka-14/S8N9D5.webp",
  "/srilanka-14/S8N9D6.jpg",
  "/srilanka-14/S8N9D7.png",
  "/srilanka-14/S8N9D8.webp",
];

export const PHOTOS68: string[] = [
  "/srilanka-15/S9N10D1.jpg",
  "/srilanka-15/S9N10D2.jpg",
  "/srilanka-15/S9N10D3.jpg",
  "/srilanka-15/S9N10D4.jpg",
  "/srilanka-15/S9N10D5.jpg",
  "/srilanka-15/S9N10D6.jpg",
  "/srilanka-15/S9N10D7.webp",
  "/srilanka-15/S9N10D8.jpg",
];


export const PHOTOS69: string[] = [
  "/srilanka-16/S9N10D1.jpg",
  "/srilanka-16/S9N10D2.jpg",
  "/srilanka-16/S9N10D3.jpg",
  "/srilanka-16/S9N10D4.jpg",
  "/srilanka-16/S9N10D5.jpg",
  "/srilanka-16/S9N10D6.jpg",
  "/srilanka-16/S9N10D7.webp",
  "/srilanka-16/S9N10D8.jpg",
];

export const PHOTOS70: string[] = [
  "/Hotel-1/hotel1.jpg",
  "/Hotel-1/hotel2.jpg",
  "/Hotel-1/hotel3.jpg",
  "/Hotel-1/hotel4.jpg",
  "/Hotel-1/hotel5.jpg",
  "/Hotel-1/hotel6.jpg",
  "/Hotel-1/hotel7.webp",
  "/Hotel-1/hotel8.jpg",
];

export const PHOTOS71: string[] = [
  "/Hotel-11/kandima7.jpg",
  "/Hotel-11/kandima1.jpg",
  "/Hotel-11/kandima2.jpg",
  "/Hotel-11/kandima3.jpg",
  "/Hotel-11/kandima4.webp",
  "/Hotel-11/kandima5.jpg",
];

export const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
  { name: "la-passport", icon: "la-passport" },
];

export const imageGallery: ListingGalleryImage[] = [...PHOTOS].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery1: ListingGalleryImage[] = [...PHOTOS1].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery2: ListingGalleryImage[] = [...PHOTOS2].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery3: ListingGalleryImage[] = [...PHOTOS3].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery4: ListingGalleryImage[] = [...PHOTOS4].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery5: ListingGalleryImage[] = [...PHOTOS5].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery6: ListingGalleryImage[] = [...PHOTOS6].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery7: ListingGalleryImage[] = [...PHOTOS7].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery8: ListingGalleryImage[] = [...PHOTOS8].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery9: ListingGalleryImage[] = [...PHOTOS9].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery10: ListingGalleryImage[] = [...PHOTOS10].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery11: ListingGalleryImage[] = [...PHOTOS11].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery12: ListingGalleryImage[] = [...PHOTOS12].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery13: ListingGalleryImage[] = [...PHOTOS13].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery14: ListingGalleryImage[] = [...PHOTOS14].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery15: ListingGalleryImage[] = [...PHOTOS15].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery16: ListingGalleryImage[] = [...PHOTOS16].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery17: ListingGalleryImage[] = [...PHOTOS17].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery18: ListingGalleryImage[] = [...PHOTOS18].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery19: ListingGalleryImage[] = [...PHOTOS19].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery20: ListingGalleryImage[] = [...PHOTOS20].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery21: ListingGalleryImage[] = [...PHOTOS21].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery22: ListingGalleryImage[] = [...PHOTOS22].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery23: ListingGalleryImage[] = [...PHOTOS23].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery24: ListingGalleryImage[] = [...PHOTOS24].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery25: ListingGalleryImage[] = [...PHOTOS25].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery26: ListingGalleryImage[] = [...PHOTOS26].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery27: ListingGalleryImage[] = [...PHOTOS27].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery28: ListingGalleryImage[] = [...PHOTOS28].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery29: ListingGalleryImage[] = [...PHOTOS29].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery30: ListingGalleryImage[] = [...PHOTOS30].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);


export const imageGallery31: ListingGalleryImage[] = [...PHOTOS31].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery32: ListingGalleryImage[] = [...PHOTOS32].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery33: ListingGalleryImage[] = [...PHOTOS33].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery34: ListingGalleryImage[] = [...PHOTOS34].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery35: ListingGalleryImage[] = [...PHOTOS35].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);
export const imageGallery36: ListingGalleryImage[] = [...PHOTOS36].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery37: ListingGalleryImage[] = [...PHOTOS37].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery38: ListingGalleryImage[] = [...PHOTOS38].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery39: ListingGalleryImage[] = [...PHOTOS39].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery40: ListingGalleryImage[] = [...PHOTOS40].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery41: ListingGalleryImage[] = [...PHOTOS41].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery42: ListingGalleryImage[] = [...PHOTOS42].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery43: ListingGalleryImage[] = [...PHOTOS43].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery44: ListingGalleryImage[] = [...PHOTOS44].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery45: ListingGalleryImage[] = [...PHOTOS45].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery46: ListingGalleryImage[] = [...PHOTOS46].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery47: ListingGalleryImage[] = [...PHOTOS47].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery48: ListingGalleryImage[] = [...PHOTOS48].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery49: ListingGalleryImage[] = [...PHOTOS49].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery50: ListingGalleryImage[] = [...PHOTOS50].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery51: ListingGalleryImage[] = [...PHOTOS51].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery52: ListingGalleryImage[] = [...PHOTOS52].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery53: ListingGalleryImage[] = [...PHOTOS53].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery54: ListingGalleryImage[] = [...PHOTOS54].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery55: ListingGalleryImage[] = [...PHOTOS55].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery56: ListingGalleryImage[] = [...PHOTOS56].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery57: ListingGalleryImage[] = [...PHOTOS57].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery58: ListingGalleryImage[] = [...PHOTOS58].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery59: ListingGalleryImage[] = [...PHOTOS59].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery60: ListingGalleryImage[] = [...PHOTOS60].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery61: ListingGalleryImage[] = [...PHOTOS61].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery62: ListingGalleryImage[] = [...PHOTOS62].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery63: ListingGalleryImage[] = [...PHOTOS63].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery64: ListingGalleryImage[] = [...PHOTOS64].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery65: ListingGalleryImage[] = [...PHOTOS65].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery66: ListingGalleryImage[] = [...PHOTOS66].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery67: ListingGalleryImage[] = [...PHOTOS67].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery68: ListingGalleryImage[] = [...PHOTOS68].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery69: ListingGalleryImage[] = [...PHOTOS69].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery70: ListingGalleryImage[] = [...PHOTOS70].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery71: ListingGalleryImage[] = [...PHOTOS71].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);