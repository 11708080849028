import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface CardCategory5Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory5: FC<CardCategory5Props> = ({
  className = "",
  taxonomy,
}) => {
  const { count, name, href = "/", thumbnail } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategory5 flex flex-col ${className}`}
      data-nc-id="CardCategory5"
    >
       <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-6 h-0 rounded-lg overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          className="object-cover w-full h-full rounded-lg"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-opacity-10 transition-opacity"></span>
        
        <div className="absolute  left-0 w-full p-3 top-64 text-white">
          <div className="bg-white rounded-md p-3">
          <h2 className="fontfamily text-black text-center text-lg sm:text-base xl:text-xl font-semibold truncate">
            {name}
          </h2>
          <h3 className="pt-1 fontfamily text-black text-center text-base sm:text-base xl:text-base font-medium truncate">
           Package Starting : {count}
          </h3>
          </div>
          {/* <span className="block mt-2 text-sm text-neutral-200">
            {convertNumbThousand(count)} properties
            <button className="lg:text-xs rounded-md lg:py-1 text-left">Add</button>
          </span> */}
        </div>
      </div>
    </Link>
  );
};

export default CardCategory5;
