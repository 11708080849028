import { Dialog, Tab, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import Badge from "shared/Badge/Badge";
import $ from 'jquery';
import axios from "axios";
import Accordion from "containers/ListingDetailPage/listing-stay-detail/Accordion";
import countriesData from 'countries+states.json';
import { toast } from "react-toastify";

export interface CheckOutPagePageMainProps {
  className?: string;
}
{/* wizard script starts */ }


$(function () {
  var currentStep = 1;
  var totalSteps = 4;

  $(".step" + currentStep).show();
  $("#prevButton").hide(); // Initially hide the Previous button in Step 1

  // Set the initial label for the Next button
  $("#nextButton").text("Select Room");


  $("#nextButton").click(function () {
    if (currentStep < totalSteps) {
      $(".step" + currentStep).hide();
      currentStep++;
      $(".step" + currentStep).show();

      // Update the label for the Next button based on the current step
      if (currentStep === 2) {
        $("#nextButton").text("Confirm");
      } else if (currentStep === 3) {
        $("#nextButton").text("Pay Now");
      } else if (currentStep === 4) {
        $("#nextButton").text("Book Online");
      }

      // Show the Previous button after moving to Step 2
      if (currentStep === 2) {
        $("#prevButton").show();
      }
    }
  });

  $("#prevButton").click(function () {
    if (currentStep > 1) {
      $(".step" + currentStep).hide();
      currentStep--;
      $(".step" + currentStep).show();

      // Update the label for the Next button based on the current step
      if (currentStep === 1) {
        $("#nextButton").text("Select Room");
      } else if (currentStep === 2) {
        $("#nextButton").text("Confirm");
      } else if (currentStep === 3) {
        $("#nextButton").text("Review");
      }

      // Hide the Previous button when going back to Step 1
      if (currentStep === 1) {
        $("#prevButton").hide();
      }
    }
  });
});

{/* wizard script Ends */ }
const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const formatPrice = (price: string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ''));

    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return "Invalid input";
    }

    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formattedAmount;
  };

  const renderSidebar = () => {
    return (
      <div className="xl:mt-24 md:mt-12 mt-4 lg:mt-20">
        {/* {showButton &&
          <div className="md:hidden hidden  xl:block lg:block xl:mb-12 font shadow-lg w-full flex flex-col sm:rounded-md lg:border md:border border border-neutral-200 dark:border-neutral-700 space-y-0 sm:space-y-0 px-0 ">
            <div className="p-6">
              <h5 className="xl:text-sm font-normal font1 text-sky-600">
                Your tour details
              </h5>
              <div className="flex xl:pt-2 xl:pb-4 lg:pt-2 lg:pb-4">
                <div className="text-center xl:w-2/12 lg:w-1/12 border-r">
                  <h3 className=" font1 font-extrabold xl:text-base">
                    {packageDays}
                  </h3>
                  <span className="text-sm font-regular -mt-2">
                    DAYS
                  </span>
                </div>
                <div className=" pl-4">
                  <h3 className="font1 font-semibold xl:text-base">
                    {packageName}
                  </h3>
                  <span className="font text-sm font-regular ">
                    {placeName}
                  </span>
                </div>
              </div>
              <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
              <div className="xl:pt-4 xl:pb-4 lg:pt-2 lg:pb-2">
                <div className="flex items-center">
                  <div className="xl:w-5/12 lg:w-4/12">
                    <h1 className="font xl:text-xs font-normal">
                      Tour Starts
                    </h1>
                    <p className="font-semibold xl:text-base">
                      {formatDate(checkInDate)}
                    </p>
                    <h2 className="font xl:text-xs font-normal">
                      {startingFrom}
                    </h2>
                  </div>
                  <div className="xl:w-2/12 lg:w-2/12 justify-center">
                    <i className="las la-arrow-right text-sky-600 xl:text-2xl lg:text-2xl"></i>
                  </div>
                  <div className="xl:w-5/12 lg:w-4/12">
                    <h1 className="font xl:text-xs font-normal">
                      Tour Ends
                    </h1>
                    <p className="font-semibold xl:text-base">
                      {formatDate(checkOutDate)}
                    </p>
                    <h2 className="font xl:text-xs font-normal">
                      {placeName}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>

              <div className="xl:mt-4 lg:mt-4">
                <div className="text-xs font-regular">
                  Travellers & Room
                </div>
                <p className="text-sm font-regular font1">
                  {adultValue} Adults, {childrenValue} Child, {infantValue} Infant, {roomCount} Rooms
                </p>
              </div>

              <div className="xl:mt-4 lg:mt-4">
                <div className="bg-sky-100 rounded-md py-2 px-2">
                  <div className="flex items-center">
                    <div className="w-6/12">
                      <h5 className="text-sky-600 text-xs underline underline-offset-1" onClick={() => setShowPopup(true)}>
                        Price Breakup
                      </h5>
                      <Transition
                        appear
                        show={showPopup}
                        as={Fragment}
                      >
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={() => setShowPopup(false)}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0  bg-opacity-25" />
                          </Transition.Child>
                          <div className="font fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                                  <div className="text-right mt-2 mr-2">
                                    <button
                                      type="button"
                                      className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                      onClick={() => setShowPopup(false)}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <Dialog.Title
                                    as="h3"
                                    className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                                  >
                                    Trawel Mart's Detailed Tour Price
                                    <p className="xl:text-xs font font-regular text-gray-500">
                                      Prices & discounts are Per
                                      Person for Indian Nationals
                                      only.
                                    </p>
                                  </Dialog.Title>
                                  <div className="p-4">
                                    <table
                                      key="table"
                                      className="table"
                                    >
                                      <table
                                        className="rounded-2xl"
                                        style={{ width: "480px" }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                                textAlign: "left",
                                                width: "230px",
                                              }}
                                            >
                                              Premium Packages
                                            </th>
                                            <th
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                                textAlign: "center",
                                              }}
                                            >
                                              Price
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                textAlign: "left",
                                                backgroundColor:
                                                  "#f9f9f9",
                                                width: "230px",
                                              }}
                                            >
                                              Adult
                                            </td>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                textAlign: "center",
                                                backgroundColor:
                                                  "#f9f9f9",
                                              }}
                                            >
                                              {" "}
                                              {pricingData
                                                ? formatPrice(
                                                    (
                                                      pricingData as any
                                                    ).adult
                                                  )
                                                : "0"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                textAlign: "left",
                                                width: "210px",
                                              }}
                                            >
                                              Child With Bed
                                            </td>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {" "}
                                              {pricingData
                                                ? formatPrice(
                                                    (
                                                      pricingData as any
                                                    ).child
                                                  )
                                                : "0"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                textAlign: "left",
                                                backgroundColor:
                                                  "#f9f9f9",
                                                width: "230px",
                                              }}
                                            >
                                              Child Without Bed
                                            </td>
                                            <td
                                              style={{
                                                border:
                                                  "1px solid #d9d7d7",
                                                padding: "10px",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                textAlign: "center",
                                                backgroundColor:
                                                  "#f9f9f9",
                                              }}
                                            >
                                              {" "}
                                              {pricingData
                                                ? formatPrice(
                                                    (
                                                      pricingData as any
                                                    ).infant
                                                  )
                                                : "0"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </table>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition>
                    </div>
                    <div className="w-6/12">
                      <div className="font-bold text-sky-600 xl:text-xl text-right font1">
                        {formatPrice(totalAmount + "")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>} */}

        <div className="p-4 border rounded bg-white shadow xl:mt-8">
          <div className="text-orange-600 uppercase fontfamily text-sm font-medium">
            Booking Details
          </div>
          <p className="fontfamily xl:text-xl pt-2 font-bold">
            Enchanting Bali 7 Days
          </p>
          <hr className="my-3"></hr>

          <h5 className="fontfamily text-base font-semibold">
            Departure City
          </h5>

          <div className="flex justify-between pt-2">
            <p className="fontfamily xl:text-base">
              Bangalore, India
              Bengaluru International Airport
            </p>
            <p className="text-green-700 fontfamily xl:text-sm font-semibold">
              BLR
            </p>
          </div>



          <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-6 font-bold">
            Key Dates
          </p>
          <div className="flex justify-between xl:pt-2">
            <p className="fontfamily font-semibold">
              Check In
            </p>
            <p className="uppercase fontfamily ">
              Sep 4, 2024
            </p>
          </div>
          <div className="flex justify-between xl:pt-2">
            <p className="fontfamily font-semibold">
              Check Out
            </p>
            <p className="uppercase fontfamily ">
              Sep 4, 2024
            </p>
          </div>

          <hr className="my-3"></hr>

          <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-2 font-bold">
            Room & Travellers
          </p>
          <div className="flex justify-between xl:pt-2">
            <p className="fontfamily font-semibold">
              No Of Room Required
            </p>
            <p className="uppercase fontfamily ">
              02
            </p>
          </div>
          <div className="flex justify-between xl:pt-3">
            <p className="fontfamily font-semibold">
              Travellers
            </p>
            <p className="uppercase fontfamily ">
              02 Adults
            </p>
          </div>

          <hr className="my-3"></hr>

          <p className="text-orange-600 fontfamily text-xs uppercase xl:pt-2 font-bold">
            Costs (INR)
          </p>
          <div className="flex justify-between xl:pt-2 items-center">
            <div className="xl:w-8/12">
              <p className="uppercase fontfamily text-sm font-medium">
                02 Adults
              </p>
              <p className="uppercase fontfamily text-sm font-medium xl:pt-1">
                01 Children
              </p>
            </div>
            <div className="xl:w-4/12 text-right">
              <p className="fontfamily font-extrabold xl:text-2xl">
                ₹ 1,54,000
                <p className="pt-1 text-green-600 xl:text-xs font-medium">
                  Per Person On Twin Sharing
                </p>
              </p>
            </div>
          </div>

          <hr className="my-3"></hr>


          <div className="bgorange rounded py-2 text-white text-center fontfamily">
            <a href="/author">
            Book Now
            </a>
          </div>

        </div>

        <div className="xl:mt-12">
          {showContent && (
            <div className="xl:mb-12 md:mb-12 font shadow-lg w-full flex flex-col sm:rounded-md lg:border md:border border rounded-md border-neutral-200 dark:border-neutral-700 space-y-0 sm:space-y-0 px-0 ">
              <div className="p-6 ">
                <div className="xl:mt-2">
                  <div className="bg-sky-100 rounded-md py-2 px-2">
                    <div className="flex items-center">
                      <div className="w-6/12">
                        <h5 className="text-sky-600 text-xs underline underline-offset-1" onClick={() => setShowPopup(true)}>
                          Price Breakup
                        </h5>
                      </div>
                      <div className="w-6/12">
                        <div className="font-bold text-sky-600 xl:text-xl text-right font1">
                          <div className="font-regular xl:text-xs">
                            Grand Total
                          </div>
                          ₹{totalAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:mt-4 md:mt-3 mt-4">
                    <div className="flex justify-between items-center">
                      <div className="">
                        <input type="radio" id="regAmount" defaultChecked name="amount" onChange={selectRegistrationAmount} value={(totalAmount * 50) / 100} />
                        <label className="text-sm font pl-3" htmlFor="regAmount">Pay Registration Amount</label><br />
                      </div>
                      <div className="text-base font-bold font1">
                        ₹{(totalAmount * 50) / 100}
                      </div>
                    </div>
                    <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700 py-1"></div>

                    <div className="flex justify-between items-center mt-2">
                      <div className="">
                        <input type="radio" id="fullAmount" name="amount" onChange={selectRegistrationAmount} value={totalAmount} />
                        <label className="text-sm font pl-3" htmlFor="fullAmount">Pay Full Amount</label><br />
                      </div>
                      <div className="text-base font-bold font1">
                        ₹{totalAmount}
                      </div>
                    </div>

                    <div className="xl:mt-6 md:mt-4 mt-3">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Terms" className="border border-neutral-400" checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)} />
                      <label className="text-xs font1 pl-2" htmlFor="vehicle1"> I agree to the Terms and Conditions and have reviewed the Policies and Cancellation rules.</label><br />
                    </div>
                    <div className="xl:mt-3 md:mt-2">
                      <input type="checkbox" id="vehicle2" name="vehicle1" value="Updates" className="border border-neutral-400" checked={updatesChecked} onChange={() => setUpdatesChecked(!updatesChecked)} />
                      <label className="text-xs font1 pl-2" htmlFor="vehicle2"> I consent to receiving updates regarding my trip on SMS, email & Whatsapp</label><br />
                    </div>
                  </div>
                </div>
                <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700 py-1"></div>

                <button className="w-full py-2 xl:mt-4 md:mt-4 mt-4 rounded bg-sky-600 text-white font1 text-sm text-center" type="button" onClick={handleCheckout}>
                  Proceed to pay & book seats
                </button>
              </div>

            </div>)}
        </div>

        <div className="xl:mt-12 lg:mt-12 mt-6 border shadow-xl top-52 rounded-md">
          <div className="bg-[#fff0ff] px-6">
            <h1 className="font font-medium xl:text-base xl:pb-4 xl:pt-4 md:pt-4 md:pb-4 pt-4 pb-4 text-black rounded-t-2xl">
              We'll take care of the rest once you've booked.
            </h1>
          </div>
          <div className="p-6">
            <div className="flex items-center">
              <i className="las la-check-circle text-sky-600 text-lg" />
              <h2 className="xl:text-sm font-regular font pl-4">
                After your payment is received, a Elegant travel expert will contact you to finalize your booking.
              </h2>
            </div>
            <div className="flex items-center pt-5">
              <i className="las la-check-circle text-sky-600 text-lg" />
              <h2 className="xl:text-sm font1 pl-4">
                Complete information on all travelers, including a copy of their valid IDs.
              </h2>
            </div>
            <div className="flex items-center pt-5">
              <i className="las la-check-circle text-sky-600 text-lg" />
              <h2 className="xl:text-sm font1 pl-4">
                Post booking confirmation, we are constantly in touch with you through SMS, WhatsApp and email
                keeping you updated about every step until your departure.
              </h2>
            </div>
            <div className="flex items-center pt-5">
              <i className="las la-check-circle text-sky-600 text-lg" />
              <h2 className="xl:text-sm font1 pl-4">
                Before the departure, the tour manager's contact details and all pre-departure information will be sent.
              </h2>
            </div>
            <div className="flex items-center pt-5">
              <i className="las la-check-circle text-sky-600 text-lg" />
              <h2 className="xl:text-sm font1 pl-4">
                Once your trip begins, you may relax and enjoy yourself! The Elegant Travel tour manager
                will take care of the rest and make sure you have a great day!
              </h2>
            </div>
          </div>
        </div>
      </div >



    );
  };

  const renderMain = () => {

    return (
      <div className="">

        {showButton &&
          <div className="">
            <div className="">
              <h5 className="text-black uppercase fontfamily font-semibold xl:text-3xl md:text-2xl">
                Your Details
              </h5>
              <p className="xl:text-base md:text-base pt-1 fontfamily xl:pb-6 md:pb-6 pb-4 font-regular">
                Enter your traveller details below
              </p>
            </div>

            <div className="font w-full flex flex-col shadow-md sm:rounded sm:border border rounded border-neutral-200 dark:border-neutral-700 space-y-4 px-0 xl:mt-2">
              <div className="sm:p-6 xl:p-8 lg:p-8 md:p-8 p-4">
                <span className="uppercase fontfamily text-base font-semibold text-orange-600 ">
                  Primary Traveller Details
                </span>
                {/* <p className="text-xs pt-1 pb-3">
                  Enter details as registered in your Aadhaar card
                </p> */}
                {/* <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700"></div> */}
                <div className="space-x-0 lg:py-4 md:py-4 py-2">
                  <div className="flex-1 space-y-1 lg:pt-4">
                    <Label className="fontfamily font-semibold text-neutral-600"> First Name</Label>
                    <Input className="border border-neutral-300 rounded-md" value={userName} onChange={(e) => setUserName(e.target.value)} />
                  </div>

                  <div className="flex-1 space-y-1 lg:pt-4 md:pt-0 pt-2">
                    <Label className="fontfamily font-semibold text-neutral-600">Last Name</Label>
                    <Input className="border border-neutral-300 rounded-md" value={userLastName} onChange={(e) => setUserLastName(e.target.value)} />
                  </div>
                </div>
                <div className="   pb-4">
                  <div className="flex-1 space-y-2 lg:pb-0 pb-2">
                    <Label className="fontfamily font-semibold text-neutral-600">Email Address</Label>
                    <Input className="border border-neutral-300 rounded-md" value={userEmail} type="email" onChange={(e) => setUserEmail(e.target.value)} />
                  </div>
                  <div className="flex-1 space-y-2 lg:pb-0 lg:pt-4 md:pt-0 pt-2">
                    <Label className="fontfamily font-semibold text-neutral-600">Phone Number</Label>
                    <Input className="border border-neutral-300 rounded-md" value={userMobileNumber} onChange={(e) => setUserMobileNumber(e.target.value)} />
                  </div>
                </div>
                <div className=" ">
                  <div className="flex-1 space-y-2 lg:pb-0 pb-2">
                    <Label className="fontfamily font-semibold text-neutral-600">Nationality</Label>
                    <select
                      className="border border-neutral-300 py-3 rounded-md "
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select Nationality</option>
                      {countryNames.map((item: any, index: number) => (
                        <option value={item} key={index}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1 space-y-2 lg:pt-4 md:pt-0 pt-2">
                    <Label className="fontfamily font-semibold text-neutral-600">State</Label>
                    <select className="border border-neutral-300 py-3 rounded-md "
                      value={addressState}
                      onChange={(e) => setAddressState(e.target.value)}
                    >
                      <option value="">Select State</option>
                      {stateNames.map((item: any, index) => (
                        <option value={item} key={index}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className=" pt-5 pb-8">
                  <div className="flex-1 space-y-2">
                    <Label className="fontfamily font-semibold text-neutral-600">Date Of Birth</Label>
                    <input
                      type="date"
                      className="border border-neutral-300 py-3 rounded-md"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                  </div>
                  <div className="flex-1 space-y-2">

                  </div>
                </div>

                {/* <div className="border-b border-dashed border-neutral-400 dark:border-neutral-700 "></div> */}
                <div className="font-semibold fontfamily text-base text-black xl:mt-5 md:mt-6 mt-4">
                  Do you want to add GST ?
                </div>
                <p className="text-xs pt-1 pb-3">
                  You can’t do this later!
                </p>
                <div>
                  <div className="flex items-center font pb-4">
                    <span className="pr-2 text-base font-semibold">No</span>
                    <button onClick={toggle} className="toggle-button">
                      <i className={isOn ? 'las la-toggle-on text-green-600 xl:text-xl font-bold toggle-button ' : 'las la-toggle-off text-orange-600 text-xl toggle-button'}></i>
                    </button>
                    <span className=" pl-2 text-base font-semibold">Yes</span>
                  </div>
                  {isOn ?
                    <div>
                      <div className="font-semibold fontfamily text-base uppercase text-orange-600 xl:mt-3">
                        GST Details
                      </div>
                      <p className="text-xs pt-2 fontfamily text-justify pb-2">
                        <span className="text-green-500">*</span> Please note: Kindly make sure you enter the correct details as they can’t be changed once saved.
                        Once your booking has been confirmed, we cannot modify GST/TAN details.
                      </p>
                      <div className=" ">
                        <div className="flex-1 space-y-1 lg:pt-4 md:pt-3 lg:pb-0 pb-4">
                          <Label className="fontfamily font-semibold text-neutral-600">GSTIN</Label>
                          <Input className="border border-neutral-300 rounded-md" value={gstin} onChange={(e) => setGstin(e.target.value)} />
                        </div>
                      </div>
                      <div className="   mt-6 mb-4">
                        <div className="flex-1 space-y-2 lg:pb-0 pb-3">
                          <Label className="fontfamily font-semibold text-neutral-600">Flat No \ Building No</Label>
                          <Input className="border border-neutral-300  rounded-md" value={gstFlatNo} onChange={(e) => setGstFlatNo(e.target.value)} />
                        </div>
                        <div className="flex-1 space-y-2 lg:pt-4 pt-2 md:pt-0">
                          <Label className="fontfamily font-semibold text-neutral-600">Street Address</Label>
                          <Input className="border border-neutral-300  rounded-md" value={gstAddress} onChange={(e) => setGstAddress(e.target.value)} />
                        </div>
                      </div>

                      <div className="   mt-4 mb-4">
                        <div className="flex-1 space-y-2 pb-3">
                          <Label className="fontfamily font-semibold text-neutral-600">State</Label>
                          <select className="border border-neutral-300 py-3 rounded-md "
                            value={gstState}
                            onChange={(e) => setGstState(e.target.value)}
                          >
                            <option value="">Select State</option>
                            {stateNames.map((item: any, index: number) => (
                              <option value={item} key={index}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className="flex-1 space-y-2">

                        </div>
                      </div>
                    </div> : null}
                  <div className="flex mb-12 mt-6">
                    <button onClick={toggleContent} className="px-10 py-3 rounded fontfamily bgcolor text-white text-sm">
                      Save
                    </button>
                    <button className="mx-2 px-8 py-3 rounded bg-orange-500 text-white text-sm">
                      Cancel
                    </button>
                  </div>
                </div>

                {/*<div className="mb-12 mt-8">

                  <button  className="w-full py-2 rounded bg-sky-600 text-white text-base text-center font1">
                    Continue
                  </button>
                </div>
                {/* <div className="border-b border-dashed border-neutral-400 dark:border-neutral-700"></div>

                <div className="font-semibold font1 text-base text-black xl:mt-5 md:mt-3 mt-4">
                  Co-traveller details
                </div>*/}
                {/*<p className="text-xs pt-1 pb-3">
                  Add Your Co-traveller details here!
              </p>*/}

                {/*<div className="flex justify-between mt-4 mb-2">
                  <h1 className="font1 font-semibold text-base text-sky-600">
                    Adult
                  </h1>
                  <button onClick={toggle1} className="toggle-button">
                    <i className={innOn ? 'las la-minus-circle text-orange-400 xl:text-xl font-bold toggle-button' : 'las la-plus-circle text-sky-600 text-xl toggle-button'}></i>
                  </button>
                </div>*/}
                {/*innOn ?
                  <div className="xl:mt-2">
                    <div className="xl:flex lg:flex md:flex flex-wrap space-x-0 lg:space-x-5 md:space-x-5 ">
                      <div className="flex-1 space-y-1 lg:pt-4">
                        <Label className="lg:text-sm text-neutral-600 font-medium">First Name</Label>
                        <Input className="border border-neutral-300 rounded-lg" value={userName} onChange={(e) => setUserName(e.target.value)} />
                      </div>

                      <div className="flex-1 space-y-1 lg:pt-4 md:pt-0 pt-2">
                        <Label className="lg:text-sm text-neutral-600 font-medium">Last Name</Label>
                        <Input className="border border-neutral-300 rounded-lg" value={userName} onChange={(e) => setUserName(e.target.value)} />
                      </div>
                    </div>
                    <div className="xl:flex lg:flex md:flex flex-wrap space-x-0 lg:space-x-5 md:space-x-5 ">
                      <div className="flex-1 space-y-2 lg:pt-4 md:pt-4 pt-2">
                        <Label className="lg:text-sm text-neutral-600 font-medium">Phone Number</Label>
                        <Input className="border border-neutral-300 rounded-lg" value={userName} onChange={(e) => setUserName(e.target.value)} />
                      </div>

                      <div className="flex-1 space-y-2 xl:pt-4 pt-4">
                        <Label className="g:text-sm text-neutral-600 font-medium">Date Of Birth</Label>
                        <input
                          type="date"
                          className="border border-neutral-300 py-3 rounded-lg"
                          value={dateOfBirth}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                        />
                      </div>
                    </div>

              </div> : null*/}
              </div>
            </div>

          </div>}

        {showContent && (
          <div className="">
            <button onClick={reset}>
              <h3 className="text-sky-600 font1 xl:text-base pb-2">
                <i className="las la-arrow-alt-circle-left pr-1"></i>Back
              </h3>
            </button>
            <div className="">
              <h5 className="text-black font1 font-semibold xl:text-xl md:text-xl">
                Review & Pay
              </h5>
              <p className="xl:text-base pt-1 font xl:pb-6 font-regular md:pb-6 pb-4">
                You can review tour details and make payment
              </p>
            </div>
            <div className="font w-full flex flex-col shadow-md sm:rounded-2xl sm:border border rounded-xl  border-neutral-200 dark:border-neutral-700 space-y-4 px-0 ">
              <div className="sm:p-6 xl:p-8 lg:p-8 md:p-8 p-6">
                <span className="font-semibold font text-base text-black ">
                  Bali 4 Nights 5 Days Tour Package
                </span>
                <p className="text-xs pt-1 pb-3">
                  We request you to make sure all details are correct to ensure a smooth booking process.
                </p>
                <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex items-center justify-between">
                  <h4 className="xl:text-base xl:mt-4 md:mt-6 mt-4 font1 text-sky-600 font-bold">
                    Lead Traveller details
                  </h4>
                  <button onClick={reset} className="xl:mt-4 md:mt-5 mt-4 ">
                    <h3 className="text-sky-600 font1 xl:text-base pb-2">
                      <i className="las la-edit pr-1"></i>Edit
                    </h3>
                  </button>
                </div>

                <div className="flex xl:pt-5 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12 font1 font-medium xl:text-sm">
                    Name
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {userName + ' ' + userLastName}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12 font1 font-medium xl:text-sm">
                    Mobile No
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {userMobileNumber}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12  font1 font-medium xl:text-sm">
                    Email ID
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {userEmail}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12  font1 font-medium xl:text-sm">
                    Date Of Birth
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {dateOfBirth}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12 font1 font-medium xl:text-sm">
                    Nationality
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {country}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12  font1 font-medium xl:text-sm">
                    State
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {addressState}
                  </div>
                </div>
                <div className="flex xl:pt-4 md:pt-4 pt-4">
                  <div className="xl:w-3/12 md:w-3/12 w-4/12  font1 font-medium xl:text-sm">
                    GST Details
                  </div>
                  <div className="xl:w-6/12 font-medium font">
                    {gstin}<br />{billNumber}<br />{flatNo}<br />{address}<br />{gstState}
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}


        {/* <div className="font w-full shadow-md sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-4 xl:p-8 md:p-8 p-4 mt-6">
          {accordionData3.map((item, index) => (
            <Accordion key={index} title={item.title} content={item.content} />
          ))}
        </div> */}
      </div>
    );
  };

  type YourTravelerType = {
    id: any; // Or string, depending on the type of 'id'.
    salutation: any;
    name: any;
    gender: any;
    age: any;
    // Add other properties if available.
  };

  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [flatNo, setFlatNo] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [addressState, setAddressState] = useState('');
  const [gstFlatNo, setGstFlatNo] = useState('');
  const [gstAddress, setGstAddress] = useState('');
  const [gstState, setGstState] = useState('');
  const [country, setCountry] = useState('');
  const [userId, setUserId] = useState('');
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [travelers, setTravelers] = useState<YourTravelerType[]>([]);
  const [travelerSalutation, setTravelerSalutation] = useState('');
  const [travelerName, setTravelerName] = useState('');
  const [travelerGender, setTravelerGender] = useState('');
  const [travelerAge, setTravelerAge] = useState('');
  const [countryNames, setCountryNames] = useState<String[]>([]);
  const [stateNames, setStateNames] = useState<String[]>([]);
  const [gstin, setGstin] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [packageId, setPackageId] = useState('');
  const [packageDays, setPackageDays] = useState('');
  const [packageName, setPackageName] = useState('');
  const [placeName, setPlaceName] = useState('');
  const [startingFrom, setStartingFrom] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [adultValue, setAdultValue] = useState(0);
  const [adultAmount, setAdultAmount] = useState(0);
  const [childrenValue, setChildrenValue] = useState(0);
  const [infantValue, setInfantValue] = useState(0);
  const [roomCount, setRoomCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [adultsValue, setAdultsValue] = useState<number[]>([]);
  const [childrensValue, setChildrensValue] = useState<number[]>([]);
  const [infantsValue, setInfantsValue] = useState<number[]>([]);
  const [childrenDates, setChildrenDates] = useState<string[]>([]);
  const [infantDates, setInfantDates] = useState<string[]>([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [updatesChecked, setUpdatesChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pricingData, setPricingData] = useState(null);


  useEffect(() => {

    const userDataJSON = localStorage.getItem('loggedInUser');
    setCountryNames(countriesData.map(country => country.name));

    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setUserData(userData);
      setUserName(userData.name);
      setUserLastName(userData.last_name);
      setUserEmail(userData.email);
      setUserMobileNumber(userData.mobile);
      setUserId(userData.id);
      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getUserProfile/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setDateOfBirth(response.data.user.date_of_birth);
              setGender(response.data.user.gender);
              setMaritalStatus(response.data.user.marital_status);
              setAddress(response.data.user.address);
              setPinCode(response.data.user.pincode);
              setAddressState(response.data.user.state);
              setCountry(response.data.user.country);
              setCity(response.data.user.city);
              setFlatNo(response.data.user.flat_no);
            }
          }
        })

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTravellers/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setTravelers(response.data.travelers);
            }
          }
        })
    }
    else {
      console.log("No details in local storage");
    }

    const storedPackageId = localStorage.getItem("packageId");
    setPackageId(storedPackageId ? storedPackageId : '');
    const checkInDate = localStorage.getItem("checkinDate");
    setCheckInDate(checkInDate ? checkInDate : '');
    const checkoutDate = localStorage.getItem("checkoutDate");
    setCheckOutDate(checkoutDate ? checkoutDate : '');
    const startingFromDetails = localStorage.getItem("departure");
    setStartingFrom(startingFromDetails ? startingFromDetails : '');
    const adultCount = localStorage.getItem("adultCount");
    setAdultValue(adultCount ? parseInt(adultCount) : 0);
    const childrenCount = localStorage.getItem("childrenCount");
    setChildrenValue(childrenCount ? parseInt(childrenCount) : 0);
    const infantCount = localStorage.getItem("infantCount");
    setInfantValue(infantCount ? parseInt(infantCount) : 0);
    const roomNumbers = localStorage.getItem("numberOfRooms");
    setRoomCount(roomNumbers ? parseInt(roomNumbers) : 0);
    const totalPrice = localStorage.getItem("totalAmount");
    setTotalAmount(totalPrice ? parseInt(totalPrice) : 0);
    const adultsArrayString = localStorage.getItem("adultRooms");
    const adultsArray = adultsArrayString ? adultsArrayString.split(", ").map(Number) : [];
    setAdultsValue(adultsArray);
    const childrenArrayString = localStorage.getItem("childrenRooms");
    const childrenArray = childrenArrayString ? childrenArrayString.split(", ").map(Number) : [];
    setChildrensValue(childrenArray);
    const infantArrayString = localStorage.getItem("infantRooms");
    const infantArray = infantArrayString ? infantArrayString.split(", ").map(Number) : [];
    setInfantsValue(infantArray);
    const childrenDateArrayString = localStorage.getItem("childrenDates");
    const childrenDateArray = childrenDateArrayString ? childrenDateArrayString.split(", ") : [];
    setChildrenDates(childrenDateArray);
    const infantDateArrayString = localStorage.getItem("infantDates");
    const infantDateArray = infantDateArrayString ? infantDateArrayString.split(", ") : [];
    setInfantDates(infantDateArray);
  }, []);

  // useEffect(() => {
  //   axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/" + )
  // },[]);



  useEffect(() => {
    setPaidAmount((totalAmount * 50) / 100)
  }, [totalAmount]);

  const selectRegistrationAmount = (event: any) => {
    const selectedAmount = parseFloat(event.target.value);
    setPaidAmount(selectedAmount);
  };

  useEffect(() => {
    if (packageId != '') {
      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackageForWebsite/" + packageId)
        .then((response) => {
          if (response.data.status) {
            setPackageId(response.data.tour.id);
            setPackageDays(response.data.tour.tour_days);
            setPlaceName(response.data.tour.tour_city);
            setPackageName(response.data.tour.tour_title);
          }
        });

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/" + packageId)
        .then((response) => {
          if (response.status == 200) {
            setPricingData(response.data);
          }
        })
    }
  }, [packageId]);

  useEffect(() => {

    if (country != '') {
      const selectedCountryObject = countriesData.find(item => item.name === country);
      if (selectedCountryObject) {
        const states = selectedCountryObject.states || [];
        setStateNames(states.map((state: any) => state.name));
        setAddressState('');
      }
      else {
        setStateNames([]);
      }
    }
    else {
      setStateNames([]);
    }

  }, [country]);

  const formatDate = (inputDate: string): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate: string = new Date(inputDate).toLocaleDateString(undefined, options);
    return formattedDate;
  };

  function handleCheckout() {
    if (!termsChecked) {
      toast.error("Please agree to the terms and conditions");
    }
    else if (!updatesChecked) {
      toast.error("Please consent to recieving updates");
    }
    else if (updatesChecked && termsChecked) {
      const formData = {
        user_id: userId, package_id: packageId, adult: adultValue, children: childrenValue, infant: infantValue, total_amount: totalAmount,
        paid_amount: paidAmount, total_rooms: roomCount, check_in_date: checkInDate, check_out_date: checkOutDate, starting_place: startingFrom,
        adult_rooms: adultsValue.join(", "), child_rooms: childrensValue.join(", "), infant_rooms: infantsValue.join(", "),
        child_dates: childrenDates.join(", "), infant_dates: infantDates.join(", ")
      }
      axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addCheckout", formData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              localStorage.removeItem("packageId");
              localStorage.removeItem("checkinDate");
              localStorage.removeItem("checkoutDate");
              localStorage.removeItem("departure");
              localStorage.removeItem("adultCount");
              localStorage.removeItem("childrenCount");
              localStorage.removeItem("infantCount");
              localStorage.removeItem("totalAmount");
              localStorage.removeItem("childrenDates");
              localStorage.removeItem("infantDates");
              localStorage.removeItem("numberOfRooms");
              localStorage.removeItem("adultRooms");
              localStorage.removeItem("childrenRooms");
              localStorage.removeItem("infantRooms");
              (window as any).location = '/thanks';
            }
          }
        })
    }
  }

  const [isOn, setIsOn] = useState(false);

  const toggle = () => {
    setIsOn(!isOn);
  };

  const [innOn, setInnOn] = useState(false);

  const toggle1 = () => {
    setInnOn(!innOn);
  };

  const accordionData3 = [
    {
      title: 'Need To Know',
      content: [
        <ul key="list" className="pl-2">
          <div className="mt-4 grid grid-cols-1 gap-0 md:gap-2 sm:grid-cols-1">
            <div className="text-xl font-bold "><span>Find Out</span></div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Warm & humid with tropical rains, unexpected showers during the day
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                For detailed Information about weather kindly visit www.accuweather.com
              </li>
            </ul>
            <div className="text-lg font-bold "><span>Transport</span></div>
            <div className="text-base font-semibold "><span>Air Travel :</span></div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Bangalore - Bali / Bali - Bangalore
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We use combination of Airlines like Malaysian Airlines, Singapore Airlines etc..
              </li>
            </ul>
            <div className="text-base font-semibold "><span>Coach Tavel :</span></div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                A/C coach - Seating capacity depends upon group size
              </li>
            </ul>
            <div className="text-lg font-bold "><span>Documents Required for Travel</span></div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Original passport with minimum 6 months validity from the date of tour arrival along with sufficient blank pages for the stamping of visa
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                A valid Tourist Visa for the duration of the tour
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                For all Schengen countries passport validity should not exceed more than 10 years
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Handwritten passport is not acceptable
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },

    {
      title: 'Cancellation Policy',
      content: [
        <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
          <ul className="text-base font-medium pt-6">
            <table className="w-full">
              <thead>
                <tr>
                  <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                    Number of days before departure, Elegant Travel
                    receives your cancellations
                  </th>
                  <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                    Amount of cancellations charge shown as a % of a total
                    holiday price you must pay
                  </th>
                  <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                    If we cancel your holiday, amount you will receive from us
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontSize: '14px', fontWeight: '400' }}>
                    More than 45 days
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    Deposit Only
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    Full Advance Received
                  </td>

                </tr>
                <tr>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    44 - 30 Days
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    40% of total holiday cost
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    29 - 15 days
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    60% of total holiday cost
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    15 days or less
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    90% of total holiday cost
                  </td>
                  <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                    Full Amount Received plus 10% of the amount
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </ul>
        </div>
      ],
    },

    {
      title: 'Accomodation Details',
      content: [
        <ul key="list" className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className=" p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Processing the best for you!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We are in the process of booking the accommodation for this tour. We will update it here, as we are done.
              </li>
              <li className="lg:text-base md:text-base text-sm  font-normal">
                Post Booking, you will be notified through your contact details about all tour related updates.
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },
  ]


  const [showButton, setShowButton] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [showBox, setShowBox] = useState(true);

  const toggleContent = () => {
    if (userName == "") {
      toast.error("Please enter user name");
    }
    else if (userLastName == "") {
      toast.error("Please enter last name");
    }
    else if (userEmail == "") {
      toast.error("Please enter email");
    }
    else if (userMobileNumber == "") {
      toast.error("Please enter mobile number");
    }
    else if (country == "") {
      toast.error("Please select country");
    }
    else if (addressState == "") {
      toast.error("Please select state");
    }
    else if (dateOfBirth == "") {
      toast.error("Please select date of birth");
    }
    else {
      setShowButton(!showButton);
      setShowContent(!showContent);
      setShowBox(!showBox);
    }
  };



  const reset = () => {
    setShowButton(true);
    setShowBox(true);
    setShowContent(false);
  };


  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>

      <main className="relative container mt-11 mb-24 lg:mb-32 ">

        <div className="xl:flex lg:flex xl:gap-2 xl:px-12">
          <div className="xl:w-8/12 lg:w-8/12 md:w-12/12">{renderMain()}</div>
          <div className="xl:w-5/12 md:w-12/12 lg:w-5/12 xl:pl-4 lg:pl-6">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;