import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import bangalore from "images/contact-us/bangalore.png"
import facebook from "images/contact-us/facebook (1).png"
import insta from "images/contact-us/instagram.png"
import youtube from "images/contact-us/youtube.png"
import Logo from "shared/Logo/Logo";
import PageEnquiryy from "containers/PageHome/PageEnquiryy";
import SocialsList from "shared/SocialsList/SocialsList";

import DatePicker from "react-datepicker";
import Input from "shared/Input/Input";


export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];




const PageContact: FC<PageContactProps> = ({ className = "" }) => {


  let [travelDate, setTravelDate] = useState("");

  const handleDateChange = (date: any) => {
    setTravelDate(date.toISOString().slice(0, 10));
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>
          Contact Us - Trawel Mart
        </title>
        <meta
          name="description"
          content="We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
        />
        <meta
          name="keywords"
          content="Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
        />
      </Helmet>

      <div className=" font mb-12 lg:mb-10 bg-[#fbf7fb] p-16 ">

        <h2 className="font flex items-center text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>
        {/*<h2 className="font flex items-center pt-4 text-3xl leading-[115%] md:text-lg md:leading-[115%] font-semibold text-sky-600 dark:text-neutral-100 justify-center">
          Managing Director : <span className="text-neutral-900">+91 96326 94971 </span>
        </h2>
        <h2 className="font flex items-center pt-2 text-3xl leading-[115%] md:text-lg md:leading-[115%] font-semibold text-sky-600 dark:text-neutral-100 justify-center">
          Director : <span className="text-neutral-900">+91 96326 94971 </span>
        </h2>
        {/* <div className="flex items center justify-center pt-2 mb-2">
           <img src={phonecall} className="mr-3 w-5 h-5 border-1 bg-gray-500 rounded-full " /> 
          <i className="las la-phone text-3xl pt-1"></i>
          <span className="font flex  items-center text-2xl pl-2 md:text-2xl  font-normal text-neutral-900 dark:text-neutral-100 justify-center">
            1800 22 7979</span>
        </div>
        <div className=" font flex items center justify-center">
          {/* <img src={email} className="mr-3 w-5 h-5" /> 
          <i className="las la-envelope text-3xl pt-1"></i>
          <span className="font flex text-2xl pl-2 md:text-2xl font-normal text-neutral-900 dark:text-neutral-100 justify-center" >
            travel@trawelmart.com</span>
        </div>*/}
      </div>


      <section className="container mx-auto">
        <div className="grid xl:grid-cols-2 xl:gap-12">
          <div className="xl:px-12">
            <div className="bgcolor1 rounded px-6 py-12">
              <h6 className="xl:text-3xl fontfamily text-white font-semibold">
                Get in Touch
              </h6>
              <p className="text-white xl:text-base pt-2 fontfamily">
                Elegant Carve Has Established Its Travel Expertise In
              </p>

              <p className=" uppercase xl:text-base text-white xl:pt-6 fontfamily font-bold">
                Vist Us
              </p>
              {/* <p className="text-white xl:text-base pt-1 fontfamily">
                Located across the country, ready to assist in planning & booking your perfect vacation.
              </p> */}
              <p className="flex text-white xl:text-base pt-2 fontfamily">
                <i className="las la-building xl:text-2xl pr-2 pt-2"></i> Novel Tech Park, #46/4,
                Kudlu Gate, Hosur Rd, HSR Extension,
                Bengaluru, Karnataka 560068.
              </p>

              <p className=" uppercase xl:text-base text-white xl:pt-6 fontfamily font-bold">
                Chat With Us
              </p>
              {/* <p className="text-white xl:text-base pt-1 fontfamily">
                Located across the country, ready to assist in planning & booking your perfect vacation.
              </p> */}
              <p className="flex text-white xl:text-base pt-2 fontfamily">
                <i className="las la-envelope xl:text-2xl pr-2 "></i> travel@elegantcarve.com
              </p>


              <p className=" uppercase xl:text-base text-white xl:pt-6 fontfamily font-bold">
                Call Us
              </p>
              {/* <p className="text-white xl:text-base pt-1 fontfamily">
                Located across the country, ready to assist in planning & booking your perfect vacation.
              </p> */}
              <p className="flex text-white xl:text-base pt-2 fontfamily">
                <i className="las la-phone xl:text-2xl pr-2 "></i> +91 9876543210
              </p>



              <p className=" uppercase xl:text-base text-white xl:pt-6 fontfamily font-bold xl:pb-3">
                Social Media
              </p>
              {/* <p className="text-white xl:text-base pt-1 fontfamily">
                Located across the country, ready to assist in planning & booking your perfect vacation.
              </p> */}
              <SocialsList
                className="w-full !space-x-2  text-left justify-start"
                itemClass="flex items-center justify-center w-10 h-10 rounded-full border text-white font-normal dark:bg-neutral-800 text-xl"
              />


            </div>
          </div>

          <div className="">
            <form >
              <div className="mx-auto xl:-mt-4">
                <label className="block xl:px-8 px-4">
                  <Input
                    name="name"
                    type="text"
                    placeholder="Full Name"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    name="tel"
                    type="number"
                    placeholder="Phone Number"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="email"
                    placeholder="Email ID"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type=""
                    placeholder="Departure City"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="date"
                    id="travelDateInput"
                    placeholder="Travel Date"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="number"
                    placeholder="No of Passenger"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                  />
                </label>
                <label className="block xl:px-8 px-4">
                  <textarea
                    placeholder="Drop us a small description"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4"
                  />
                </label>
              </div>

              <div className="xl:px-8 px-4 mt-4 pb-6">
                <button
                  type="submit"
                  id="submitButton"
                  className="w-full rounded-md bggreen mt-2 px-4 py-3 text-sm 
                                                          font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                          focus-visible:ring-opacity-75"
                >
                  Submit Enquiry
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>


      <section className="container mx-auto xl:mt-24 ">
        <div className="grid xl:grid-cols-2 xl:gap-4 items-center ">
          <div className="">
            <h1 className="text-center xl:text-3xl fontfamily font-bold">
              Manage Everything
            </h1>
            <p className="text-center xl:text-xl fontfamily pt-4">
              Through Elegant Carve Supplier App
            </p>
            <div className="center pt-6">
              <img src="https://old-assets-gc.thrillophilia.com/assets/supplier/landing/btn_app_round-d15d728a83a687d58ba9170a2deb94ad.png" alt="" />
            </div>
          </div>

          <div className="">
            <img src="https://old-assets-gc.thrillophilia.com/assets/supplier/landing/devices-4b5ea56540d313997361bbdba706b133.png" alt="" />
          </div>
        </div>
      </section>







      {/* <div className=" container">
        <div className="w-full pb-4 pb-10 flex flex-wrap">


          <div className="lg:w-4/12 w-full xl:px-0 lg:px-2">
            <div className="border shadow-xl rounded-xl ">
              <div className="flex items-center justify-between mb-6 font bg-[#fbf7fb] p-4 text-center rounded-t-xl ">
                <h2 className="font text-lg font-semibold ">Bengaluru <span className="text-xs text-orange-500 font-normal">(Corporate Office)</span></h2>
                <a href="https://goo.gl/maps/VKXqQceYMcD2vMtSA" target="_blank">
                  <div className="text-blue-800 text-xs flex items-center ">
                    <i className="las la-map-pin text-xl font-bold"></i>
                    <p className="">Get Directions</p>
                  </div>
                </a>
              </div>
              <div className=" px-4 mb-4">
                <div className="flex mb-3">
                  <i className="las la-building text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2">No.357, Trawel Mart Gruha, 10th B Main Rd, near Cosmopolitan Club, Jayanagar 3rd Block, Bengaluru, Karnataka - 560 011</span>
                </div>
                <div className="flex mb-3">
                  <i className="las la-phone-square text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2"><a href="tel:080 – 4642 7999">080 4642 7999</a></span>
                </div>
                <div className="flex pb-1">
                  <i className="las la-envelope text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2 "><a href="mailto:enquiry@trawelmart.com">enquiry@trawelmart.com</a></span>
                </div>

                <div className="border-t mt-2" />
                <div className="flex items-center justify-between mt-2">
                  <div className="text-sm font font-semibold text-sky-600 mt-2">
                    <p className="">
                      Working Hours
                    </p>
                    <p className="text-xs text-black ">
                      10:00 AM - 07:00 PM
                    </p>
                  </div>
                  {/*<Logo className="w-24" />
                </div>
              </div>
            </div>
            {/*} <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.621335201645!2d77.58304527485366!3d12.932041887379691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15c4b6a47ee9%3A0xdcb494a827ecb263!2sTrawel%20Mart%20World%20Tours%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1686833710089!5m2!1sen!2sin" width="590" height="450" className="border-1 w-600 h-450"  ></iframe>
          </div>




          <div className="lg:w-4/12 w-full xl:pl-6 xl:py-0 py-2 lg:py-0 lg:w-4/12 w-full xl:px-0 lg:px-2">
            <div className="border shadow-xl rounded-xl ">
              <div className="flex items-center justify-between mb-6 font bg-[#fbf7fb] p-4 text-center rounded-t-xl ">
                <h2 className="font text-lg font-semibold ">Bengaluru</h2>
                <a href="https://www.google.com/maps/place/Trawel+Mart+World+Tours+Pvt+Ltd/@13.001166,77.5696492,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae16fb92be8713:0xddef912aba579eb!8m2!3d13.001166!4d77.5722295!16s%2Fg%2F11gf5_sv50?entry=ttu" target="_blank">
                  <div className="text-blue-800 text-xs flex items-center ">
                    <i className="las la-map-pin text-xl font-bold"></i>
                    <p className="">Get Directions</p>
                  </div>
                </a>
              </div>

              <div className=" px-4 mb-4 ">
                <div className="flex mb-3">
                  <i className="las la-building text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2">No 20, 10th Cross, 1 Temple Street, <br></br>Malleshwaram, Bengaluru, Karnataka - 560 003</span>
                </div>
                <div className="flex mb-3">
                  <i className="las la-phone-square text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2"><a href="tel:080 – 4612 7999">080 4612 7999</a></span>
                </div>
                <div className="flex lg:pb-3">
                  <i className="las la-envelope text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2"><a href="mailto:blr@trawelmart.com">blr@trawelmart.com</a></span>
                </div>

                <div className="border-t mt-5" />
                <div className="flex items-center justify-between mt-2">
                  <div className="text-sm font font-semibold text-sky-600 mt-2">
                    <p className="">
                      Working Hours
                    </p>
                    <p className="text-xs text-black ">
                      10:00 AM - 07:00 PM
                    </p>
                  </div>
                  {/*<Logo className="w-24" />
                </div>
              </div>
            </div>
            {/*} <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.621335201645!2d77.58304527485366!3d12.932041887379691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15c4b6a47ee9%3A0xdcb494a827ecb263!2sTrawel%20Mart%20World%20Tours%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1686833710089!5m2!1sen!2sin" width="590" height="450" className="border-1 w-600 h-450"  ></iframe>
          </div>







          <div className="lg:w-4/12 w-full xl:pl-6 xl:py-0 py-2 lg:py-0 lg:w-4/12 w-full xl:px-0 lg:px-2">
            <div className="border shadow-xl rounded-xl ">
              <div className="flex items-center justify-between mb-6 font bg-[#fbf7fb] p-4 text-center rounded-t-xl ">
                <h2 className="font text-lg font-semibold ">Chennai</h2>
                <a href="https://goo.gl/maps/fHYy7v8sgDKVR2Jj8" target="_blank">
                  <div className="text-blue-800 text-xs flex items-center ">
                    <i className="las la-map-pin text-xl font-bold"></i>
                    <p className="">Get Directions</p>
                  </div>
                </a>
              </div>
              <div className=" px-4 mb-4 ">
                <div className="flex mb-3">
                  <i className="las la-building text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2">No.66/75, Cisons Complex, 150, Montieth Road, Egmore, Chennai, TamilNadu – 600 008</span>
                </div>
                <div className="flex mb-3">
                  <i className="las la-phone-square text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2"><a href="tel:044 – 4353 3313">044 4353 3313</a></span>
                </div>
                <div className="flex xl:pb-6  lg:pb-3">
                  <i className="las la-envelope text-2xl text-sky-700"></i>
                  <span className="font text-sm font-medium pl-2"><a href="mailto:chennai@trawelmart.com">chennai@trawelmart.com</a></span>
                </div>

                <div className="border-t xl:mt-2 lg:mt-0" />
                <div className="flex items-center justify-between mt-2">
                  <div className="text-sm font font-semibold text-sky-600 mt-2">
                    <p className="">
                      Working Hours
                    </p>
                    <p className="text-xs text-black ">
                      10:00 AM - 07:00 PM
                    </p>
                  </div>
                  {/* <Logo className="w-24" />
                </div>
              </div>
            </div>
            {/*   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4774228905167!2d80.25434107514322!3d13.068900812737382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266128b5d32c1%3A0x3fad6582bc47fa7!2sTrawel%20Mart%20World%20Tours%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1686835601551!5m2!1sen!2sin" width="580" height="450" className="border-1 w-600 h-450"  ></iframe>
          
          </div>
        </div>


        <div className="p-2 rounded-lg xl:bg-neutral-50 xl:mt-16 p-6">
          <h2 className="font flex items-center text-xl text-center leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Trawel Mart Has Established Its Travel Expertise In
          </h2>
          <div className="flex flex-wrap justify-center items-center mt-6 ">
            <div className="bg-gray-100 px-6 py-2  mx-2 text-center">
              <div className="flex justify-center ">
                <img src={bangalore} className="w-14 h-14 " />
              </div>
              <p className="text-xs font font-semibold mt-1">Bengaluru</p>
            </div>
            {/*<div className="bg-gray-100 px-6 py-2  mx-2 xl:my-0 my-2 text-center">
              <div className="flex justify-center ">
                <img src={mumbai} className="w-14 h-14 " />
              </div>
              <p className="text-xs font font-semibold mt-1">Mumbai</p>
            </div>
             {/*<div className="bg-gray-100 px-6 py-2  mx-2 text-center">
              <div className="flex justify-center ">
                <img src={bangalore} className="w-14 h-14 " />
              </div>
              <p className="text-xs font font-semibold mt-1">Kolkata</p>
            </div>
            {/* <div className="bg-gray-100 px-6 py-2  mx-2 text-center">
             <div className="flex justify-center ">
                <img src={mumbai} className="w-14 h-14 " />
              </div>
              <p className="text-xs font font-semibold mt-1">Pune</p>
        </div>
            <div className="bg-gray-100 px-6 py-2  mx-2 xl:my-0 my-2 text-center">
              <div className="flex justify-center ">
                <img src={bangalore} className="w-14 h-14 " />
              </div>
              <p className="text-xs font font-semibold mt-1">Chennai</p>
            </div>
          </div>
          {/*<h2 className="mt-6 flex items-center text-lg leading-[115%] md:text-lg md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            +50 more cities..
        </h2>
        </div>
        <hr className="my-4" />


        <section className="xl:pt-6 xl:pb-12 pt-6 pb-12 ">
          <div className="grid xl:grid-cols-4 xl:gap-2 sm:grid-cols-2 sm:gap-2">
            <div className="xl:py-0 py-2">
              <div className="flex items-center">
                <i className="las la-building text-sky-600 xl:text-2xl"></i>
                <h2 className="font-semibold font xl:text-base pl-2">
                  Our Offices
                </h2>
              </div>
              <p className="xl:text-sm font xl:pt-1 pt-1">
                Located across the country, ready to assist in planning & booking your perfect vacation.
              </p>
              {/* <div className="flex xl:pt-2 pt-2">
                <a href="">
                  <img src={facebook} className="w-6 h-6  mr-3" />
                </a>
                <a href="">
                  <img src={insta} className="w-6 h-6 mr-3" />
                </a>
                <a href="">
                  <img src={youtube} className="w-6 h-6 mr-3" />
                </a>
              </div> 
            </div>
            <div className="xl:py-0 py-3">
              <div className="flex items-center">
                <i className="las la-phone text-sky-600 xl:text-2xl"></i>
                <h2 className="font-semibold font xl:text-base pl-2">
                  Call Us
                </h2>
              </div>
              <p className="xl:text-sm font xl:pt-1">
                Request a quote, or just chat about your next vacation. We're always happy to help!
              </p>
              <div className="text-sky-600 font font-semibold xl:pt-2 xl:text-base">
                <a href="tel:080 4642 7999">080 4642 7999</a>
              </div>
            </div>
            <div className="xl:py-0 py-2">
              <div className="flex items-center">
                <i className="las la-edit text-sky-600 xl:text-2xl"></i>
                <h2 className="font-semibold font xl:text-base pl-2">
                  Write to Us
                </h2>
              </div>
              <p className="xl:text-sm font xl:pt-1 pt-1">
                Be it an enquiry, feedback or a simple <br></br>suggestion, write to us.
              </p>
              <div className="text-sky-600 font font-semibold xl:pt-2 pt-2 xl:text-base">
                <a href="mailto:enquiry@trawelmart.com ">enquiry@trawelmart.com </a>
              </div>
            </div>
            <div className="xl:py-0 py-2">
              <div className="flex items-center">
                <i className="las la-share text-sky-600 xl:text-2xl"></i>
                <h2 className="font-semibold font xl:text-base pl-2">
                  Connect with Us
                </h2>
              </div>
              <p className="xl:text-sm font xl:pt-1 pt-1">
                Check out reviews, podcasts, <br></br>blogs and more...
              </p>

              <SocialsList
                className="w-full !space-x-2 pt-2 lg:pt-4 text-right"
                itemClass="flex items-center justify-center w-8 h-8 rounded-lg bg-sky-100 text-black font-normal dark:bg-neutral-800 text-base"
              />
              {/* <div className="flex xl:pt-2 pt-2">
                <img src={facebook} className="w-6 h-6  mr-3" />
                <img src={insta} className="w-6 h-6 mr-3" />
                <img src={youtube} className="w-6 h-6 mr-3" /> */}
      {/*<img src={link} className="w-6 h-6 mr-3" />*/}
      {/* </div> */}
      {/* <div className="flex xl:pt-2 pt-2">
                <a href="">
                  <img src={facebook} className="w-6 h-6  mr-3" />
                </a>
                <a href="">
                  <img src={insta} className="w-6 h-6 mr-3" />
                </a>
                <a href="">
                  <img src={youtube} className="w-6 h-6 mr-3" />
                </a>
              </div> 
            </div>
          </div>
        </section>
      </div> */}

      <PageEnquiryy />
    </div>
  );
};

export default PageContact;
